import { Component, OnInit } from '@angular/core';
import * as fromShared from 'carehub-shared/state';
import * as sharedActions from 'carehub-shared/state/shared.actions';
import { Store, select } from '@ngrx/store';
import * as fromRoot from 'carehub-root/state/app.state';
import { BaseComponent } from 'carehub-root/shared/components/base-component';
import { takeUntil, filter } from 'rxjs/operators';

@Component({
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent extends BaseComponent implements OnInit {
  lastError: string;

  constructor(private store: Store<fromRoot.State>) {
    super();
  }

  ngOnInit() {
    this.store
      .pipe(
        select(fromShared.getCurrentError),
        filter((c) => !!c),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((error) => {
        this.lastError = error;
      });
  }

  protected onDestroy(): void {}
}
