import { Observable, Subject } from 'rxjs';
import { bufferTime, filter, map, take, takeUntil } from 'rxjs/operators';

export enum ClickGroupType {
  Single,
  Double,
}
export interface ClickGroup<TData> {
  type: ClickGroupType;
  click: MouseEvent;
  allClicks: MouseEvent[];
  data: TData;
}
/**
 * manages the distinction between single and double clicks.
 * separate so that we can be consistent with click behavior throughout the site
 */
export class DoubleClickManager<TData> {
  private disposed = false;
  private doubleClickWindowMs = 500;
  private rawClicks$ = new Subject<{ event: Event; data?: TData }>();
  public clicks$: Observable<ClickGroup<TData>>;

  /**
   * builds a new instance of handler
   * @param disposed$ the subject stream for this service being disposed of
   */
  constructor(private disposed$: Subject<void>) {
    this.disposed$.pipe(take(1)).subscribe(() => this.dispose());

    this.clicks$ = this.rawClicks$.pipe(
      takeUntil(this.disposed$),
      bufferTime(this.doubleClickWindowMs),
      filter((clicks) => clicks.length > 0),
      map(
        (clicks) =>
          ({
            type:
              clicks.length === 1
                ? ClickGroupType.Single
                : ClickGroupType.Double,
            click: clicks[0].event,
            allClicks: clicks.map((i) => i.event),
            data: clicks[0].data,
          } as ClickGroup<TData>)
      )
    );
  }

  /**
   * method call when the box or button is clicked
   * @param event the click event
   */
  click(event: Event, data?: TData) {
    if (this.disposed) {
      console.error('this click service has been disposed');
    } else {
      this.rawClicks$.next({ event: event, data: data });
    }
  }

  /** method call to clean up resources */
  dispose() {
    if (!this.disposed) {
      this.rawClicks$.complete();
    }
    this.disposed = true;
  }
}
