import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  swaggerUri = environment.chiliSauceApiRoot + '/swagger';
  constructor() {}

  ngOnInit() {}
}
