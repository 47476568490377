<ch-base-banner [goBack]="this.goBack.bind(this)">
  <div class="banner-content" *ngIf="anesthesiologist">
    <div>
      <span>NAME: </span>
      {{ anesthesiologist?.name }}
    </div>
    <mat-divider class="banner-dividers" [vertical]="true"> </mat-divider>
    <div>
      <span>NPI: </span>
      {{ anesthesiologist?.npi }}
    </div>
  </div>
</ch-base-banner>
