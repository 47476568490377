<ch-smartlist-grid
  permissionName="Member"
  [includedColumns]="includedColumns"
  disableAdd="true"
  [smartListResult]="dataSource"
  [isLoadingInput]="isLoading"
  [smartListCriteria]="smartListCriteria"
  gridTitle="Cases"
  (rowClick)="onRowClick($event)"
  (page)="onPage($event)"
  (sort)="onSort($event)"
>
</ch-smartlist-grid>
