import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { InputFormatter } from '../services/converters/input-formatter.interface';
import {
  PhoneFormatterOptions,
  PhoneFormatterService,
} from '../services/converters/phone-formatter.service';
import { FormatterDirectiveBase } from './base/formatter.directive.base';

@Directive({
  selector: '[enPhoneFormatter]',
  host: {
    '(blur)': 'onTouched()',
    '(change)': 'onChange($event.target.value)',
  },
})
export class PhoneFormatterDirective
  extends FormatterDirectiveBase
  implements ControlValueAccessor, OnInit
{
  public formatterOptions: Partial<PhoneFormatterOptions>;
  public formatter: InputFormatter;
  constructor(
    renderer: Renderer2,
    element: ElementRef,
    controlBinder: NgControl,
    phoneFormatter: PhoneFormatterService
  ) {
    super(renderer, element, controlBinder);
    this.formatter = phoneFormatter;

    this.formatterOptions = { allowIncompleteNumbers: true };
  }
}
