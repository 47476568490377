export interface SmartListResult<T> {
  currentPage: number;
  pageCount: number;
  /** controls the number of records on the page. 0 indicates that no paging should be used, and so returns the full result set */
  pageSize: number;
  rowCount: number;
  results: T[];
  isLoading?: boolean;
  filterHashCode?: number;
}

export function initializeSmartListResult<T>() {
  return {
    currentPage: 0,
    pageCount: 0,
    pageSize: 0,
    rowCount: 0,
    results: Array<T>(),
    isLoading: true,
    filterHashCode: undefined as number,
  };
}

export function loadingSmartListResults<T>(
  results: SmartListResult<T>
): SmartListResult<T> {
  if (!results) {
    results = initializeSmartListResult<T>();
  } else {
    results = { ...results, ...{ isLoading: true } };
  }

  return results;
}

export function loadedSmartListResults<T>(
  results: SmartListResult<T>
): SmartListResult<T> {
  return {
    ...results,
    ...{ isLoading: false },
  };
}

// todo: should this indicate failure?
// it's a dupe of the above
export function failedSmartListResults<T>(
  results: SmartListResult<T>
): SmartListResult<T> {
  return {
    ...results,
    ...{ isLoading: false },
  };
}

export interface ISmartListCriteria {
  pageSize: number;
  pageIndex: number;
  sortField: string;
  sortDirection: string;
  filter: string;
  cachedFilterHashCode?: number;
  cachedRowCount?: number;
}

export class SmartListCriteria implements ISmartListCriteria {
  pageSize: number;
  pageIndex: number;
  sortField: string;
  sortDirection: string;
  filter: string;
  cachedFilterHashCode?: number;
  cachedRowCount?: number;

  static default(): SmartListCriteria {
    return {
      pageSize: 50,
      pageIndex: 0,
      sortField: 'createdDate',
      sortDirection: 'asc',
      filter: null,
      cachedFilterHashCode: null,
      cachedRowCount: null,
    };
  }

  static defaultNoPaging(): SmartListCriteria {
    return {
      pageSize: 0,
      pageIndex: 0,
      sortField: 'createdDate',
      sortDirection: 'asc',
      filter: null,
      cachedFilterHashCode: null,
      cachedRowCount: null,
    };
  }

  static toQueryParams(criteria: ISmartListCriteria): string {
    let queryParams =
      '?pageNumber=' + (criteria.pageIndex ? criteria.pageIndex + 1 : 1);

    if (criteria.pageSize != null) {
      queryParams += '&pageSize=' + criteria.pageSize;
    }
    if (criteria.filter) {
      queryParams += '&filter=' + criteria.filter;
    }
    if (criteria.sortField) {
      queryParams += '&sortField=' + criteria.sortField;
    }
    if (criteria.sortDirection) {
      queryParams += '&sortDirection=' + criteria.sortDirection;
    }
    if (criteria.cachedFilterHashCode) {
      queryParams += '&cachedFilterHashCode=' + criteria.cachedFilterHashCode;
    }
    if (criteria.cachedRowCount) {
      queryParams += '&cachedRowCount=' + criteria.cachedRowCount;
    }
    return queryParams;
  }
}
