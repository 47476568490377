import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'age',
})
export class AgePipe implements PipeTransform {
  transform(dateValue: Date | string): number | string {
    if (!dateValue) {
      return '--';
    }

    const birthDate = new Date(dateValue);
    const today = new Date();
    if (isNaN(birthDate.getTime()) || birthDate > today) {
      return '--';
    }

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // The member hasn't had their birthday yet if the current month is before the birth month
    // or (when the months are the same) if the current date is before the birth date.
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }
}
