import { Injectable } from '@angular/core';
import { State as RootState } from 'carehub-root/state/app.state';
import * as fromShared from 'carehub-shared/state';
import { Store, select } from '@ngrx/store';
import { LookupTypes } from './models/lookup-types.enum';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LoadLookups } from 'carehub-shared/state/shared.actions';
import { LookupItem } from 'carehub-shared/state/shared.reducer';

function getLookupItemDescription(
  map: Map<string, LookupItem[]>,
  type: LookupTypes,
  id: number
) {
  // 0 is falsy but a legal value
  if (id === null || id === undefined) {
    return '';
  }

  const match = map.get(type).find((entry) => entry.id === id);
  return match ? match.description : '';
}

// singleton service which acts as a cache
// for lookup items.
@Injectable({
  providedIn: 'root',
})
export class LookupService {
  private lookups: Map<string, LookupItem[]>;

  constructor(private sharedStore: Store<RootState>) {
    // this looks weird, but internally in the store
    // all the lookup type maps get merged lazily
    // on each individual type load.
    this.sharedStore
      .pipe(select(fromShared.getLookups))
      .subscribe((data) => (this.lookups = data));
  }

  getDescriptionByTypeAndId(type: LookupTypes, id: number): Observable<string> {
    if (this.lookups && this.lookups.has(type)) {
      let result = getLookupItemDescription(this.lookups, type, id);

      return of(result);
    }

    const subscription$ = this.sharedStore.pipe(
      select(fromShared.getLookups),
      filter((data) => data.has(type)),
      map((data) => {
        return getLookupItemDescription(data, type, id);
      })
    );

    this.sharedStore.dispatch(new LoadLookups(type));

    return subscription$;
  }

  // ? By default, we will always keep inactive lookups.
  // ? The lookup smart field component will keep them disabled
  // ? in order to render their descriptions on the UI.
  getAllByType(
    type: LookupTypes,
    filterInactive = false
  ): Observable<LookupItem[]> {
    if (this.lookups && this.lookups.has(type)) {
      let types = this.lookups.get(type);
      return of(filterInactive ? types.filter((x) => x.isActive) : types);
    }

    const subscription$ = this.sharedStore.pipe(
      select(fromShared.getLookups),
      filter((data) => data.has(type)),
      map((data) => {
        let items = data.get(type);
        return filterInactive ? items.filter((x) => x.isActive) : items;
      })
    );

    this.sharedStore.dispatch(new LoadLookups(type));

    return subscription$;
  }
}
