import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ProviderGroup } from 'carehub-api/models/providergroup/providergroup';
import { LookupService } from 'carehub-shared/services/lookup.service';
import { LookupTypes } from 'carehub-shared/services/models/lookup-types.enum';

@Component({
  selector: 'ch-providergroup-banner',
  templateUrl: './providergroup-banner.component.html',
  styleUrls: ['./providergroup-banner.component.scss'],
})
export class ProviderGroupBannerComponent {
  @Input() providerGroup: ProviderGroup;
  constructor(private lookupService: LookupService, private router: Router) {}

  get providerGroupStatus() {
    return this.lookupService.getDescriptionByTypeAndId(
      LookupTypes.ProviderGroupStatuses,
      this.providerGroup?.statusId
    );
  }

  goBack(): any {
    if (location.href.includes('provider-network-mgmt')) {
      this.router.navigateByUrl('provider-network-mgmt/providergroups');
    } else {
      this.router.navigateByUrl('member-services-mgmt/providergroups');
    }
    return;
  }
}
