import {
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { DecimalFormatterOptions } from 'carehub-shared/services/converters/decimal-formatter.service';
import { Subject } from 'rxjs';
import { delay, takeUntil, takeWhile } from 'rxjs/operators';
import { InputFormatter } from '../../services/converters/input-formatter.interface';

/**
 * Base class for formatters on input elements bound to form controls.
 * Implementors must define the formatter service used to convert between the underlying form control value and the displayed formatted value.
 * Ensures that the elements form control value is always machine readable, and the displayed value is always human readable
 */
@Directive()
export abstract class FormatterDirectiveBase
  implements ControlValueAccessor, OnInit, OnDestroy
{
  private get underlyingValue(): number {
    return this.controlBinder.control.value;
  }
  private get elementValue(): string {
    return this.element.nativeElement.value;
  }
  private $destroyed = new Subject<void>();
  public enabled = true;
  public isFocused = false;
  /**
   * handles the conversions between the machine friendly underlying value and formatter display value
   */
  public abstract formatter: InputFormatter;
  public abstract formatterOptions: any;

  // private acceptFormControlChanges = true;
  // private $formChanged = new Subject<void>();

  constructor(
    private renderer: Renderer2,
    private element: ElementRef,
    private controlBinder: NgControl
  ) {}

  ngOnInit(): void {
    // this.$formChanged
    //   .pipe(
    //     takeUntil(this.$destroyed),
    //     throttleTime(100, asyncScheduler, { leading: false, trailing: true })
    //   )
    //   .subscribe((x) => {
    //     this.acceptFormControlChanges = true;
    //   });
    this.controlBinder.valueChanges
      .pipe(
        takeUntil(this.$destroyed),
        delay(100),
        takeWhile((_) => !this.isFocused)
      )
      .subscribe((x) => this.writeValue(x));
    this.writeValue(this.underlyingValue);
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  @HostListener('focus')
  onElementFocus() {
    this.isFocused = true;
  }
  @HostListener('blur')
  onElementBlur() {
    this.isFocused = false;
  }

  /** ControlValueAccessor */

  /**
   * handles value updates.
   * called in onChanges and on the valueChanges subject from the from control
   * */
  writeValue(value: any, changeFromUi = false): void {
    const parsed = this.parse(
      value == null || value == undefined ? '' : value,
      !changeFromUi
    );
    const transformed = this.transform(parsed);
    // if (changeFromUi || this.isFocused) {
    //   this.acceptFormControlChanges = false;
    // }
    // this.$formChanged.next();
    if (changeFromUi && parsed !== this.underlyingValue) {
      this.controlBinder.control.setValue(parsed, { emitEvent: false });
    }
    if (transformed !== this.elementValue) {
      this.renderer.setProperty(
        this.element.nativeElement,
        'value',
        String(transformed)
      );
    }
  }

  private _registeredOnChange: (value: any) => void;
  registerOnChange(fn: (value: any) => void): void {
    this._registeredOnChange = fn;
  }
  public onChange(value: any) {
    this.writeValue(value, true);
    if (this._registeredOnChange) {
      this._registeredOnChange(value);
    }
  }

  private _registeredOnTouched: () => void = null;
  registerOnTouched(fn: () => void): void {
    this._registeredOnTouched = fn;
  }
  public onTouched() {
    if (this._registeredOnTouched) {
      this._registeredOnTouched();
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.enabled = !isDisabled;
  }

  /** IInputFormatter wrappers */

  /** given the raw value, get the formatted value */
  private transform(rawValue: any): string {
    return this.formatter.transform(rawValue, this.formatterOptions);
  }

  /** given the formatted value, gets the raw value */
  private parse(formattedValue: string, asNormalized: boolean): any {
    return this.formatter.parse(formattedValue, {
      ...this.formatterOptions,
      parseAsNormalized: asNormalized,
    } as Partial<DecimalFormatterOptions>);
  }
}
