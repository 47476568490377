import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from 'carehub-root/state/app.state';
import { Dictionary } from 'carehub-shared/dictionary';
import * as fromShared from 'carehub-shared/state/index';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SecurityUser } from 'security-api/models/securityuser';
import { User } from '../state/shared.reducer';

/** The valid permission scopes. Based on data from Edh.Core.Security.Enums.PermissionScope */
export enum PermissionScopes {
  READ = 'Read',
  WRITE = 'Write',
  DELETE = 'Delete',
  EXECUTE = 'Execute',
  VIEW_WORK = 'ViewWork',
  DO_WORK = 'DoWork',
  ASSIGN_WORK = 'AssignWork',
  CLAIM_WORK = 'ClaimWork',
}
export type ScopedPermission = {
  permission: string;
  scope: PermissionScopes;
  strict?: boolean;
};
type Permissions = Dictionary<string[]>;

// todo: maybe a nicer mechanism for these that encodes
// the level in the permission name.
// like Case:read for more fine-grained control
export function getPermissionScope(name: string): PermissionScopes {
  if (name) {
    switch (name.toLowerCase()) {
      case 'read':
        return PermissionScopes.READ;
      case 'write':
        return PermissionScopes.WRITE;
      case 'delete':
        return PermissionScopes.DELETE;
      case 'execute':
        return PermissionScopes.EXECUTE;
      case 'viewwork':
        return PermissionScopes.VIEW_WORK;
      case 'dowork':
        return PermissionScopes.DO_WORK;
      case 'assignwork':
        return PermissionScopes.ASSIGN_WORK;
      case 'claimwork':
        return PermissionScopes.CLAIM_WORK;
      default:
        return PermissionScopes.READ;
    }
  }
  return PermissionScopes.READ;
}

export function hasPermission(
  name: string,
  scope: PermissionScopes,
  permissions: Permissions
): boolean {
  if (!name) {
    return true;
  }

  return permissions && permissions[name] && permissions[name].includes(scope);
}

/**
 * validates that the user has the specified permission scope
 * @param permissionName string permission. unless strict, nulls and empty string pass automatically
 * @param scope bit flag indicating the level, see PermissionLevels
 * @param user a user to check
 * @param string flag to require an exact match on permission and scope
 */
export function checkPermission(
  permissionName: string,
  scope: PermissionScopes,
  user: User | SecurityUser,
  strict: boolean = false
) {
  if (strict && (!permissionName || !scope)) {
    return false;
  }
  if (!permissionName) {
    return true;
  }
  if (!user) {
    return false;
  }
  if ('securityInfo' in user) {
    user = user.securityInfo;
  }

  return (
    user &&
    user.permissions &&
    hasPermission(permissionName, scope, user.permissions)
  );
}

@Directive()
abstract class BaseHasPermissionDirective implements OnInit, OnDestroy {
  protected permissionName: string;
  protected strict = false;
  protected elseTemplateRef: any;

  protected allow = false;
  protected viewCreated = false;

  protected unsubscribe$ = new Subject<void>();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<fromRoot.State>,
    protected scope: PermissionScopes
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    if (!this.scope) {
      // based on the way the base class is set up, if there is no scope on init the directive will not update for any subsequent changes
      // even if the scope is later set, the update view method is never called
      console.error(
        `Please set the permission scope for the permissions check to enable conditional rendering. Permission: ${this.permissionName}`
      );
      this.updateView(false);
    }
    // since this is effectively async
    // we don't do an immediate updateView
    this.store
      .select(fromShared.getUserPermissions)
      .pipe(
        filter((permissions) => !!permissions),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((permissions) => {
        this.updateView(
          !!this.scope &&
            (!this.strict || !!this.permissionName) &&
            hasPermission(this.permissionName, this.scope, permissions)
        );
      });
  }

  private updateView(newValue: boolean) {
    if (this.viewCreated) {
      if (newValue === this.allow) {
        // redundant store update, no change
        return;
      }
      // there's been a change in the permission
      // so re-render the alternate template
      console.warn(
        'if-allowed-directive: view container cleared!',
        this.permissionName
      );
      this.viewContainer.clear();
    }

    this.allow = newValue;

    console.debug('if-allowed-directive: ', this.permissionName, this.allow);

    if (this.allow) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.elseTemplateRef) {
      this.viewContainer.createEmbeddedView(this.elseTemplateRef);
    }
    this.viewCreated = true;
  }
}
/**
 * Permissions gate to not render a component used if the permission is dynamic or the scope is not from the standard crud operations
 */
@Directive({
  selector: '[enIfAllowed]',
})
export class IfAllowedDirective extends BaseHasPermissionDirective {
  constructor(
    templateRef: TemplateRef<any>,
    viewContainer: ViewContainerRef,
    store: Store<fromRoot.State>
  ) {
    super(templateRef, viewContainer, store, null);
  }

  @Input()
  set enIfAllowed(val: ScopedPermission) {
    this.permissionName = val ? val.permission : null;
    this.scope = val ? getPermissionScope(val.scope) : null;
    this.strict = val ? val.strict : null;
  }

  @Input()
  set enIfAllowedElse(val: any) {
    this.elseTemplateRef = val;
  }
}
@Directive({
  selector: '[enIfReadAllowed]',
})
export class IfReadAllowedDirective extends BaseHasPermissionDirective {
  constructor(
    templateRef: TemplateRef<any>,
    viewContainer: ViewContainerRef,
    store: Store<fromRoot.State>
  ) {
    super(templateRef, viewContainer, store, PermissionScopes.READ);
  }

  @Input()
  set enIfReadAllowed(val: string) {
    this.permissionName = val;
  }

  @Input()
  set enIfReadAllowedElse(val: any) {
    this.elseTemplateRef = val;
  }
}

@Directive({
  selector: '[enIfWriteAllowed]',
})
export class IfWriteAllowedDirective extends BaseHasPermissionDirective {
  constructor(
    templateRef: TemplateRef<any>,
    viewContainer: ViewContainerRef,
    store: Store<fromRoot.State>
  ) {
    super(templateRef, viewContainer, store, PermissionScopes.WRITE);
  }

  @Input()
  set enIfWriteAllowed(val: string) {
    this.permissionName = val;
  }

  @Input()
  set enIfWriteAllowedElse(val: any) {
    this.elseTemplateRef = val;
  }
}

@Directive({
  selector: '[enIfDeleteAllowed]',
})
export class IfDeleteAllowedDirective extends BaseHasPermissionDirective {
  constructor(
    templateRef: TemplateRef<any>,
    viewContainer: ViewContainerRef,
    store: Store<fromRoot.State>
  ) {
    super(templateRef, viewContainer, store, PermissionScopes.DELETE);
  }

  @Input()
  set enIfDeleteAllowed(val: string) {
    this.permissionName = val;
  }

  @Input()
  set enIfDeleteAllowedElse(val: any) {
    this.elseTemplateRef = val;
  }
}
