import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
