import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum CheckFilterState {
  Checked = 'checked',
  Crossed = 'crossed',
  Unchecked = 'unchecked',
}
@Component({
  selector: 'ch-check-filter',
  templateUrl: './check-filter.component.html',
  styleUrls: ['./check-filter.component.scss'],
})
export class CheckFilterComponent implements OnInit {
  public states = CheckFilterState;
  private _filterState: CheckFilterState = CheckFilterState.Unchecked;
  public get filterState(): CheckFilterState {
    return this._filterState;
  }
  @Input() public set filterState(value: CheckFilterState) {
    this._filterState = value;

    this.filterStateChange.emit(this.filterState);
    this.checkedChange.emit(this.mapCheckStateToBool(this.filterState));
  }
  @Output() public filterStateChange = new EventEmitter<CheckFilterState>();

  public get checked(): boolean {
    return this.mapCheckStateToBool(this.filterState);
  }
  @Input() public set checked(value: boolean) {
    this.filterState = this.mapBoolToCheckState(value);
  }
  @Output() public checkedChange = new EventEmitter<boolean>();

  private defaultLabels: { [key in CheckFilterState]: string } = {
    checked: 'Required',
    unchecked: 'No Filter',
    crossed: 'Excluded',
  };

  @Input() labelMap: Partial<{ [key in CheckFilterState]: string }>;

  @Input() label: string;

  constructor() {}

  ngOnInit(): void {}

  public getLabel() {
    return this.labelMap && this.filterState && this.labelMap[this.filterState]
      ? this.labelMap[this.filterState]
      : this.label
      ? `${this.label}: ${this.defaultLabels[this.filterState]}`
      : this.defaultLabels[this.filterState];
  }

  public isChecked(): boolean | null {
    // return true or null, 1for false
    return this.filterState !== CheckFilterState.Unchecked || null;
  }

  private _stateProgression: { [key in CheckFilterState]: CheckFilterState } = {
    checked: CheckFilterState.Crossed,
    crossed: CheckFilterState.Unchecked,
    unchecked: CheckFilterState.Checked,
  };
  public onToggle(event: any) {
    this.filterState = this._stateProgression[this.filterState];
  }

  private mapBoolToCheckState(state: boolean): CheckFilterState {
    switch (state) {
      case null:
      case undefined:
        return CheckFilterState.Unchecked;
      case true:
        return CheckFilterState.Checked;
      case false:
        return CheckFilterState.Crossed;
    }
  }
  private mapCheckStateToBool(state: CheckFilterState): boolean {
    switch (state) {
      case CheckFilterState.Checked:
        return true;
      case CheckFilterState.Unchecked:
        return false;
      case CheckFilterState.Crossed:
        return null;
    }
  }
}
