import { Input, OnInit, Directive } from '@angular/core';
import { Member } from 'carehub-api/models/member/member';

@Directive()
export abstract class BaseMemberIconComponent implements OnInit {
  private _member: Member = null;

  @Input() set member(member) {
    this._member = member;
  }
  get member(): Member {
    return this._member;
  }
  abstract get getToolTip(): string;
  abstract get getIcon(): string;

  constructor() {}

  ngOnInit(): void {}
}
