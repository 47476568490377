<ch-base-banner [goBack]="this.goBack.bind(this)">
  <div class="banner-content" *ngIf="providerGroup">
    <div>
      <span>NAME: </span>
      {{ providerGroup?.name }}
    </div>
    <mat-divider class="banner-dividers" [vertical]="true"> </mat-divider>
    <div>
      <span>NPI: </span>
      {{ providerGroup?.npi }}
    </div>
    <mat-divider class="banner-dividers" [vertical]="true"></mat-divider>

    <div>
      <span>STATUS: </span>
      {{ providerGroupStatus | async }}
    </div>
  </div>
</ch-base-banner>
