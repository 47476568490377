import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Anesthesiologist } from 'carehub-api/models/anesthesiologist/anesthesiologist';

@Component({
  selector: 'ch-anesthesiologist-banner',
  templateUrl: './anesthesiologist-banner.component.html',
  styleUrls: ['./anesthesiologist-banner.component.scss'],
})
export class AnesthesiologistBannerComponent {
  @Input() anesthesiologist: Anesthesiologist;
  constructor(private router: Router) {}

  goBack(): any {
    if (location.href.includes('provider-network-mgmt')) {
      this.router.navigateByUrl('provider-network-mgmt/anesthesiologists');
    } else {
      this.router.navigateByUrl('member-services-mgmt/anesthesiologists');
    }
  }
}
