import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Utils } from 'carehub-root/shared/utils';
import * as fromRoot from 'carehub-root/state/app.state';
import { BaseComponent } from 'carehub-shared/components/base-component';
import { AppInsightService } from 'carehub-shared/services/app-insights.service';
import { ContentContainerService } from 'carehub-shared/services/content-container.service';
import * as fromShared from 'carehub-shared/state';
import * as sharedActions from 'carehub-shared/state/shared.actions';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
  isRouting = false;
  currentBusinessModuleId$: Observable<number>;
  rightScrollClaimed = false;

  @ViewChild(MatSidenav) sidenav: MatSidenav;

  constructor(
    private snackBar: MatSnackBar,
    private store: Store<fromRoot.State>,
    private router: Router,
    private contentContainerService: ContentContainerService,
    ai: AppInsightService
  ) {
    super();
    if (environment && environment.logoEnvText) {
      if (Utils.isLocal()) {
        document.title += ' LCL';
      } else {
        document.title += ' ' + environment.logoEnvText;
      }
    }

    // prefetch all the lookups we're going to need
    // rather than loading them on demand
    this.store.dispatch(new sharedActions.LoadAllLookups());

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        console.log(`>----------- Attempting route to: ${event.url}`);
        this.isRouting = true;
      } else if (event instanceof NavigationError) {
        console.error(
          `-----------X Error attempting to route to: ${event.url}`
        );

        ai.logTrace('nav-error', { url: event.url, error: event.error });
        this.isRouting = false;
      } else if (event instanceof NavigationEnd) {
        console.log(`-----------( Routed to: ${event.url}`);
        this.isRouting = false;

        ai.logPageView(event.urlAfterRedirects);
      } else if (event instanceof NavigationCancel) {
        console.log(
          `-----------( Routing Canceled to: ${event.url}: ${event.reason}`
        );
        ai.logTrace('nav-cancel', { url: event.url, reason: event.reason });
        this.isRouting = false;
      }
    });

    this.contentContainerService.onRightScrollToggle((claimed) => {
      this.rightScrollClaimed = claimed;
    });
  }

  ngOnInit() {
    this.currentBusinessModuleId$ = this.store.pipe(
      select(fromShared.getCurrentBusinessModuleId)
    );

    this.store
      .pipe(select(fromShared.getCurrentError), takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data) {
          const config = new MatSnackBarConfig();
          config.verticalPosition = 'top';
          config.horizontalPosition = 'center';
          config.panelClass = 'ch-custom-error-snackbar';

          console.error(`Error: ${data}`);

          const afterDismissed$ = this.snackBar
            .open(`Error: ${data}`, 'Close', config)
            .afterDismissed();

          afterDismissed$.pipe(take(1)).subscribe(() => {
            this.store.dispatch(new sharedActions.SetCurrentError(null));
          });
        }
      });

    this.store
      .pipe(select(fromShared.getCurrentMessage), takeUntil(this.unsubscribe$))
      .subscribe((message) => {
        // ensure we don't just keep spamming
        // an infinite stream of empty messages
        // by emitting/dismissing
        if (!message || !message.text) {
          return;
        }

        const config = new MatSnackBarConfig();
        config.verticalPosition = 'top';
        config.horizontalPosition = 'center';
        config.panelClass = 'ch-custom-message-snackbar';
        config.duration = message.duration;

        const afterDismissed$ = this.snackBar
          .open(message.text, null, config)
          .afterDismissed();

        afterDismissed$.pipe(take(1)).subscribe(() => {
          this.store.dispatch(new sharedActions.SetCurrentMessage(null));
        });
      });

    this.router.events.subscribe(() => {
      if (this.isScreenSmall()) {
        this.sidenav.close();
      }
    });
  }

  onDestroy() {}

  showHeader(): boolean {
    return this.router.url.toLowerCase().indexOf('redirect') === -1;
  }

  isScreenSmall(): boolean {
    return false;
  }
}
