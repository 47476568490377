import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { MembersApiService } from 'carehub-api/members-api.service';
import { Activity } from 'carehub-api/models/member/activity';
import { ActivitySummary } from 'carehub-api/models/member/activitysummary';
import { BaseComponent } from 'carehub-root/shared/components/base-component';
import { ColumnDetails } from 'carehub-root/shared/components/controls/smartlist-grid/column-details';
import { LookupPipe } from 'carehub-root/shared/pipes/lookup.pipe';
import {
  SmartListCriteria,
  SmartListResult,
} from 'carehub-root/shared/smartlist';
import { ActivityDialogComponent } from './activity-dialog/activity-dialog.component';
import * as fromShared from 'carehub-shared/state/index';
import { Store, select } from '@ngrx/store';
import { SharedState } from 'carehub-root/shared/state/shared.reducer';
import { takeUntil, take } from 'rxjs/operators';

@Component({
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
  providers: [LookupPipe],
})
export class ActivitiesComponent extends BaseComponent implements OnInit {
  caseId: string;
  memberId: string;
  activities$: ReplaySubject<SmartListResult<ActivitySummary>> =
    new ReplaySubject<SmartListResult<ActivitySummary>>(1);
  smartListCriteria = new SmartListCriteria();

  includedColumns: ColumnDetails[] = [
    {
      columnDef: 'activityDate',
      sortable: true,
      width: '100px',
      header: 'Activity Date',
      cell: (row: ActivitySummary) =>
        row.activityDate
          ? formatDate(`${row.activityDate}`, 'medium', 'en-US')
          : '',
    },
    {
      columnDef: 'activityTypeId',
      width: '50px',
      header: 'Type',
      cell: (row: ActivitySummary) =>
        this.lookupPipe.transform(row.activityTypeId, 'ActivityTypes'),
    },
    {
      columnDef: 'activityRelatedToId',
      width: '100px',
      header: 'Related To',
      cell: (row: ActivitySummary) =>
        this.lookupPipe.transform(row.activityRelatedToId, 'ActivityRelatedTo'),
    },
    {
      columnDef: 'activityReasonId',
      width: '80px',
      header: 'Reason',
      cell: (row: ActivitySummary) =>
        this.lookupPipe.transform(row.activityReasonId, 'ActivityReasons'),
    },
    {
      columnDef: 'createdUserName',
      sortable: true,
      width: '100px',
      header: 'User',
      cell: (row: ActivitySummary) => row.createdUserName,
    },
    {
      columnDef: 'description',
      width: '250px',
      header: 'Description',
      cell: (row: ActivitySummary) => row.description,
    },
  ];

  constructor(
    private lookupPipe: LookupPipe,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private sharedStore: Store<SharedState>,
    private membersApiService: MembersApiService
  ) {
    super();
  }

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      this.memberId = this.route.parent.parent.snapshot.params['id'];
      this.smartListCriteria.sortDirection = 'desc';
      this.smartListCriteria.sortField = 'activityDate';
      this.smartListCriteria.pageSize = 10;
      this.caseId = this.route.parent.parent.snapshot.params['caseId'];

      this.membersApiService
        .getActivities(
          this.memberId,
          this.smartListCriteria,
          this.getQueryParam()
        )
        .pipe(takeUntil(this.unsubscribe$), take(1))
        .subscribe((results) => this.activities$.next(results));
    });

    this.sharedStore
      .pipe(takeUntil(this.unsubscribe$), select(fromShared.getCurrentMessage))
      .subscribe((message) => {
        if (message) {
          this.membersApiService
            .getActivities(
              this.memberId,
              this.smartListCriteria,
              this.getQueryParam()
            )
            .pipe(takeUntil(this.unsubscribe$), take(1))
            .subscribe((results) => this.activities$.next(results));
        }
      });
  }

  getQueryParam(): { memberCaseId?: string } {
    let queryParams: { memberCaseId?: string } = null;
    if (this.caseId) {
      queryParams = {
        memberCaseId: this.caseId,
      };
    }

    return queryParams;
  }

  onRowClick(activity: Activity) {
    const dialogRef = this.dialog.open(ActivityDialogComponent, {
      data: {
        activityId: activity.activityId,
      },
      width: ActivityDialogComponent.DefaultWidth,
      role: 'alertdialog',
    });
    dialogRef.afterClosed().subscribe((saved: boolean) => {});
  }

  onLoad(criteria: SmartListCriteria) {
    this.smartListCriteria = { ...criteria, pageIndex: 0 };
    this.membersApiService
      .getActivities(
        this.memberId,
        this.smartListCriteria,
        this.getQueryParam()
      )
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe((results) => this.activities$.next(results));
  }

  onPage(payload: { pageIndex: number; pageSize: number }) {
    this.smartListCriteria = {
      ...this.smartListCriteria,
      pageIndex: payload.pageIndex,
      pageSize: payload.pageSize,
    };
    this.membersApiService
      .getActivities(
        this.memberId,
        this.smartListCriteria,
        this.getQueryParam()
      )
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe((results) => this.activities$.next(results));
  }

  onSort(payload: { sortField: string; sortDirection: string }) {
    this.smartListCriteria = {
      ...this.smartListCriteria,
      pageIndex: 0,
      sortDirection: payload.sortDirection,
      sortField: payload.sortField,
    };
    this.membersApiService
      .getActivities(
        this.memberId,
        this.smartListCriteria,
        this.getQueryParam()
      )
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe((results) => this.activities$.next(results));
  }

  protected onDestroy(): void {}
}
