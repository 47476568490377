<mat-accordion *ngIf="permissions$ | async" class="mat-elevation-z6">
  <mat-nav-list>
    <span *ngFor="let menuItem of menuItems">
      <a
        *ngIf="menuItem && menuItem.featureFlag ? menuItem.featureFlagOn : true"
        mat-list-item
        routerLinkActive="active-link"
        [routerLink]="menuItem.url"
        [matTooltip]="menuItem.name"
      >
        <mat-icon>{{ menuItem.icon }}</mat-icon>
      </a>
      <mat-divider *ngIf="!menuItem"></mat-divider>
    </span>
  </mat-nav-list>
</mat-accordion>
