<div class="grid-container health">
  <mat-card class="healthDetails">
    <mat-card-title>Health Details</mat-card-title>
    <div class="json-viewer" *ngIf="healthDetails; else loadingDetails">
      <button class="button" mat-stroked-button (click)="dumpToConsole()">
        Export To Console
      </button>
      <button class="button" mat-stroked-button (click)="clearCache()">
        Clear Cache + Reload
      </button>
      <ch-expandable-rows-table
        (rowClicked)="itemRowClicked($event)"
        [dataSource]="smartListResult"
        [columns]="includedColumns"
        [infiniteScroll]="true"
      >
        <ng-template
          #rowTemplate
          let-rowVal="expandedService"
          let-rowIdx="rowIndex"
        >
          <div>
            <ch-expandable-rows-table
              [dataSource]="smartListResultService"
              gridTitle="Issue Types"
              (rowClicked)="itemRowClickedService($event)"
              [columns]="includedColumnsService"
              [infiniteScroll]="true"
            >
              <ng-template
                #rowTemplate
                let-expandedElement="expandedDetails"
                let-rowIdx="rowIndex"
              >
                <div>
                  <ch-smartlist-grid
                    gridTitle="Issue Details"
                    [includedColumns]="subTableColumnsToDisplay"
                    selectableType="None"
                    [disableAdd]="true"
                    [disableDelete]="true"
                    [smartListResult]="smartListResultDetails"
                  >
                  </ch-smartlist-grid>
                </div>
              </ng-template>
            </ch-expandable-rows-table>
          </div>
        </ng-template>
      </ch-expandable-rows-table>
    </div>
    <ng-template #loadingDetails>Loading Health Details...</ng-template>
  </mat-card>
</div>
