<!DOCTYPE html>

<html lang="en" xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <meta charset="utf-8" />
    <link rel="stylesheet" [href]="stylesheetUrl" />
  </head>

  <body class="referral-form">
    <div class="page">
      <img id="sig" [src]="logoUrl" />

      <table>
        <tr>
          <td>Name</td>
          <td>{{ memberName }}</td>
        </tr>
        <tr>
          <td>CaseId</td>
          <td>{{ documentDetails.memberCase.caseNumber }}</td>
        </tr>
        <tr>
          <td>Date of Birth</td>
          <td>{{ documentDetails.member.dob | date : 'mediumDate' : '+0' }}</td>
        </tr>
        <tr>
          <td>Phone</td>
          <td>{{ phone | phone }}</td>
        </tr>
        <tr>
          <td>Address</td>
          <td>{{ address$ | async }}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>
            <a [href]="'mailto:' + email" class="email-row">{{ email }}</a>
          </td>
        </tr>
        <tr>
          <td>Provider Name</td>
          <td>{{ providerName$ | async }}</td>
        </tr>
        <tr>
          <td class="td-left-width">Procedure Category</td>
          <td>{{ procedureItemCategory$ | async }}</td>
        </tr>
        <tr>
          <td>Procedure Type</td>
          <td>{{ procedureItem$ | async }}</td>
        </tr>
        <tr>
          <td>Needs Prior Auth</td>
          <td>{{ needsPriorAuthValue ? 'Yes' : 'No' }}</td>
        </tr>
        <tr>
          <td>Approved Facilities</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <ng-container *ngFor="let facility of facilities | slice : 0 : 5">
              {{ facility.name }}<br />
            </ng-container>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div>
              If scheduled at any other facility, SurgeryPlus must approve or
              procedure will not be reimbursed. SurgeryPlus has zero out of
              network coverage.
            </div>
          </td>
        </tr>

        <tr>
          <td>Comments</td>
          <td>{{ documentDetails.comment }}</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>Instructions:</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <p>
              1. Confirm receipt of this referral within 72 hours using the Care
              Advocate contact information provided below
            </p>
            <p>
              2. Contact the member directly to schedule a surgical consultation
              as soon as possible &nbsp;&nbsp;Please note the procedure type
              listed is based on the member's self reported request for surgical
              consultation
            </p>
            <p>
              3. Notify SurgeryPlus of the scheduled consultation date using the
              Care Advocate contact information provided below<br />
              &nbsp;&nbsp;A Surgery Plus representative will follow up within
              three business days if notification of the scheduled consultation
              is not received
            </p>
            <p>
              4. Once the initial consult is complete, please contact the Care
              Advocate within three business days regarding next steps for the
              patient.
            </p>
          </td>
        </tr>
        <tr>
          <td>Contact Information</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div>{{ careAdvocateName }}</div>
            <div>SurgeryPlus Care Advocate</div>
            <div>{{ hubPhoneNumber | phone }}</div>
            <div>{{ hubEmail }}</div>
            <br />
            <div>
              Employer Direct Healthcare has verified Patient’s coverage through
              the SurgeryPlus benefit at the time of submission. Contact the
              SurgeryPlus advocate when additional services are scheduled for
              additional authorization and reimbursement information.
            </div>
          </td>
        </tr>
      </table>
    </div>
  </body>
</html>
