import {
  Component,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentGenerationApiService } from 'carehub-api/documentgeneration-api.service';
import { DocumentType as DocumentTypeEnum } from 'carehub-api/enums/common/documenttype';
import { Client } from 'carehub-api/models/client/client';
import {
  DocumentType,
  initializeNewDocumentType,
} from 'carehub-api/models/common/documenttype';
import { Demographic } from 'carehub-api/models/member/demographic';
import { Member } from 'carehub-api/models/member/member';
import { MemberPlan } from 'carehub-api/models/member/memberplan';
import { MemberCase } from 'carehub-api/models/membercase/membercase';
import { Team } from 'carehub-api/models/security/team';
import { TeamUser } from 'carehub-api/models/security/teamuser';
import { User } from 'carehub-api/models/security/user';
import { FeatureFlagsApiService } from 'carehub-rest-services/feature-flags/feature-flags-api.service';
import { FormDefinitionBuilder } from 'carehub-root/shared/form-definition-builder/form-definition-builder';
import { GenericValidator } from 'carehub-root/shared/form-definition-builder/generic-validator';
import { Required } from 'carehub-root/shared/validators';
import { Observable, Subject, zip } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class GeneratedDocumentDetails {
  @Required()
  name: string;
  @Required()
  documentTypeId: number;
  documentHtml: string;
  member: Member;
  demographics: Demographic[];
  memberCase: MemberCase;
  memberPlan: MemberPlan;
  client: Client;
  comment: string;
  currentUser: User;
  team: Team;
  teamUsers: TeamUser[];

  generateMemberIdCard: boolean;
  generateProviderOnlyMemberCard: boolean;
  memberdialog: boolean = false;
}

@Component({
  templateUrl: './document-gen-dialog.component.html',
  styleUrls: ['./document-gen-dialog.component.scss'],
})
export class DocumentGenDialogComponent
  implements OnInit, OnChanges, OnDestroy
{
  static readonly DefaultWidth = '640px';
  private genericValidator: GenericValidator;

  private unsubscribe$ = new Subject<void>();

  formGroup: FormGroup;
  formGroupDisplayMessage: { [key: string]: string } = {};
  types$: Observable<DocumentType[]>;
  filteredTypes: DocumentType[] = [];

  hidePreview: false;

  memberCardEntry: DocumentType = initializeNewDocumentType();

  constructor(
    @Inject(MAT_DIALOG_DATA) public documentDetails: GeneratedDocumentDetails,
    private view: ViewContainerRef,
    private dialogRef: MatDialogRef<DocumentGenDialogComponent>,
    private documentGenerationService: DocumentGenerationApiService,
    private formBuilder: FormBuilder,
    private flagsApi: FeatureFlagsApiService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    const formDefinitionBuilder = new FormDefinitionBuilder();

    const definition = formDefinitionBuilder.build(
      GeneratedDocumentDetails.prototype,
      ['name', 'comment', 'documentTypeId', 'generateProviderOnlyMemberCard']
    );

    this.genericValidator = new GenericValidator({
      ...definition.validationMessages,
    });

    this.formGroup = this.formBuilder.group(definition.groupDefinition);

    this.formGroup.valueChanges.subscribe(
      () =>
        (this.formGroupDisplayMessage = this.genericValidator.processMessages(
          this.formGroup
        ))
    );

    this.memberCardEntry.documentTypeId = -1;
    this.memberCardEntry.description = 'Member Card';

    this.types$ = this.documentGenerationService
      .getTemplatedDocumentTypes()
      .pipe(takeUntil(this.unsubscribe$));
    zip(
      this.flagsApi.get('show-member-card').pipe(takeUntil(this.unsubscribe$)),
      this.flagsApi
        .get('show-surgery-plus-card')
        .pipe(takeUntil(this.unsubscribe$)),
      this.types$
    ).subscribe(([memberFlag, surgeryFlag, types]) => {
      if (!this.documentDetails.memberdialog) {
        this.filteredTypes = types.filter((item) => {
          return item.description != 'UB04' && item.description != 'HCFA';
        });
        if (surgeryFlag && !surgeryFlag.value) {
          this.filteredTypes = this.filteredTypes.filter((item) => {
            return item.documentTypeId != DocumentTypeEnum.surgeryplusCard;
          });
        }
      }
      if (memberFlag && memberFlag.value) {
        this.filteredTypes.push(this.memberCardEntry);
      }
    });

    this.applyFormDetails();
  }

  get documentType(): number {
    const val = this.formGroup.get('documentTypeId').value;
    if (!val) {
      return -1;
    }

    return val.documentTypeId;
  }

  ngOnChanges() {
    this.applyFormDetails();
  }

  onCommentChanged(event: any) {
    this.documentDetails.comment = event.target.value;
  }

  private get documentTypeId(): number {
    const documentType = this.formGroup.get('documentTypeId')
      .value as DocumentType;
    return documentType ? documentType.documentTypeId : null;
  }

  showProviderOnlyCheck(): boolean {
    return this.documentTypeId === this.memberCardEntry.documentTypeId;
  }
  disablePreview(): boolean {
    return this.documentTypeId === this.memberCardEntry.documentTypeId;
  }

  onAdd() {
    const documentType: DocumentType =
      this.formGroup.get('documentTypeId').value;
    this.documentDetails.documentTypeId = documentType.documentTypeId;
    if (documentType.isCommentAllowed) {
      const commentControl = this.formGroup.get('comment');

      this.documentDetails.comment = commentControl.value;
    }
    if (documentType.documentTypeId === this.memberCardEntry.documentTypeId) {
      this.documentDetails.generateMemberIdCard = true;
      this.documentDetails.generateProviderOnlyMemberCard = this.formGroup.get(
        'generateProviderOnlyMemberCard'
      ).value;
    } else {
      this.documentDetails.documentHtml = this.getGeneratedMarkup();
    }
    this.documentDetails.name = this.formGroup.get('name').value;

    this.dialogRef.close(this.documentDetails);
  }

  getTypeDisplayValue(value: any): string {
    return value.description;
  }

  showPreviewWindow() {
    const win = window.open('', '_blank', 'resizable,scrollbars,status');

    win.document.write(this.getGeneratedMarkup());
    win.document.title = 'CareHub | Document Preview';

    return false;
  }

  private getGeneratedMarkup() {
    return document.getElementById('generated_document').innerHTML;
  }

  private applyFormDetails() {
    if (this.documentDetails && this.formGroup) {
      this.formGroup.patchValue({
        name: this.documentDetails.name,
        documentTypeId: this.documentDetails.documentTypeId,
        comment: this.documentDetails.comment,
      });
    }
  }
}
