<div class="grid-container">
  <ch-header *ngIf="showHeader()"></ch-header>
  <mat-sidenav-container
    class="all-wrap"
    fullscreen
    id="mainContent"
    [ngClass]="{ 'right-scroll-claimed': rightScrollClaimed }"
  >
    <mat-sidenav
      *ngIf="currentBusinessModuleId$ | async"
      #sidenav
      class="sidenav mat-elevation-z4"
      [opened]="!isScreenSmall()"
      [mode]="isScreenSmall() ? 'over' : 'side'"
    >
      <ch-nav></ch-nav>
    </mat-sidenav>

    <main class="content">
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-container>
</div>
<div *ngIf="isRouting" class="routing">
  <div>&nbsp;</div>
</div>
