import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from 'carehub-root/state/app.state';
import { BaseComponent } from 'carehub-shared/components/base-component';
import { WindowEventConsumerService } from 'carehub-shared/services/window-event-consumer.service';
import * as fromSharedActions from 'carehub-shared/state/shared.actions';
import { takeUntil } from 'rxjs/operators';
import { FrameContextService } from './frame-context.service';

/* BUT JOHN, WHY!?!?! WHY DO WE DO THIS?
 * 1) Nice in contact has no test environment
 * 2) Nice in contact only allows specifying one hosted site, visible to all users,
 *     for the iframe integration
 * 3) the iframe message api must specify the intended recipients's host name
 * 4) We need to be able to test this integration e2e without breaking prod
 **/
interface IEventLogEntry {
  direction: 'upstream' | 'downstream';
  origin: string;
  data: any;
}
enum EnvironmentOptions {
  'Local' = 'lcl',
  'Develop' = 'dev',
  'Test' = 'tst',
  'UAT' = 'uat',
  'Stage' = 'stg',
}

/**
 * provides a layer between the call validation component and the desired test environment,
 *  so that we can test nice in any environment even if nice isn't so nice about the iframe url.
 */
@Component({
  selector: 'ch-call-context-swap',
  templateUrl: './call-context-swap.component.html',
  styleUrls: ['./call-context-swap.component.scss'],
})
export class CallContextSwapComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  private hostMapping: { [key in EnvironmentOptions]: string } = {
    lcl: 'http://localhost:4200',
    dev: 'https://carehub.dev.edhc.com',
    tst: 'https://carehub.tst.edhc.com',
    uat: 'https://carehub.uat.edhc.com',
    stg: 'https://carehub-stg.edhc.com/',
  };
  public events: IEventLogEntry[] = [];
  public get upstreamCount(): number {
    return this.events.filter((x) => x.direction == 'upstream').length;
  }
  public get downstreamCount(): number {
    return this.events.filter((x) => x.direction == 'downstream').length;
  }
  public hostList: { key: string; value: string }[] = Object.keys(
    EnvironmentOptions
  ).map((x: keyof typeof EnvironmentOptions) => ({
    key: EnvironmentOptions[x],
    value: x,
  }));
  public targetHost: string;

  @ViewChild('hostedIframe') hostedIframe: ElementRef;

  constructor(
    private windowEventConsumerService: WindowEventConsumerService,
    private frameContextService: FrameContextService,
    private sharedStore: Store<fromRoot.State>
  ) {
    super();
  }

  // #region setup/teardown actions
  ngOnInit(): void {
    // forward window events downstream to the iframe
    this.windowEventConsumerService.events$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (msg) => {
          this.forwardDownstreamMessage(msg);
        },
        (err) => console.error(err)
      );
    // configure header to hide normal nav options
    this.sharedStore.dispatch(
      new fromSharedActions.SetHeaderOptions({
        iconNavigationLink: 'call-validation',
        shouldShowHelp: false,
        shouldShowImpersonator: false,
        shouldShowModules: false,
        shouldShowNotifications: false,
        shouldShowSearch: false,
      })
    );
  }
  ngAfterViewInit(): void {
    // set up load listener so that child frame events are forwarded upstream
    this.setupChildListener();
  }
  protected onDestroy(): void {
    this.frameContextService.childOrigin = null;
    this.sharedStore.dispatch(
      new fromSharedActions.SetHeaderOptions({
        iconNavigationLink: 'member-services-mgmt/dashboard',
        shouldShowHelp: true,
        shouldShowImpersonator: true,
        shouldShowModules: true,
        shouldShowNotifications: true,
        shouldShowSearch: true,
      })
    );
  }
  // #endregion setup/teardown actions

  /** called whan a target host is selected (or deselected) */
  onSelect(env?: EnvironmentOptions) {
    this.frameContextService.childOrigin = env ? this.hostMapping[env] : null;
    this.targetHost = this.frameContextService.getChildDest('/call-validation');
    if (this.hostedIframe.nativeElement.src !== this.targetHost) {
      // each time src is set the frame reloads, even if there is no change,
      //  so only set if there is a delta.
      this.hostedIframe.nativeElement.src = this.targetHost;
    }
  }
  /** dumps the events log to the console */
  onDumpLogToConsole(): void {
    console.log(this.events.slice());
  }
  /** clears the events log */
  onClearLog(): void {
    this.events = [];
  }

  /** forwards a received message from this windows host to the selected target */
  private forwardDownstreamMessage(message: MessageEvent) {
    this.hostedIframe.nativeElement.contentWindow.postMessage(
      message.data,
      this.targetHost
    );
    message.stopPropagation();
    message.stopImmediatePropagation();
    this.events.push({
      direction: 'downstream',
      origin: message.origin,
      data: message.data,
    });
  }
  /** forwards a message received from this windows child to the host */
  private forwardUpstreamMessage(message: MessageEvent) {
    this.windowEventConsumerService.postMessage(
      message.data,
      this.frameContextService.hostOrigin
    );
    message.stopPropagation();
    message.stopImmediatePropagation();
    this.events.push({
      direction: 'upstream',
      origin: message.origin,
      data: message.data,
    });
  }
  /** sets up the listener for upstream events. since the listener attaches to the current window, should only be called once */
  private setupChildListener(): void {
    window.addEventListener(
      'message',
      (event: MessageEvent) => {
        if (event.origin !== this.frameContextService.childOrigin) {
          return;
        }
        this.forwardUpstreamMessage(event);
      },
      false
    );
  }
}
