import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

const apiRewriteMap: Record<string, string> = {
  chiliSauceApiRoot: 'carehub',
  securityApiRoot: 'security',
  featureServiceUri: 'flags',
};

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  static initialized = false;
  constructor(private authService: AuthService) {
    console.log('AppSettingsService::ctor()');
  }

  async load() {
    if (AppSettingsService.initialized === true) {
      return;
    }

    AppSettingsService.initialized = true;
    console.log('AppSettingsService::load()');

    const response = await fetch('/assets/appSettings.php');
    const text = await response.text();

    let json: any = null;

    // when running locally the whole PHP file is served as
    // static content rather than executing, just ignore.
    if (text[0] !== '<') {
      json = JSON.parse(text);
    }

    if (json) {
      for (const entry of Object.keys(json)) {
        const value: string = json[entry];
        const parts = entry.split(':');

        let count = 0;
        let environmentContainer: any = environment;
        for (const keyPart of parts) {
          count++;

          if (count === parts.length) {
            console.log(
              `Replacing entry with Key: ${keyPart} \n` +
                `  Old: '${environmentContainer[keyPart]}'\n` +
                `  New: '${value}'\n`
            );
            environmentContainer[keyPart] = value;
          } else {
            let tempContainer = environmentContainer[keyPart];
            if (!tempContainer) {
              environmentContainer[keyPart] = {};
              tempContainer = environmentContainer[keyPart];
            }
            environmentContainer = tempContainer;
          }
        }

        // todo: remove this when all the goofy names are gone
        if (entry in apiRewriteMap) {
          environment.apis[apiRewriteMap[entry]] = value;
        }
      }
    } else {
      console.warn('no AppSettings available');
    }

    this.authService.initialize();
  }
}
