<mat-form-field
  class="common-fields"
  appearance="outline"
  floatLabel="always"
  [ngClass]="{ 'ch-use-filter-styles': !useFormStyles, 'ch-compact': compact }"
>
  <mat-select
    [formControl]="multiSelectCtrl"
    [placeholder]="placeholder"
    [multiple]="true"
    class="multi-select"
    (click)="$event.stopImmediatePropagation()"
    ngDefaultControl
  >
    <mat-option
      ><ngx-mat-select-search
        ngxMatSelectSearchClear
        [showToggleAllCheckbox]="true"
        [toggleAllCheckboxIndeterminate]="selectAllShowIntermediate()"
        [toggleAllCheckboxChecked]="selectAllChecked()"
        (toggleAll)="toggleSelectAll($event)"
        placeholderLabel="Search"
        noEntriesFoundLabel="No Results"
        [formControl]="multiSelectFilterCtrl"
        ngDefaultControl
      ></ngx-mat-select-search
    ></mat-option>
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ renderDisplayValue(option, 'Null') }}
    </mat-option>
  </mat-select>
</mat-form-field>
