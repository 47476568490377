import { ValidatorFn, Validators } from '@angular/forms';
import * as validatorMethods from 'carehub-shared/validators';
import 'reflect-metadata';

// eslint-disable-next-line max-len
export const emailValidatorRegEx =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export interface FormDefinition {
  groupDefinition: { [key: string]: any };
  validationMessages: { [key: string]: { [key: string]: string } };
}
export interface FieldDetails {
  /**
   * the name of the field. Must match the base object property name
   */
  fieldName: string;
  /**
   * validation methods specified on the base object to ignore. defined in 'carehub-shared/validators'
   */
  ignore?: any[];
  /**
   * override the required metadata from the base object. Null/undefined uses the base object metadata.
   */
  required?: boolean;
}

export class FormDefinitionBuilder {
  public validationMessages: { [key: string]: { [key: string]: string } } = {};
  public groupDefinition: any = {};

  public build(
    prototype: any,
    fields: (string | FieldDetails)[]
  ): FormDefinition {
    for (const field of fields) {
      this.processField(prototype, field);
    }

    return {
      groupDefinition: this.groupDefinition,
      validationMessages: this.validationMessages,
    };
  }

  public addCustom(
    field: string,
    typeName: string,
    message: string,
    fn: ValidatorFn
  ) {
    let validators: ValidatorFn[] = null;
    if (!this.groupDefinition[field]) {
      validators = [];
      this.groupDefinition[field] = ['', validators];
    } else {
      validators = this.groupDefinition[field][1];
    }

    validators.push(fn);

    if (!this.validationMessages[field]) {
      this.validationMessages[field] = {};
    }

    this.validationMessages[field][typeName] = message;
  }

  private processField(prototype: any, field: string | FieldDetails) {
    let fieldDetails: FieldDetails = null;
    if ((<any>field).fieldName) {
      fieldDetails = <FieldDetails>field;
    } else {
      fieldDetails = { fieldName: <string>field };
    }
    const validators: ValidatorFn[] = [];
    this.groupDefinition[fieldDetails.fieldName] = ['', validators];

    this.collectValidatorsImpl(prototype, fieldDetails, validators);
  }

  public collectValidators(
    prototype: any,
    fieldName: string,
    validators: ValidatorFn[],
    ignore: any[] = null
  ) {
    this.collectValidatorsImpl(
      prototype,
      <FieldDetails>{ fieldName: fieldName, ignore: ignore },
      validators
    );
  }
  private collectValidatorsImpl(
    prototype: any,
    field: FieldDetails,
    validators: ValidatorFn[]
  ) {
    const ignore = field.ignore;
    const fieldName = field.fieldName;

    function shouldInclude(m: Function) {
      return !ignore || ignore.indexOf(m) === -1;
    }

    if (shouldInclude(validatorMethods.MinValue)) {
      this.checkForMinValueValidation(prototype, fieldName, validators);
    }

    if (shouldInclude(validatorMethods.MaxValue)) {
      this.checkForMaxValueValidation(prototype, fieldName, validators);
    }

    if (shouldInclude(validatorMethods.StringLength)) {
      this.checkForLengthValidation(prototype, fieldName, validators);
    }

    if (shouldInclude(validatorMethods.EmailAddress)) {
      this.checkForEmailValidation(prototype, fieldName, validators);
    }

    if (field.required != null) {
      if (field.required) {
        validators.push(Validators.required);
        if (!this.validationMessages[fieldName]) {
          this.validationMessages[fieldName] = {};
        }
        this.validationMessages[fieldName]['required'] =
          'Please provide a value.';
      }
    } else {
      if (shouldInclude(validatorMethods.Required)) {
        this.checkForRequiredValidation(prototype, fieldName, validators);
      }
    }

    if (shouldInclude(validatorMethods.RegularExpression)) {
      this.checkForRegexValidation(prototype, fieldName, validators);
    }
  }

  private checkForRegexValidation(
    prototype: any,
    field: string,
    validators: any[]
  ) {
    const regex = validatorMethods.getRegularExpression(prototype, field);
    if (regex) {
      validators.push(Validators.pattern(regex.pattern));
      if (!this.validationMessages[field]) {
        this.validationMessages[field] = {};
      }
      this.validationMessages[field]['pattern'] = regex.message;
    }
  }

  private checkForRequiredValidation(
    prototype: any,
    field: string,
    validators: ValidatorFn[]
  ) {
    const isRequired = validatorMethods.getRequired(prototype, field);
    if (isRequired) {
      validators.push(Validators.required);
      if (!this.validationMessages[field]) {
        this.validationMessages[field] = {};
      }
      this.validationMessages[field]['required'] = 'Please provide a value.';
    }
  }

  private checkForEmailValidation(
    prototype: any,
    field: string,
    validators: ValidatorFn[]
  ) {
    const isEmail = validatorMethods.getEmailAddress(prototype, field);
    if (isEmail) {
      // todo(refactor): there is a built-in Validators.email, why aren't we using that?
      validators.push(Validators.pattern(emailValidatorRegEx));
      if (!this.validationMessages[field]) {
        this.validationMessages[field] = {};
      }
      this.validationMessages[field]['email'] =
        'Please provide a valid email address.';
    }
  }

  private checkForLengthValidation(
    prototype: any,
    field: string,
    validators: ValidatorFn[]
  ) {
    const maxLength = validatorMethods.getStringLength(prototype, field);
    if (maxLength && maxLength > 0) {
      validators.push(Validators.maxLength(maxLength));
      if (!this.validationMessages[field]) {
        this.validationMessages[field] = {};
      }
      this.validationMessages[field][
        'maxlength'
      ] = `Value cannot be longer than ${maxLength} characters.`;
    }
  }

  private checkForMinValueValidation(
    prototype: any,
    field: string,
    validators: ValidatorFn[]
  ) {
    const minValue = validatorMethods.getMinValue(prototype, field);
    if (minValue != null && !isNaN(minValue)) {
      validators.push(Validators.min(minValue));
      if (!this.validationMessages[field]) {
        this.validationMessages[field] = {};
      }
      this.validationMessages[field][
        'minValue'
      ] = `Value must be greater than ${minValue}.`;
    }
  }

  private checkForMaxValueValidation(
    prototype: any,
    field: string,
    validators: ValidatorFn[]
  ) {
    const maxValue = validatorMethods.getMaxValue(prototype, field);
    if (maxValue != null && !isNaN(maxValue)) {
      validators.push(Validators.max(maxValue));
      if (!this.validationMessages[field]) {
        this.validationMessages[field] = {};
      }
      this.validationMessages[field][
        'maxValue'
      ] = `Value must be greater than ${maxValue}.`;
    }
  }
}
