import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Contract } from 'carehub-api/models/contract/contract';
import { LookupService } from 'carehub-shared/services/lookup.service';
import { LookupTypes } from 'carehub-shared/services/models/lookup-types.enum';

@Component({
  selector: 'ch-contract-banner',
  templateUrl: './contract-banner.component.html',
  styleUrls: ['./contract-banner.component.scss'],
})
export class ContractBannerComponent {
  @Input() contract: Contract;
  constructor(private lookupService: LookupService, private router: Router) {}

  get contractStatus() {
    return this.lookupService.getDescriptionByTypeAndId(
      LookupTypes.ContractStatuses,
      this.contract?.statusId
    );
  }

  get contractType() {
    return this.lookupService.getDescriptionByTypeAndId(
      LookupTypes.ContractTypes,
      this.contract?.contractTypeId
    );
  }

  goBack(): any {
    if (location.href.includes('provider-network-mgmt')) {
      this.router.navigateByUrl('provider-network-mgmt/contracts');
    } else {
      this.router.navigateByUrl('member-services-mgmt/contracts');
    }
  }
}
