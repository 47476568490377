/**
 * Contains configuration information for the application.
 * similar to environment settings, but static across environments
 */
export const settings = {
  /**
   * if no default url can be found by role, uses this fallback
   */
  fallbackUrl: '/home',
  /**
   * lists roles and their associated default url. if there are more than one match, the first matching url should be used
   */
  defaultUrlByRole: [
    {
      roles: [
        'Care Advocates',
        'Care Advocate Seniors',
        'Care Advocate Managers',
      ],
      url: '/member-services-mgmt',
    },
    {
      roles: [
        'Client Management',
        'Client Relations',
        'Client Relations Managers',
        'Production Support',
        'Management',
      ],
      url: '/client-acct-mgmt',
    },
    {
      roles: ['Network Management', 'Network Sales'],
      url: '/provider-network-mgmt',
    },
    {
      roles: [
        'Billing Specialist',
        'Claims Processing',
        'Claims Entry/Validation',
      ],
      url: '/finance',
    },
    {
      roles: ['Administrators', 'Super Admin'],
      url: '/security',
    },
  ],
};
