<ch-smartlist-grid
  [includedColumns]="includedColumns"
  disableAdd="true"
  [smartListResult]="dataSource"
  [smartListCriteria]="smartListCriteria"
  gridTitle="History"
  (page)="onPage($event)"
  (sort)="onSort($event)"
>
</ch-smartlist-grid>
