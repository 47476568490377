import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  DecimalFormatterOptions,
  DecimalFormatterService,
} from '../services/converters/decimal-formatter.service';
import { InputFormatter } from '../services/converters/input-formatter.interface';
import { FormatterDirectiveBase } from './base/formatter.directive.base';

@Directive({
  selector: '[enCurrencyFormatter]',
  host: {
    '(blur)': 'onTouched()',
    '(change)': 'onChange($event.target.value)',
  },
})
export class CurrencyFormatterDirective
  extends FormatterDirectiveBase
  implements ControlValueAccessor, OnInit
{
  public formatter: InputFormatter;
  public formatterOptions: Partial<DecimalFormatterOptions>;
  private defaultOptions: Partial<DecimalFormatterOptions> = {
    multiplier: 1,
    prefix: '$',
    roundToDecimal: 2,
  };
  @Input('enCurrencyFormatter') public set instanceOptions(
    value: Partial<DecimalFormatterOptions>
  ) {
    this.formatterOptions = Object.assign({}, this.defaultOptions, value);
  }
  constructor(
    renderer: Renderer2,
    element: ElementRef,
    controlBinder: NgControl,
    decimalFormatter: DecimalFormatterService
  ) {
    super(renderer, element, controlBinder);
    this.formatter = decimalFormatter;
    this.formatterOptions = this.defaultOptions;
  }
}
