<ch-base-banner [goBack]="this.goBack.bind(this)">
  <div class="banner-content" *ngIf="contract">
    <div>
      <span>NAME: </span>
      {{ contract?.name }}
    </div>
    <mat-divider class="banner-dividers" [vertical]="true"> </mat-divider>
    <div>
      <span>TYPE: </span>
      {{ contractType | async }}
    </div>
    <mat-divider class="banner-dividers" [vertical]="true"> </mat-divider>
    <div>
      <span>NPI: </span>
      {{ contract?.npi }}
    </div>
    <mat-divider class="banner-dividers" [vertical]="true"></mat-divider>
    <div>
      <span>STATUS: </span>
      {{ contractStatus | async }}
    </div>
  </div>
</ch-base-banner>
