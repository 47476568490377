<div class="base-banner">
  <div
    class="banner banner-display mat-elevation-z6"
    [ngClass]="{
      hasOne: goBack == null,
      hasThree: goBack != null,
      'warning-background': useWarningBackground
    }"
  >
    <span class="arrow-button-holder ch-clickable" *ngIf="goBack">
      <mat-icon (click)="goBack()">keyboard_arrow_left</mat-icon>
    </span>

    <mat-divider
      class="banner-dividers"
      [vertical]="true"
      *ngIf="goBack"
    ></mat-divider>

    <ng-content class="banner-content"></ng-content>
  </div>
</div>
