<div class="grid-container">
  <mat-card>
    <mat-card-title>Smart Field (Multi-line)</mat-card-title>
    <ch-smart-field
      [formGroup]="formConfig?.formGroup"
      [displayMessage]="formConfig?.displayMessage"
      propertyName="description"
      displayName="Description"
      type="multi"
    >
    </ch-smart-field>
  </mat-card>

  <mat-card>
    <mat-card-title>Smart Field (Text Box)</mat-card-title>
    <ch-smart-field
      [formGroup]="formConfig?.formGroup"
      [displayMessage]="formConfig?.displayMessage"
      propertyName="name"
      displayName="Name"
    >
    </ch-smart-field>
    <div>
      <label>Note:</label>@StringLength used on objects field to restrict length
      to 6 characters.
    </div>
  </mat-card>

  <mat-card>
    <mat-card-title>Smart Field (Phone)</mat-card-title>
    <ch-smart-field
      [formGroup]="formConfig?.formGroup"
      [displayMessage]="formConfig?.displayMessage"
      propertyName="phone"
      displayName="Phone"
      type="phone"
    >
    </ch-smart-field>
  </mat-card>

  <mat-card>
    <mat-card-title>Smart Field (Date)</mat-card-title>
    <ch-smart-field
      [formGroup]="formConfig?.formGroup"
      [displayMessage]="formConfig?.displayMessage"
      propertyName="dob"
      displayName="Birth Date"
      type="date"
    >
    </ch-smart-field>
  </mat-card>

  <!-- <mat-card>
    <mat-card-title>Smart Field (Time)</mat-card-title>
    <ch-smart-field
      [formGroup]="formConfig?.formGroup"
      [displayMessage]="formConfig?.displayMessage"
      propertyName="dob"
      displayName="Time"
      type="time"
    >
    </ch-smart-field>
  </mat-card> -->

  <mat-card>
    <mat-card-title>Smart Field (SSN)</mat-card-title>
    <ch-smart-field
      [formGroup]="formConfig?.formGroup"
      [displayMessage]="formConfig?.displayMessage"
      propertyName="ssn"
      displayName="SSN"
      type="ssn"
    >
    </ch-smart-field>
  </mat-card>

  <mat-card>
    <mat-card-title>Smart Field (Currency)</mat-card-title>
    <ch-smart-field
      [formGroup]="formConfig?.formGroup"
      [displayMessage]="formConfig?.displayMessage"
      propertyName="cost"
      displayName="Cost"
      type="currency"
    >
    </ch-smart-field>
  </mat-card>

  <mat-card>
    <mat-card-title>Smart Field (Percentage)</mat-card-title>
    <ch-smart-field
      [formGroup]="formConfig?.formGroup"
      [displayMessage]="formConfig?.displayMessage"
      propertyName="percentage"
      displayName="Percentage"
      type="percentage"
    >
    </ch-smart-field>
  </mat-card>

  <mat-card>
    <mat-card-title>Smart Field (Checkbox)</mat-card-title>
    <ch-smart-field
      [formGroup]="formConfig?.formGroup"
      [displayMessage]="formConfig?.displayMessage"
      propertyName="isSmart"
      displayName="Is Smart"
      type="checkbox"
    >
    </ch-smart-field>
  </mat-card>

  <mat-card>
    <mat-card-title>Smart Field (Object Picklist)</mat-card-title>
    <!-- Note: When the optionPropertyName property is set to a dot (.) the result will be the entire object is set on the FormGroup -->
    <ch-smart-field
      [formGroup]="formConfig?.formGroup"
      [displayMessage]="formConfig?.displayMessage"
      optionPropertyName="."
      propertyName="color"
      displayName="Color"
      type="picklist"
      [filterInactive]="false"
      [entries]="colors"
      [dropdownDisplayFn]="getColorDisplayValue"
    >
    </ch-smart-field>
    <div><label>Note:</label>@Required used on objects field.</div>
  </mat-card>

  <mat-card>
    <mat-card-title>Smart Field (Primitive Picklist)</mat-card-title>
    <!-- Note: When the optionPropertyName property is set to a field name the result will be that only the selected field will be set on the FormGroup -->
    <ch-smart-field
      [formGroup]="formConfig?.formGroup"
      [displayMessage]="formConfig?.displayMessage"
      optionPropertyName="name"
      propertyName="colorName"
      displayName="Color"
      type="picklist"
      [filterInactive]="false"
      [entries]="colors"
      [dropdownDisplayFn]="getColorDisplayValue"
    >
    </ch-smart-field>
  </mat-card>
  <mat-card>
    <mat-card-title>Lookup Select</mat-card-title>
    <ch-lookup-select
      placeholder="Name..."
      lookupType="RawClaimStatuses"
      [(selected)]="lookupSingleSelected"
    ></ch-lookup-select>
    <br />
    Selected:
    {{ lookupSingleSelected ? lookupSingleSelected.description : 'None' }}
  </mat-card>
  <mat-card>
    <mat-card-title>Lookup Multi Select</mat-card-title>
    <ch-lookup-multi-select
      placeholder="Name..."
      lookupType="RawClaimStatuses"
      [(selected)]="lookupMultiSelected"
    ></ch-lookup-multi-select>
    <br />
    Selected:
    <ul>
      <li *ngFor="let selected of lookupMultiSelected || []">
        {{ selected ? selected.description : 'undefined' }}
      </li>
    </ul>
  </mat-card>
  <mat-card>
    <mat-card-title>Checkbox filter</mat-card-title>
    <ch-check-filter [label]="'Test'"></ch-check-filter>
    <ch-check-filter
      [(filterState)]="checkboxFilterState"
      [labelMap]="checkboxFilterLabels"
    ></ch-check-filter>
    <br />
    Checkbox filter state: {{ checkboxFilterState }}
  </mat-card>
  <mat-card>
    <mat-card-title>User picker</mat-card-title>
    <ch-user-select
      placeholder="Name... (No Team)"
      [(user)]="selectedUser"
    ></ch-user-select>
    <br />
    Selected: {{ selectedUser ? selectedUser.displayName : 'None' }}
  </mat-card>
  <mat-card>
    <mat-card-title
      >Team User picker ({{
        targetTeam ? targetTeam.name : 'No Team'
      }})</mat-card-title
    >
    <ch-user-select
      [placeholder]="'Name... (Member of team)'"
      [teamId]="targetTeam ? targetTeam.teamId : null"
      [(user)]="selectedTeamUser"
    ></ch-user-select>
    <br />
    Selected: {{ selectedTeamUser ? selectedTeamUser.displayName : 'None' }}
  </mat-card>
  <mat-card>
    <mat-card-title>Email Chips Field</mat-card-title>
    <ch-emails-field
      [formGroup]="formConfig?.formGroup"
      propertyName="emails"
      [emails]="emails"
    >
    </ch-emails-field>
  </mat-card>

  <mat-card>
    <mat-card-title>Auto Complete Field</mat-card-title>
    <ch-auto-complete-field
      [dataSource$]="colors$"
      [criteria$]="colorsCriteria$"
      [formGroup]="formConfig?.formGroup"
      [displayMessage]="formConfig?.displayMessage"
      propertyName="otherColor"
      [displayFn]="getColorDisplayValue"
      labelName="Other Color"
    >
    </ch-auto-complete-field>
  </mat-card>

  <mat-card>
    <mat-card-title>Error test</mat-card-title>
    <button mat-stroked-button color="warn" (click)="throwJsError()">
      Front end error Test
    </button>
    <br />
    <br />
    <button mat-stroked-button color="warn" (click)="throwHttpError()">
      Server error test
    </button>
  </mat-card>

  <mat-card class="smart-grid">
    <mat-card-title>Smart Grid</mat-card-title>
    <ch-smartlist-grid
      [includedColumns]="includedColumns"
      disableAdd="true"
      [smartListResult]="colors"
      gridTitle="Colors"
    >
    </ch-smartlist-grid>
  </mat-card>
</div>

<div class="json-viewer">
  <div>FormGroup Value</div>
  <pre>{{ formConfig?.formGroup.value | json }}</pre>
</div>
