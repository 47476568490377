import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MaterialModule } from 'carehub-shared/material.module';
import { SharedModule } from 'carehub-shared/shared.module';
import { ActivitiesComponent } from './components/activities.component';
import { ActivityDialogComponent } from './components/activity-dialog/activity-dialog.component';

export const routes: Routes = [
  {
    path: '',
    component: ActivitiesComponent,
  },
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes), MaterialModule],
  exports: [ActivityDialogComponent],
  entryComponents: [ActivityDialogComponent],
  declarations: [ActivitiesComponent, ActivityDialogComponent],
})
export class ActivitiesSubModule {
  constructor() {}
}
