import { NgModule } from '@angular/core';
import { MaterialModule } from 'carehub-shared/material.module';
import { SharedModule } from 'carehub-shared/shared.module';
import { ComposeCaseMessageComponent } from './components/task-compose-message/task-compose-message.component';
import { TaskDialogComponent } from './components/task-dialog/task-dialog.component';
import { TaskHistoryDialogComponent } from './components/task-history-dialog/task-history-dialog.component';
import { CaseMessageDialogComponent } from './components/task-message-dialog/task-message-dialog.component';

@NgModule({
  imports: [SharedModule, MaterialModule],
  entryComponents: [TaskDialogComponent],
  declarations: [
    TaskDialogComponent,
    TaskHistoryDialogComponent,
    CaseMessageDialogComponent,
    ComposeCaseMessageComponent,
  ],
  providers: [],
})
export class TasksSubModule {}
