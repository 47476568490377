import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BaseComponent } from 'carehub-shared/components/base-component';
import { LookupService } from 'carehub-shared/services/lookup.service';
import { LookupTypes } from 'carehub-shared/services/models/lookup-types.enum';
import { LookupItem } from 'carehub-shared/state/shared.reducer';
import { Observable, fromEvent } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

/**
 * renders an input tied to a mat auto select that, for the specified lookup type,
 * shows the filtered list of members based on the input text. When a member is
 * selected, emits the selected lookup (selectedChange) and it's id (selectedIdChange)
 * Supports 2 way binding on selected and selectedId
 */
@Component({
  selector: 'ch-lookup-select',
  templateUrl: './lookup-select.component.html',
  styleUrls: ['./lookup-select.component.scss'],
})
export class LookupSelectComponent extends BaseComponent implements OnInit {
  @ViewChild('filterInput', { static: true })
  filterInput: ElementRef;
  filteredItems$: Observable<LookupItem[]>;
  private allItems: LookupItem[];

  private _selectedId: number;
  public get selected(): LookupItem {
    return this.allItems
      ? this.allItems.find((x) => x.id === this._selectedId)
      : undefined;
  }
  @Input()
  public set selected(selected: LookupItem) {
    if (this.selectedId !== (selected ? selected.id : undefined)) {
      this._selectedId = selected?.id;
      this.selectedChange.emit(this.selected);
      this.selectedIdChange.emit(this.selectedId);
    }
  }
  @Output()
  public selectedChange = new EventEmitter<LookupItem>();
  public get selectedId(): any {
    return this._selectedId;
  }
  @Input()
  public set selectedId(value: any) {
    if (this.selectedId !== value) {
      this._selectedId = value;
    }
  }
  @Output()
  public selectedIdChange = new EventEmitter<number>();

  private lookupType: LookupTypes;

  public get lookupTypeKey(): keyof typeof LookupTypes {
    return this.lookupType;
  }
  @Input('lookupType')
  public set lookupTypeKey(value: keyof typeof LookupTypes) {
    this.lookupType =
      value && LookupTypes.hasOwnProperty(value)
        ? LookupTypes[value]
        : undefined;
    if (this.lookupType == null) {
      console.error(
        `Unable to map ${value} to a valid lookup type. Try adding ${value} to the lookup types enum.`
      );
    }
  }

  @Input() placeholder = '';
  @Input() inputClass = '';
  @Input() enabled = true;
  /**
   * used to display the lookup item. defaults to showing the description.
   * @param i the lookup item to display
   */
  @Input()
  displayLookupItem = (i: LookupItem): string => (i ? i.description : '');
  /**
   * used to show only a subset of the lookup's members in the picker.
   * @param i the lookup item to include or exclude
   */
  @Input()
  filterLookupItems = (i: LookupItem): boolean => true;

  constructor(private lookupService: LookupService) {
    super();
  }

  ngOnInit() {
    this.lookupService
      .getAllByType(this.lookupType)
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe(
        (items: LookupItem[]) =>
          (this.allItems = items.filter(this.filterLookupItems)),
        (error: any) => {
          console.error(error);
        }
      );

    this.filteredItems$ = fromEvent(
      this.filterInput.nativeElement,
      'input'
    ).pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => this.filterLookups(event.target.value))
    );
  }
  protected onDestroy(): void {
    this.selectedChange.complete();
    this.selectedIdChange.complete();
  }

  /**
   * called by mat picker when a selection is made
   * @param selected the selected item
   */
  public onSelection(selected: LookupItem): void {
    this.selected = selected;
  }
  /**
   * called when focus is moved from the input, to null the selected if the
   * value is empty
   * @param event the blur event
   */
  public onInputBlur(event: FocusEvent) {
    const target = event.target as HTMLTextAreaElement;
    if (target.value === '') {
      this.selected = null;
    }
  }

  /**
   * returns the subset of the base collection whose description contains the filter term
   * @param filterBy the value to match
   * @param baseCollection the lookup items to scan
   */
  private filterLookups(filterBy: string): LookupItem[] {
    if (filterBy) {
      filterBy = filterBy.toLowerCase();
    }

    if (this.allItems) {
      return this.allItems.filter(
        (holderType) =>
          holderType.description.toLowerCase().indexOf(filterBy) >= 0
      );
    } else {
      console.error('No Items to filter');
      return null;
    }
  }
}
