import { Directive, HostListener } from '@angular/core';

// todo: this needs more documentation
// where and why is it used?
@Directive({
  selector: '[enSmartBlur]',
})
export class SmartBlurDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const element = event.target as any;
    if (event.key === 'Enter') {
      element.blur();
      element.focus();
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    const element = event.target as any;
    const value = element.value;
    if (!value) {
      element.blur();
      element.focus();
    }
  }

  // search? is this some synthetic event?
  @HostListener('search', ['$event'])
  search(event: any) {
    console.log('smart-blur:SEARCH');
    const element = event.target as any;
    const value = element.value;

    if (!value) {
      element.blur();
      element.focus();
    }
  }
}
