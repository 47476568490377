import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'carehub-shared/components/base-component';

@Component({
  selector: 'ch-base-banner',
  templateUrl: './base-banner.component.html',
  styleUrls: ['./base-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BaseBannerComponent extends BaseComponent implements OnInit {
  /** input placeholder text */
  @Input() goBack: () => any;
  @Input() useWarningBackground: boolean = false;

  constructor() {
    super();
  }

  /** lifecycle hook for startup */
  ngOnInit() {}
  /** lifecycle hook to clean up resources */
  protected onDestroy(): void {}
}
