import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Store } from '@ngrx/store';
import { emailValidatorRegEx } from 'carehub-root/shared/form-definition-builder/form-definition-builder';
import { SharedState } from 'carehub-root/shared/state/shared.reducer';
import { Utils } from 'carehub-root/shared/utils';
import * as sharedActions from 'carehub-shared/state/shared.actions';
import { EmailAddress } from 'carehub-shared/validators';

export class Email {
  @EmailAddress()
  address: string;
  removable = true;
}

@Component({
  selector: 'ch-emails-field',
  templateUrl: './emails-field.component.html',
  styleUrls: ['../smart-field/smart-field.component.scss'],
})
export class EmailsFieldComponent implements OnInit {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];

  @Input() formGroup: FormGroup;
  @Input() propertyName: string;
  private _emails: Email[];
  @Input() set emails(value: Email[]) {
    this._emails = value;
  }
  get emails(): Email[] {
    return this._emails;
  }
  @Input() disabled: boolean;
  isRequired = false;

  constructor(private sharedStore: Store<SharedState>) {}

  ngOnInit() {
    const control = this.formGroup.controls[this.propertyName];
    this.isRequired = Utils.hasRequiredField(control);
    this.updateValue();
  }

  onAddEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      if (value.match(emailValidatorRegEx)) {
        this.emails.push({ address: value.trim(), removable: true });
      } else {
        this.sharedStore.dispatch(
          new sharedActions.SetCurrentError(
            'Please enter a valid email address.'
          )
        );
      }
    }

    if (input) {
      input.value = '';
    }
    if (this.emails.length == 1) {
      this.updateValue();
    }
  }

  onRemoveEmail(email: Email): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
    if (index == 0) {
      this.updateValue();
    }
  }

  private updateValue() {
    if (this.formGroup) {
      this.formGroup.controls[this.propertyName].setValue(
        this.emails.map((item) => item.address).join(';')
      );
      this.formGroup.controls[this.propertyName].markAsTouched();
      this.formGroup.controls[this.propertyName].markAsDirty();
    }
  }
}
