import 'reflect-metadata';

// ---------------------------------------------------------------------------
const stringLengthMetadataKey = Symbol('stringLengthMetadataKey');

export function StringLength(length: number) {
  return Reflect.metadata(stringLengthMetadataKey, length);
}

export function getStringLength(target: any, propertyKey: string): number {
  return Reflect.getMetadata(stringLengthMetadataKey, target, propertyKey);
}

// ---------------------------------------------------------------------------
const requiredMetadataKey = Symbol('requiredMetadataKey');

export function Required() {
  return Reflect.metadata(requiredMetadataKey, true);
}

export function getRequired(target: any, propertyKey: string): boolean {
  return Reflect.getMetadata(requiredMetadataKey, target, propertyKey);
}

// ---------------------------------------------------------------------------
const emailAddressMetadataKey = Symbol('emailAddressMetadataKey');

export function EmailAddress() {
  return Reflect.metadata(emailAddressMetadataKey, true);
}

export function getEmailAddress(target: any, propertyKey: string): boolean {
  return Reflect.getMetadata(emailAddressMetadataKey, target, propertyKey);
}

// ---------------------------------------------------------------------------
const regularExpressionMetadataKey = Symbol('regularExpressionMetadataKey');

export function RegularExpression(
  regex: string | RegExp,
  message = 'Please provide a valid value.'
) {
  return Reflect.metadata(regularExpressionMetadataKey, {
    pattern: regex,
    message: message,
  });
}

export function getRegularExpression(
  target: any,
  propertyKey: string
): { pattern: string | RegExp; message: string } {
  return Reflect.getMetadata(regularExpressionMetadataKey, target, propertyKey);
}

// ---------------------------------------------------------------------------
const minValueMetadataKey = Symbol('minValueMetadataKey');

export function MinValue(value: number) {
  return Reflect.metadata(minValueMetadataKey, value);
}

export function getMinValue(target: any, propertyKey: string): number {
  return Reflect.getMetadata(minValueMetadataKey, target, propertyKey);
}

// ---------------------------------------------------------------------------
const maxValueMetadataKey = Symbol('maxValueMetadataKey');

export function MaxValue(value: number) {
  return Reflect.metadata(maxValueMetadataKey, value);
}

export function getMaxValue(target: any, propertyKey: string): number {
  return Reflect.getMetadata(maxValueMetadataKey, target, propertyKey);
}
