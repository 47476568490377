<input
  enSmartBlur
  #filterInput
  class="filter"
  [attr.aria-label]="placeholder"
  [attr.placeholder]="placeholder"
  [value]="displayName(user)"
  (blur)="onInputBlur($event)"
  [matAutocomplete]="filterType"
  [disabled]="!enabled"
/>
<mat-autocomplete
  #filterType="matAutocomplete"
  [displayWith]="displayName"
  (optionSelected)="onSelection($event.option.value)"
  [panelWidth]="350"
>
  <mat-option
    class="option"
    *ngFor="let item of filteredItems$ | async"
    [value]="item"
  >
    <span class="name">{{ item.displayName }}</span>
    <span class="email">
      (<a class="email-link" [href]="'mailto:' + item.email">{{ item.email }}</a
      >)</span
    >
  </mat-option>
</mat-autocomplete>
