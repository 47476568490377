<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>
    <mat-icon>drag_handle</mat-icon>Generate New Document
  </h2>
  <ch-smart-field
    class="name"
    [formGroup]="formGroup"
    [displayMessage]="formGroupDisplayMessage"
    propertyName="name"
    displayName="Name"
  >
  </ch-smart-field>

  <ch-smart-field
    class="documentTypeId"
    [formGroup]="formGroup"
    [displayMessage]="formGroupDisplayMessage"
    optionPropertyName="."
    propertyName="documentTypeId"
    displayName="Type"
    type="picklist"
    [filterInactive]="false"
    [entries]="filteredTypes"
    [dropdownDisplayFn]="getTypeDisplayValue"
  >
  </ch-smart-field>

  <ch-smart-field
    *ngIf="(formGroup?.controls)['documentTypeId'].value?.isCommentAllowed"
    class="comment"
    (change)="onCommentChanged($event)"
    [formGroup]="formGroup"
    [displayMessage]="formGroupDisplayMessage"
    propertyName="comment"
    displayName="Comment"
    type="multi"
  >
  </ch-smart-field>

  <ch-smart-field
    *ngIf="showProviderOnlyCheck()"
    [formGroup]="formGroup"
    [displayMessage]="formGroupDisplayMessage"
    propertyName="generateProviderOnlyMemberCard"
    displayName="Generate Provider Only Version"
    type="checkbox"
  >
  </ch-smart-field>
  <ng-container [ngSwitch]="documentType">
    <ch-surgery-plus-card-maker
      id="generated_document"
      *ngSwitchCase="10"
      [documentDetails]="documentDetails"
      [hidden]="true"
    >
    </ch-surgery-plus-card-maker>
    <ch-blank-referral-form-maker
      id="generated_document"
      *ngSwitchCase="11"
      [documentDetails]="documentDetails"
      [hidden]="true"
    >
    </ch-blank-referral-form-maker>
  </ng-container>

  <div class="footer">
    <button
      mat-button
      matTooltip="Show preview"
      (click)="showPreviewWindow()"
      [disabled]="disablePreview()"
    >
      PREVIEW
    </button>

    <button
      mat-button
      class="mat-elevation-z4"
      matTooltip="Cancel"
      mat-dialog-close
    >
      CANCEL
    </button>
    <button
      mat-button
      (click)="onAdd()"
      class="confirm"
      matTooltip="Add"
      [disabled]="!formGroup.valid"
    >
      Add
    </button>
  </div>
</div>
