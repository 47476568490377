<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>
    <mat-icon>drag_handle</mat-icon>Activity
  </h2>
  <form autocomplete="off" [formGroup]="formConfig?.formGroup">
    <mat-dialog-content>
      <div class="activity-grid-container">
        <ch-smart-field
          class="activityTypeId"
          [formGroup]="formConfig?.formGroup"
          [excludedValues]="excludedActivityTypes"
          [displayMessage]="formConfig?.displayMessage"
          propertyName="activityTypeId"
          displayName="Type"
          type="lookup"
          subtype="ActivityTypes"
        >
        </ch-smart-field>
        <ch-smart-field
          class="activityRelatedToId"
          [formGroup]="formConfig?.formGroup"
          [displayMessage]="formConfig?.displayMessage"
          propertyName="activityRelatedToId"
          displayName="Related to"
          type="lookup"
          subtype="ActivityRelatedTo"
        >
        </ch-smart-field>
        <ch-smart-field
          class="activityDirectionId"
          [formGroup]="formConfig?.formGroup"
          [displayMessage]="formConfig?.displayMessage"
          propertyName="activityDirectionId"
          displayName="Direction"
          type="lookup"
          subtype="ActivityDirections"
        >
        </ch-smart-field>
        <ch-smart-field
          class="activityReasonId"
          [formGroup]="formConfig?.formGroup"
          [excludedValues]="excludedReasonValues"
          [displayMessage]="formConfig?.displayMessage"
          propertyName="activityReasonId"
          displayName="Reason"
          type="lookup"
          subtype="ActivityReasons"
        >
        </ch-smart-field>
        <ch-smart-field
          class="activityWithId"
          [formGroup]="formConfig?.formGroup"
          [displayMessage]="formConfig?.displayMessage"
          propertyName="activityWithId"
          displayName="With"
          type="lookup"
          subtype="ActivityWiths"
        >
        </ch-smart-field>
        <ch-smart-field
          class="description"
          [formGroup]="formConfig?.formGroup"
          [displayMessage]="formConfig?.displayMessage"
          propertyName="description"
          displayName="Description"
          type="multi"
        >
        </ch-smart-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        mat-button
        [mat-dialog-close]="formConfig?.formGroup.value"
        (click)="onSave($event)"
        [disabled]="!formConfig?.formGroup.valid"
      >
        SAVE
      </button>
      <button mat-button mat-dialog-close>CANCEL</button>
    </mat-dialog-actions>
  </form>
</div>
