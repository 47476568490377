import { Pipe, PipeTransform } from '@angular/core';
import { PhoneFormatterService } from 'carehub-shared/services/converters/phone-formatter.service';

// Adapted from 'https://stackoverflow.com/questions/36895431/2932782'
@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  constructor(private formatter: PhoneFormatterService) {}
  transform(value: any, args?: any): any {
    try {
      return this.formatter.transform(value, { allowIncompleteNumbers: true });
    } catch (err) {
      console.error(err);
      return '';
    }
  }
}
