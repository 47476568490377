import { Injectable } from '@angular/core';
import {
  AbstractControlOptions,
  FormBuilder,
  ValidatorFn,
} from '@angular/forms';
import { FormConfig } from './form-config';
import { FieldDetails, FormDefinitionBuilder } from './form-definition-builder';
import { GenericValidator } from './generic-validator';

@Injectable({
  providedIn: 'root',
})
export class FormBuilderService {
  private definitionBuilder: FormDefinitionBuilder;
  constructor(private formBuilder: FormBuilder) {}

  build(
    prototype: any,
    fields: (string | FieldDetails)[],
    options?: AbstractControlOptions
  ) {
    const formConfig = new FormConfig();
    this.definitionBuilder = new FormDefinitionBuilder();
    const definition = this.definitionBuilder.build(prototype, fields);

    if (!options) {
      options = {
        updateOn: 'blur',
      };
    } else {
      if (!options.updateOn) {
        options.updateOn = 'blur';
      }
    }

    formConfig.formGroup = this.formBuilder.group(
      {
        ...definition.groupDefinition,
      },
      options
    );

    formConfig.genericValidator = new GenericValidator({
      ...definition.validationMessages,
    });

    formConfig.formGroup.valueChanges.subscribe(
      () =>
        (formConfig.displayMessage =
          formConfig.genericValidator.processMessages(formConfig.formGroup))
    );

    return formConfig;
  }

  addCustom(field: string, typeName: string, message: string, fn: ValidatorFn) {
    let validators: ValidatorFn[] = null;
    if (!this.definitionBuilder.groupDefinition[field]) {
      validators = [];
      this.definitionBuilder.groupDefinition[field] = ['', validators];
    } else {
      validators = this.definitionBuilder.groupDefinition[field][1];
    }

    validators.push(fn);

    if (!this.definitionBuilder.validationMessages[field]) {
      this.definitionBuilder.validationMessages[field] = {};
    }

    this.definitionBuilder.validationMessages[field][typeName] = message;
  }
}
