<div class="nav">
  <mat-nav-list>
    <a
      *ngFor="let menuItem of menuItems"
      [ngClass]="getClasses(menuItem)"
      mat-list-item
      routerLinkActive="active-link"
      [routerLink]="menuItem.routeName"
      >{{ menuItem.displayName }}</a
    >
  </mat-nav-list>
</div>
