import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PediatricIndicatorComponent } from 'carehub-root/business-modules/shared/case/components/member/pediatric-indicator/pediatric-indicator.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ErrorsModule } from '../errors/errors.module';
import { AssignmentToolbarComponent } from './components/controls/assignment-toolbar/assignment-toolbar.component';
import { AuditFieldsComponent } from './components/controls/audit-fields/audit-fields.component';
import { AutoCompleteFieldComponent } from './components/controls/auto-complete-field/auto-complete-field.component';
import { AutoMultiSelectComponent } from './components/controls/auto-multi-select/auto-multi-select.component';
import { AssociatedComponent } from './components/controls/base-associator-new/associated/associated.component';
import { MasterComponent } from './components/controls/base-associator-new/master/master.component';
import { BaseBannerComponent } from './components/controls/base-banner/base-banner.component';
import { DocumentsGridComponent } from './components/controls/base-documents/documents-grid/documents-grid.component';
import { CheckFilterComponent } from './components/controls/check-filter/check-filter.component';
import { ChipFieldComponent } from './components/controls/chip-field/chip-field.component';
import { ConfirmationDialogComponent } from './components/controls/confirmation-dialog/confirmation-dialog.component';
import { ContactDialogComponent } from './components/controls/contact-dialog/contact-dialog.component';
import { NameContentDividerComponent } from './components/controls/divider/name-content-divider.component';
import { NameValueDividerComponent } from './components/controls/divider/name-value-divider.component';
import { DocumentDialogComponent } from './components/controls/document-dialog/document-dialog.component';
import { DocumentGenDialogComponent } from './components/controls/document-gen-dialog/document-gen-dialog.component';
import { BlankReferralFormMakerComponent } from './components/controls/document-gen-dialog/templates/blank-referral-form-maker/blank-referral-form-maker.component';
import { SurgeryPlusCardMakerComponent } from './components/controls/document-gen-dialog/templates/surgery-plus-card-maker/surgery-plus-card-maker.component';
import { EmailDialogComponent } from './components/controls/email-dialog/email-dialog.component';
import { EmailsFieldComponent } from './components/controls/emails-field/emails-field.component';
import { ExpandableRowsTableComponent } from './components/controls/expandable-rows-table/expandable-rows-table.component';
import { InformationDialogComponent } from './components/controls/information-dialog/information-dialog.component';
import { LeftRightPanelsComponent } from './components/controls/left-right-panels/left-right-panels.component';
import { ListDialogComponent } from './components/controls/list-dialog/list-dialog.component';
import { LocationFieldComponent } from './components/controls/location-field/location-field.component';
import { LookupMultiSelectComponent } from './components/controls/lookup-multi-select/lookup-multi-select.component';
import { LookupSelectComponent } from './components/controls/lookup-select/lookup-select.component';
import { NamedItemSelectComponent } from './components/controls/named-item-select/named-item-select.component';
import { NotesDialogComponent } from './components/controls/notes-dialog/notes-dialog.component';
import { SideNavComponent } from './components/controls/side-nav/nav.component';
import { SmartFieldComponent } from './components/controls/smart-field/smart-field.component';
import { SmartPanelComponent } from './components/controls/smart-panel/smart-panel.component';
import { SmartRadioComponent } from './components/controls/smart-radio/smart-radio.component';
import { SmartTileGridComponent } from './components/controls/smart-tile-grid/smart-tile-grid.component';
import { SmartlistFilterComponent } from './components/controls/smartlist-filter/smartlist-filter.component';
import { SmartListGridTableComponent } from './components/controls/smartlist-grid/smartlist-grid-table/smartlist-grid-table.component';
import { SmartListGridComponent } from './components/controls/smartlist-grid/smartlist-grid.component';
import { AssignmentDialogComponent } from './components/controls/smartlist-queue/assignment-dialog/assignment-dialog.component';
import { SmartlistQueueComponent } from './components/controls/smartlist-queue/smartlist-queue.component';
import { ThirdPartyOrgDialogComponent } from './components/controls/third-party-org-dialog/third-party-org-dialog.component';
import { FileDropDirective } from './components/controls/upload/file-drop.directive';
import { UploadComponent } from './components/controls/upload/upload.component';
import { UserSelectComponent } from './components/controls/user-select/user-select.component';
import { CurrencyFormatterDirective } from './directives/currency-formatter.directive';
import { DateFormatterDirective } from './directives/date-formatter.directive';
import {
  IfAllowedDirective,
  IfDeleteAllowedDirective,
  IfReadAllowedDirective,
  IfWriteAllowedDirective,
} from './directives/if-allowed.directive';
import { PercentFormatterDirective } from './directives/percent-formatter.directive';
import { PhoneFormatterDirective } from './directives/phone-formatter.directive';
import { SmartBlurDirective } from './directives/smart-blur.directive';
import { SsnFormatterDirective } from './directives/ssn-formatter.directive';
import { VarDirective } from './directives/var.directive';
import { MaterialModule } from './material.module';
import { AgePipe } from './pipes/age.pipe';
import { CptLookupPipe } from './pipes/cpt-lookup.pipe';
import { DrgLookupPipe } from './pipes/drg-lookup.pipe';
import { HcpcsLookupPipe } from './pipes/hcpcs-lookup.pipe';
import { LookupPipe } from './pipes/lookup.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { SsnPipe } from './pipes/ssn.pipe';
import { UsernamePipe } from './pipes/username.pipe';
import { SharedEffects } from './state/shared.effects';
import { reducer } from './state/shared.reducer';

@NgModule({
  imports: [
    CommonModule,
    ErrorsModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    StoreModule.forFeature('shared', reducer),
    EffectsModule.forFeature([SharedEffects]),
    NgxMatSelectSearchModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SmartListGridComponent,
    ExpandableRowsTableComponent,
    ConfirmationDialogComponent,
    InformationDialogComponent,
    ContactDialogComponent,
    ListDialogComponent,
    NameValueDividerComponent,
    NameContentDividerComponent,
    SmartPanelComponent,
    EmailDialogComponent,
    DocumentGenDialogComponent,
    AuditFieldsComponent,
    SurgeryPlusCardMakerComponent,
    BlankReferralFormMakerComponent,
    DocumentDialogComponent,
    PhonePipe,
    UsernamePipe,
    AgePipe,
    SmartFieldComponent,
    AutoCompleteFieldComponent,
    SmartRadioComponent,
    LocationFieldComponent,
    EmailsFieldComponent,
    SideNavComponent,
    SsnPipe,
    LookupPipe,
    CptLookupPipe,
    DrgLookupPipe,
    HcpcsLookupPipe,
    UploadComponent,
    FileDropDirective,
    IfAllowedDirective,
    IfReadAllowedDirective,
    IfWriteAllowedDirective,
    IfDeleteAllowedDirective,
    SmartBlurDirective,
    VarDirective,
    ChipFieldComponent,
    PercentFormatterDirective,
    CurrencyFormatterDirective,
    PhoneFormatterDirective,
    SsnFormatterDirective,
    DateFormatterDirective,
    LeftRightPanelsComponent,
    LookupSelectComponent,
    SmartlistFilterComponent,
    UserSelectComponent,
    AutoMultiSelectComponent,
    LookupMultiSelectComponent,
    SmartlistQueueComponent,
    AssignmentToolbarComponent,
    AssignmentDialogComponent,
    BaseBannerComponent,
    CheckFilterComponent,
    AssociatedComponent,
    MasterComponent,
    DocumentsGridComponent,
    ThirdPartyOrgDialogComponent,
    NamedItemSelectComponent,
    SmartTileGridComponent,
    SmartListGridTableComponent,
    PediatricIndicatorComponent,
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    InformationDialogComponent,
    ListDialogComponent,
    DocumentDialogComponent,
    ContactDialogComponent,
    EmailDialogComponent,
    DocumentGenDialogComponent,
    AssignmentDialogComponent,
  ],
  providers: [],
  declarations: [
    DocumentsGridComponent,
    ConfirmationDialogComponent,
    InformationDialogComponent,
    ContactDialogComponent,
    SurgeryPlusCardMakerComponent,
    BlankReferralFormMakerComponent,
    AuditFieldsComponent,
    DocumentDialogComponent,
    ListDialogComponent,
    SmartListGridComponent,
    ExpandableRowsTableComponent,
    NameValueDividerComponent,
    NameContentDividerComponent,
    SmartPanelComponent,
    EmailDialogComponent,
    DocumentGenDialogComponent,
    PhonePipe,
    UsernamePipe,
    AgePipe,
    SmartFieldComponent,
    AutoCompleteFieldComponent,
    SmartRadioComponent,
    LocationFieldComponent,
    EmailsFieldComponent,
    SideNavComponent,
    SsnPipe,
    LookupPipe,
    CptLookupPipe,
    DrgLookupPipe,
    HcpcsLookupPipe,
    UploadComponent,
    FileDropDirective,
    IfAllowedDirective,
    IfReadAllowedDirective,
    IfWriteAllowedDirective,
    IfDeleteAllowedDirective,
    VarDirective,
    SmartBlurDirective,
    ChipFieldComponent,
    PercentFormatterDirective,
    CurrencyFormatterDirective,
    PhoneFormatterDirective,
    SsnFormatterDirective,
    DateFormatterDirective,
    LeftRightPanelsComponent,
    LookupSelectComponent,
    SmartlistFilterComponent,
    UserSelectComponent,
    AutoMultiSelectComponent,
    LookupMultiSelectComponent,
    SmartlistQueueComponent,
    AssignmentToolbarComponent,
    AssignmentDialogComponent,
    BaseBannerComponent,
    CheckFilterComponent,
    AssociatedComponent,
    MasterComponent,
    ThirdPartyOrgDialogComponent,
    NamedItemSelectComponent,
    SmartTileGridComponent,
    SmartListGridTableComponent,
    NotesDialogComponent,
    PediatricIndicatorComponent,
  ],
})
export class SharedModule {}
