import { Injectable } from '@angular/core';
import { FrameContextService } from 'carehub-root/core/components/call-validation/call-context-swap/frame-context.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WindowEventConsumerService {
  constructor(private frameContext: FrameContextService) {
    this.init();
  }

  public host: Window;
  public get hostOrigin(): string {
    return this.frameContext.hostOrigin;
  }

  events$: Subject<MessageEvent> = new Subject<MessageEvent>();

  sessionToken: string;

  init() {
    this.host = window.opener || window.parent;
    if (this.host === window) {
      this.host === null;
    }
    if (!this.host) {
      // not running inside iframe
      return;
    }
    /** gets the protocol, if any, and the host from the url. test: https://regex101.com/r/zM0yIG/1 */
    const getDomainRegex = /^((?:https?:\/\/)?[\w+@:%._\+~#=]+)/;
    const firstParent = window.location.ancestorOrigins.item(0);

    this.frameContext.hostOrigin =
      firstParent === null || firstParent === 'file://'
        ? null
        : getDomainRegex.test(firstParent)
        ? firstParent.match(getDomainRegex)[1]
        : firstParent;

    window.addEventListener('message', (e) => {
      if (this.shouldProcessEvent(e)) {
        this.events$.next(e);
      }
    });
  }

  public postMessage(message: any, origin: string): void {
    if (this.host) {
      this.host.postMessage(message, origin);
    }
  }
  /** decision logic for if an incoming event should be processed */
  private shouldProcessEvent(event: MessageEvent): boolean {
    return (
      // events from a file origin have host origin 'null'
      event.origin === (this.hostOrigin !== null ? this.hostOrigin : 'null') &&
      this.host === event.source
    );
  }
}
