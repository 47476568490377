<!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <meta charset="utf-8" />
    <link rel="stylesheet" [href]="styleUrl" type="text/css" />
  </head>

  <body class="s-plus-card">
    <div class="card">
      <div class="logo-line">
        <img [src]="logoUrl" />

        <p class="right" style="font-size: medium; padding-right: 4px">
          Provider Use Only
        </p>
      </div>

      <div class="card-body">
        <div class="member-name">
          <div class="gray">
            {{ documentDetails?.member?.firstName }}
          </div>
          <div class="blue">
            {{ documentDetails?.member?.lastName }}
          </div>
        </div>
        <div class="details">
          <div class="client-info left" style="height: 0.3cm">
            <div
              class="client-name bold"
              style="height: 0.4cm; font-size: 7.75pt"
            >
              <!-- {{ documentDetails?.client?.name }} -->
            </div>
            <div class="bold blue" style="font-size: 10.25pt">
              Member Services:
            </div>
            <div
              class="bold orange"
              style="font-size: 10.25pt; padding-bottom: 0pt"
            >
              {{ documentDetails?.team?.phone | phone }}
            </div>
          </div>

          <div
            class="plan-details right"
            style="font-size: 2mm; padding-bottom: 0cm"
          >
            <div class="row">
              <div class="detail-label">MEMBER ID:</div>
              <div class="detail-value">
                {{ documentDetails?.member?.surgeryPlusMemberKey || '-' }}
              </div>
            </div>
            <!-- <div class="row">
              <div class="detail-label">GROUP ID:</div>
              <div class="detail-value">
                {{ documentDetails?.memberPlan?.plan?.groupNumber || '-' }}
              </div>
            </div> -->
            <div class="row">
              <div class="detail-label">DEDUCTIBLE:</div>
              <div class="detail-value">
                {{
                  documentDetails?.memberPlan?.plan?.isCollectDeductible
                    ? 'Collected by S+'
                    : '$0'
                }}
              </div>
            </div>
            <div class="row">
              <div class="detail-label">COINSURANCE:</div>
              <div class="detail-value">
                {{
                  documentDetails?.memberPlan?.plan?.isCollectCoinsurance
                    ? 'Collected by S+'
                    : '$0'
                }}
              </div>
            </div>
            <div class="row">
              <div class="detail-label">COPAY:</div>
              <div class="detail-value">
                {{
                  documentDetails?.memberPlan?.plan?.isCollectCopay
                    ? 'Collected by S+'
                    : '$0'
                }}
              </div>
            </div>
            <div class="row">
              <div class="detail-label">COVERAGE</div>
              <div class="detail-value">
                {{ coverageStart | date : 'MM-dd-yyyy' }}
              </div>
            </div>
            <div class="row">
              <div class="detail-label">PERIOD *:</div>
              <div class="detail-value">
                -
                {{ coverageEnd | date : 'MM-dd-yyyy' }}
              </div>
            </div>
            <!-- <div class="row">
              <div class="detail-label">FINANCIAL REWARD:</div>
              <div class="detail-value">
                {{
                  documentDetails?.memberPlan?.plan?.isFinancialRewardEligible
                    ? 'Eligible'
                    : 'N/A'
                }}
              </div>
            </div> -->
          </div>
          <div
            class="center-info"
            style="
              margin-bottom: 0.1cm;
              padding-bottom: 0.1cm;
              padding-top: 0cm;
            "
          >
            <p class="clear" style="padding-top: 0.2cm">
              * Member Eligibility status subject to change. Please contact
              SurgeryPlus&reg; Member Services for Eligibility status inquiries.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-back">
      <p>
        Employer Direct Healthcare, LLC ("EDH") is the sole administrator of
        claims originating as a result of any SurgeryPlus&reg; services or
        procedures. All claims must be submitted to EDH electronically or by
        mail as provided below.
      </p>

      <div class="center-info bold">
        <div class="contact-info left">
          <div>Employer Direct Healthcare</div>
          <div>Attn: Claims Processing</div>
          <div>2100 Ross Ave. #550</div>
          <div>Dallas, TX 75201</div>
        </div>

        <div class="claims-info right" style="font-size: 2.1mm">
          <div>
            <div class="left">Electronic Payer ID:</div>
            <div class="right">48888</div>
          </div>

          <div>
            <div class="left">Claims Fax Line:</div>
            <div class="right">{{ claimsFaxLine | phone }}</div>
          </div>

          <div>
            <div class="left">Eligibility:</div>
            <div class="right">{{ documentDetails?.team?.phone | phone }}</div>
          </div>

          <div>
            <div class="left">Claims Dept:</div>
            <div class="right">(855) 200-6689</div>
          </div>
        </div>
      </div>

      <p class="clear">
        Providers should contact EDH directly with regards to SurgeryPlus&reg;
        member eligibility or claims-related inquires.
      </p>

      <p>
        In accordance with the terms of applicable contracts and agreements with
        EDH, Provider agrees that it shall not bill or seek to collect any
        amount from member related to services rendered under the
        SurgeryPlus&reg; benefit.
      </p>
    </div>
  </body>
</html>
