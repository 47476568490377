import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'carehub-shared/components/base-component';

@Component({
  selector: 'ch-smartlist-filter',
  templateUrl: './smartlist-filter.component.html',
  styleUrls: ['./smartlist-filter.component.scss'],
})
export class SmartlistFilterComponent extends BaseComponent implements OnInit {
  @Output()
  public search = new EventEmitter<void>();
  constructor() {
    super();
  }

  ngOnInit() {}
  protected onDestroy(): void {
    this.search.complete();
  }

  public onClickSearch() {
    this.search.emit();
  }
}
