import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { GroupsApiService } from 'carehub-api/groups-api.service';
import { TokenSummary } from 'carehub-api/models/security/tokensummary';
import { settings } from 'carehub-assets/settings';
import { environment } from 'carehub-environment/environment';
import * as fromRoot from 'carehub-root/state/app.state';
import { Dictionary } from 'carehub-shared/dictionary';
import { SessionStorageKeys } from 'carehub-shared/session-storage-keys';
import {
  Log,
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from 'oidc-client';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map, reduce, take, takeUntil } from 'rxjs/operators';
import { SecurityUser } from 'security-api/models/securityuser';
import { SecurityUsersApiService } from 'security-api/securityusers-api.service';
import * as fromShared from '../../shared/state';
import { SmartListCriteria } from '../smartlist';
import * as sharedActions from '../state/shared.actions';

export enum BroadcastEvents {
  'Logout' = 'Logout',
  'Login' = 'Login',
}

type Permissions = Dictionary<string[]>;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  //? List of paths that will not redirect when receiving a login
  //? broadcasted message
  private readonly noRedirectLinks: string[] = ['/call-validation'];

  private destroyed$: Subject<void> = new Subject<void>();
  private userManager: UserManager;
  private user: User;
  private broadcastChannel: BroadcastChannel;

  securityInfo: SecurityUser;
  originalUserGroups: string;

  constructor(
    private sharedStore: Store<fromRoot.State>,
    private securityUsersService: SecurityUsersApiService,
    private groupsService: GroupsApiService
  ) {}

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  initialize() {
    Log.logger = console;

    moveUserInfoToSessionStorage();

    const dimensions = ', width=500,height=500,top=0,left=0';

    const config: UserManagerSettings = {
      ...environment.oidc,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      popupWindowFeatures:
        'title="", scrollbars=no, titlebar=no, menubar=no, location=no, toolbar=no' +
        dimensions,
    };

    this.broadcastChannel = this.configureBroadcastChannel();
    this.userManager = new UserManager(config);
    // on page reload for already logged in users
    this.userManager
      .getUser()
      .then((user) => {
        if (user && !user.expired) {
          this.user = user;

          // setup nav callback on user update processed
          this.sharedStore
            .pipe(
              select(fromShared.getCurrentUser),
              take(1),
              takeUntil(this.destroyed$)
            )
            .subscribe((_) => {
              this.internalCollectUserPermissions(user);
            });
          this.sharedStore.dispatch(new sharedActions.LoginSuccess(this.user));

          this.internalCollectUserPermissions(user);
        }
      })
      .catch((reason) => {
        const msg = `AuthService::initialize getUser->catch: ${JSON.stringify(
          reason
        )}`;
        this.sharedStore.dispatch(new sharedActions.LoginFail(msg));
      });
  }

  // section: broadcast channel
  // the broadcast channel enables cross tab communication within the same domain
  private configureBroadcastChannel(): BroadcastChannel {
    const newBroadcastChannel = new BroadcastChannel('AuthService');
    newBroadcastChannel.onmessage = (event) => {
      switch (event.data.message) {
        case BroadcastEvents.Logout: {
          this.doLogoutCleanup(() => {});
          window.location.reload(); // log out in other tab
          break;
        }
        case BroadcastEvents.Login: {
          this.doLoginSetup(event.data.user, () => {});
          const newLocation = this.getPostAuthDestination();
          if (this.noRedirectLinks.includes(window.location.pathname)) {
            break;
          }
          window.location.href = newLocation;
          break;
        }
      }
    };
    return newBroadcastChannel;
  }

  private dispatch(event: BroadcastEvents) {
    const eventDto = { message: event, user: this.user };
    this.broadcastChannel.postMessage(eventDto);
  }

  // section: collect permissions
  collectUserPermissions() {
    this.internalCollectUserPermissions(this.user);
  }

  public canImpersonate(permissions: Permissions = null): boolean {
    if (!permissions && this.securityInfo) {
      permissions = this.securityInfo.permissions;
    }
    return permissions && permissions.hasOwnProperty('Impersonate');
  }
  public impersonateWithGroups(
    groups: { name: string; id: string }[],
    callback: () => void
  ) {
    this.internalCollectUserPermissions(this.user, callback, groups);
  }

  private internalCollectUserPermissions(
    user: User,
    postAuthCallback: () => void = null,
    impersonateGroups: { name: string; id: string }[] = null
  ) {
    const canImpersonate = this.canImpersonate();
    if (impersonateGroups && (!this.securityInfo || !canImpersonate)) {
      console.warn(
        'unable to apply impersonation: user does not have the impersonate permission'
      );
      impersonateGroups = null;
    }
    const accessToken = this.getAccessToken();
    this.securityUsersService
      .getSecurityUserByUserId(accessToken, user.profile.sub)
      .pipe(take(1), takeUntil(this.destroyed$))
      .subscribe(
        (securityInfo: SecurityUser) => {
          if (impersonateGroups && impersonateGroups.length > 0) {
            this.getTokensForGroups(
              impersonateGroups.map((group) => group.id)
            ).subscribe((newTokens) => {
              securityInfo.permissions = newTokens;
              if (!this.canImpersonate(securityInfo.permissions)) {
                securityInfo.permissions['Impersonate'] = ['Read'];
              }
              this.originalUserGroups = securityInfo.groups;
              securityInfo.groups = impersonateGroups
                .map((group) => group.name)
                .join(', ');
              this.setSecurityUser(securityInfo, postAuthCallback);
            });
          } else {
            this.originalUserGroups = undefined;
            this.setSecurityUser(securityInfo, postAuthCallback);
          }
        },
        (error: any) => {
          this.sharedStore.dispatch(new sharedActions.SetCurrentError(error));
        }
      );
  }

  private setSecurityUser(
    securityUser: SecurityUser,
    postAuthCallback: () => void = null
  ) {
    this.sharedStore.dispatch(new sharedActions.SetSecurityInfo(securityUser));
    this.securityInfo = securityUser;
    if (postAuthCallback) {
      postAuthCallback();
    }
  }

  // section: login
  login(): Promise<User> {
    // must pass in router since this is a shared service
    return this.popupLogin();
  }

  private popupLogin(): Promise<User> {
    return this.userManager.signinPopup().then((user) => {
      return this.doLoginSetup(user, () => this.handleSameWindowLogin());
    });
  }

  private handleSameWindowLogin() {
    window.location.href = this.getPostAuthDestination();

    this.userManager.startSilentRenew();
    this.dispatch(BroadcastEvents.Login);
  }

  /**
   * handles log ins from other tabs or the signing modal
   * @param user the user logged in
   * @param callback allows configuration of the login callback
   */
  private doLoginSetup(user: User, callback: () => void): User {
    this.user = user;
    if (!callback) {
      callback = () => {};
    }
    // setup nav callback on user update processed
    this.sharedStore
      .pipe(
        select(fromShared.getCurrentUser),
        take(1),
        takeUntil(this.destroyed$)
      )
      .subscribe((_) => {
        this.internalCollectUserPermissions(user, callback);
      });
    return user;
  }

  /**
   * called in the post login redirect page to signal to the original requestor that the login is complete
   */
  processLoginResult() {
    // workaround for https://github.com/IdentityModel/oidc-client-js/issues/937
    return this.userManager.signinPopupCallback(
      window.location.href.split('?')[1]
    );
  }

  // section: logout

  /**
   * initiates a logout
   */
  logout(): Promise<any> {
    // this claims to take args, what does it do?
    return this.userManager
      .signoutPopup()
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.doLogoutCleanup(() => this.dispatch(BroadcastEvents.Logout));
        window.location.href = window.origin;
      });
  }

  /**
   * handles log out cleanup for logout originating from this or other tabs
   * @param callback called after the logout is complete
   */
  private doLogoutCleanup(callback?: () => void): void {
    window.sessionStorage.clear();
    window.localStorage.clear();
    this.userManager.stopSilentRenew();
    this.sharedStore.dispatch(new sharedActions.LogoutSuccess());

    if (callback) {
      callback();
    }
  }

  /**
   * called from the logout redirect (which doesn't seem to work, like at all, but w/e)
   */
  processLogoutResult() {
    return this.userManager.signoutPopupCallback(location.href, false);
  }

  // section: misc utility
  isLoggedIn(): boolean {
    return this.user && this.user.access_token && !this.user.expired;
  }

  getAccessToken(): string {
    // ? should this just throw if user is empty?
    return this.user ? this.user.access_token : '';
  }

  /**
   * after login redirects the user to the specified page. called when the user is not logged in and navigates to a page, to allow pass through auth and maintaining deep linking
   * @param url after login, the user should be directed to this page
   */
  setPostAuthUrl(url: string): void {
    if (url) {
      window.sessionStorage.setItem(SessionStorageKeys.LoginPostAuthUrl, url);
    }
  }

  /**
   * after a login request, attempts to return the user to the originally requested page
   */
  getPostAuthDestination(): string {
    let postAuthUrl = window.sessionStorage.getItem(
      SessionStorageKeys.LoginPostAuthUrl
    );
    if (!postAuthUrl) {
      // try to parse default url by role
      const groups = this.securityInfo
        ? this.securityInfo.groups.split(', ')
        : [];
      const roleUrls = settings.defaultUrlByRole.filter(
        (roleSet) =>
          roleSet.url &&
          roleSet.roles.some((role) =>
            groups.some(
              (group) =>
                // case insensitive string comparison
                group.localeCompare(role, undefined, {
                  sensitivity: 'accent',
                }) === 0
            )
          )
      );

      postAuthUrl =
        roleUrls.length >= 1 ? roleUrls[0].url : settings.fallbackUrl;
    }

    if (postAuthUrl) {
      window.sessionStorage.removeItem(SessionStorageKeys.LoginPostAuthUrl);

      return postAuthUrl;
    }
  }

  /** Section: Group Permissions **/

  /**
   * for a given set of group ids, loads the associated tokens (permissions) for those groups, and aggregate the permissions so that the highest level of each permission is used, and no permissions are duplicated
   * @param groupIds the groups to get the tokens for
   */
  private getTokensForGroups(groupIds: string[]): Observable<Permissions> {
    return forkJoin(groupIds.map((id) => this.loadPermissions(id))).pipe(
      map((arraysOfTokens) => arraysOfTokens.reduce(this.tokenReducer, [])),
      map((allTokens) => this.groupByPermission(allTokens)),
      takeUntil(this.destroyed$),
      take(1)
    );
  }

  /**
   * a reducer function to flatten a 2d array of tokens to a 1d array
   */
  private tokenReducer(accumulated: TokenSummary[], current: TokenSummary[]) {
    accumulated.push(...current);
    return accumulated;
  }
  /**
   * loads the permissions for the specified group, returning an observable that will emit that groups tokens a single time, then complete
   * @param groupId the group id to load the permissions for
   */
  private loadPermissions(groupId: string): Observable<TokenSummary[]> {
    const results = new Subject<TokenSummary[]>();
    const criteria = new SmartListCriteria();
    criteria.pageSize = 0;
    criteria.pageIndex = 0;
    criteria.sortField = 'permissionName';

    this.loadPermissionsRecursion(groupId, criteria, results);
    return results.pipe(
      takeUntil(this.destroyed$),
      reduce(this.tokenReducer, [])
    );
  }
  /**
   * for the specified group and criteria, loads the permission page, and, if more pages are found, invokes itself recursively.
   * results are published on the resultsSubject
   * @param groupId the group to load the permissions for
   * @param criteria criteria governing page size and number for the permissions to load
   * @param resultsSubject publishes the results for each page of permissions
   */
  private loadPermissionsRecursion(
    groupId: string,
    criteria: SmartListCriteria,
    resultsSubject: Subject<TokenSummary[]>
  ): void {
    this.groupsService
      .getPermissionsByGroupId(groupId, criteria)
      .pipe(take(1), takeUntil(this.destroyed$))
      .subscribe((tokenResults) => {
        resultsSubject.next(tokenResults.results);
        if (tokenResults.currentPage < tokenResults.pageCount) {
          criteria.pageIndex = tokenResults.currentPage; // (1 vs 0 based to increment)
          this.loadPermissionsRecursion(groupId, criteria, resultsSubject);
        } else {
          resultsSubject.complete();
        }
      });
  }
  private groupByPermission(tokens: TokenSummary[]): Permissions {
    return tokens.reduce((permissions: Permissions, current: TokenSummary) => {
      if (!permissions.hasOwnProperty(current.permissionName)) {
        permissions[current.permissionName] = [];
      }
      const scopes = permissions[current.permissionName];
      current.scopes.forEach((scope) => {
        if (!scopes.includes(scope)) {
          scopes.push(scope);
        }
      });
      return permissions;
    }, {});
  }

  /** End Section: Group Permissions **/
}

export function moveUserInfoToSessionStorage() {
  const storageCount = window.localStorage.length;
  for (let index = 0; index < storageCount; index++) {
    const key = window.localStorage.key(index);
    if (key && key.startsWith(SessionStorageKeys.OidcUserPrefix)) {
      const userInfo = window.localStorage.getItem(key);
      window.sessionStorage.setItem(key, userInfo);
      window.localStorage.removeItem(key);
    }
  }
}
