import { Injectable } from '@angular/core';
import { InputFormatter } from './input-formatter.interface';

export interface ExpandedPhone {
  countryCode?: string;
  localNumber: string;
  extension?: string;
}

export interface PhoneFormatterOptions {
  allowIncompleteNumbers: boolean;
}
// Adapted from 'https://stackoverflow.com/questions/36895431/2932782'

@Injectable({
  providedIn: 'root',
})
export class PhoneFormatterService implements InputFormatter {
  private deformattedRegex =
    /^(?:\+?([0-9]|[0-9]{3}))??([0-9]{9,10})(?:x([0-9]{1,12}))?$/;
  constructor() {}

  // #region Options Implementation
  private defaultOptions: PhoneFormatterOptions = {
    allowIncompleteNumbers: false,
  };
  private useOptions(
    opts: Partial<PhoneFormatterOptions>
  ): PhoneFormatterOptions {
    return Object.assign({}, this.defaultOptions, opts);
  }
  // #endregion Options Implementation

  // #region InputFormatter implementation
  parse(
    displayValue: string,
    options?: Partial<PhoneFormatterOptions>
  ): string {
    if (displayValue == null) {
      throw Error('Could not parse a null value');
    }
    const opts = this.useOptions(options);
    const deformatted = displayValue.replace(/[\(\)\[\]{}<>\.\- +]/g, '');
    if (/[^\d+]/.test(deformatted)) {
      throw Error(
        this.errorMessage('the phone number contains invalid characters')
      );
    }
    const parts = deformatted.match(this.deformattedRegex);
    if (!opts.allowIncompleteNumbers && (parts == null || parts.length === 0)) {
      this.throwInvalidParseValueError(deformatted, displayValue);
    }
    return deformatted;
  }
  transform(input: string, options?: Partial<PhoneFormatterOptions>): string {
    const opts = this.useOptions(options);
    if (!input || input == '') {
      return '';
    }
    const parts = this.parse(input, options).match(this.deformattedRegex);
    if (!parts || parts.length === 0) {
      console.warn(`Could not parse the value ${input}`);
      return input;
    }
    let expanded = {
      countryCode: parts[1],
      localNumber: parts[2],
      extension: parts[3],
    };

    if (!expanded.localNumber || expanded.localNumber === '') {
      return '';
    }

    const countryCode = expanded.countryCode
      ? expanded.countryCode[0] === '+'
        ? `${expanded.countryCode} `
        : `+${expanded.countryCode} `
      : '';

    const localNumberPartsRegex = /([0-9]{2,3})([0-9]{3})([0-9]{4})/;
    let numberParts: string[] = expanded.localNumber.match(
      localNumberPartsRegex
    );
    if (numberParts == null || numberParts.length == 0) {
      if (!opts.allowIncompleteNumbers) {
        throw new Error(
          this.errorMessage('The local number could not be parsed')
        );
      } else {
        const hasFullAreaCode = expanded.localNumber.length >= 3;
        const hasFullSection = expanded.localNumber.length >= 6;
        numberParts = [
          hasFullAreaCode
            ? expanded.localNumber.substr(0, 3)
            : expanded.localNumber,
          hasFullAreaCode
            ? hasFullSection
              ? expanded.localNumber.substr(3, 3)
              : expanded.localNumber.substr(3)
            : '',
          hasFullAreaCode && hasFullSection
            ? expanded.localNumber.substr(6)
            : '',
        ];
      }
    }
    const regionalNumber = numberParts
      ? `(${numberParts[1]}) ${numberParts[2]}-${numberParts[3]}`
      : expanded.localNumber;

    const ext = expanded.extension ? ' x' + expanded.extension : '';

    return `${countryCode}${regionalNumber}${ext}`;
  }
  reformat(displayValue: string, options?: any): string {
    return this.transform(this.parse(displayValue, options), options);
  }
  // #endregion InputFormatter implementation

  // #region validation feedback
  private throwInvalidParseValueError(
    deformatted: string,
    displayValue: string
  ) {
    if (deformatted.includes('x')) {
      const extParts = deformatted.split('x');
      if (extParts.length > 2) {
        throw Error(
          this.errorMessage(
            "A number can only contain one extension, prefixed by 'x'",
            displayValue
          )
        );
      }
      deformatted = extParts[0];
      if (extParts[1].length > 12 || extParts[1].length < 1) {
        throw Error(
          this.errorMessage(
            'An extension must be between 1 and 12 digits, inclusive',
            displayValue
          )
        );
      }
    }
    if (deformatted.length > 10 && deformatted[0] != '+') {
      deformatted = '+' + deformatted;
      if (deformatted.length < 12) {
        throw Error(
          this.errorMessage(
            'Expecting at least 10 digits in the number, and at least one in the country code',
            displayValue
          )
        );
      }
    } else {
      if (deformatted.length != 10) {
        throw Error(
          this.errorMessage(
            'Expecting exactly 10 digits for the US based phone number',
            displayValue
          )
        );
      }
    }
  }
  private errorMessage(message: string, value?: string) {
    value = value ? ` '${value}'` : '';
    return `Could not parse${value}: ${message}`;
  }
  // #endregion validation feedback
}
