<span [formGroup]="formGroup">
  <mat-form-field floatLabel="always" appearance="outline" *ngIf="propertyName">
    <mat-label
      >{{ labelName
      }}<span class="ch-required-star" *ngIf="isRequired">*</span></mat-label
    >
    <input
      type="text"
      matInput
      [formControl]="getFormControl()"
      (blur)="onBlur($event)"
      #filterControlInput
      [matAutocomplete]="filterControl"
    />
    <mat-autocomplete
      #filterControl="matAutocomplete"
      [displayWith]="selectedItemDisplay.bind(this)"
      (optionSelected)="onSelected($event)"
    >
      <mat-option
        class="entity-option"
        *ngFor="let entity of (dataSource$ | async)?.results"
        [value]="entity"
      >
        <ng-container *ngIf="displayPropertyName">
          {{ entity[displayPropertyName] }}
        </ng-container>
        <ng-container *ngIf="displayFn">
          {{ displayFn(entity) }}
        </ng-container>
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="displayMessage[propertyName]">
      {{ displayMessage[propertyName] }}
    </mat-error>
  </mat-form-field>
</span>
