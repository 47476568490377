import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Facility } from 'carehub-api/models/facility/facility';
import { LookupService } from 'carehub-shared/services/lookup.service';
import { LookupTypes } from 'carehub-shared/services/models/lookup-types.enum';

@Component({
  selector: 'ch-facility-banner',
  templateUrl: './facility-banner.component.html',
  styleUrls: ['./facility-banner.component.scss'],
})
export class FacilityBannerComponent {
  @Input() facility: Facility;
  constructor(private lookupService: LookupService, private router: Router) {}

  get facilityStatus() {
    return this.lookupService.getDescriptionByTypeAndId(
      LookupTypes.FacilityStatuses,
      this.facility?.statusId
    );
  }

  goBack(): any {
    if (location.href.includes('provider-network-mgmt')) {
      this.router.navigateByUrl('provider-network-mgmt/facilities');
    } else {
      this.router.navigateByUrl('member-services-mgmt/facilities');
    }
  }
}
