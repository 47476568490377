<input
  enSmartBlur
  #filterInput
  [class]="'filter' + inputClass"
  [attr.aria-label]="placeholder"
  [attr.placeholder]="placeholder"
  [value]="displayLookupItem(selected)"
  (blur)="onInputBlur($event)"
  [matAutocomplete]="filterType"
  [disabled]="!enabled"
/>
<mat-autocomplete
  #filterType="matAutocomplete"
  [displayWith]="displayLookupItem"
  (optionSelected)="onSelection($event.option.value)"
  [panelWidth]="350"
>
  <mat-option *ngFor="let item of filteredItems$ | async" [value]="item">
    {{ displayLookupItem(item) }}
  </mat-option>
</mat-autocomplete>
