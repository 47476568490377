<div class="grid-container about">
  <mat-card class="environment">
    <mat-card-title>Environment</mat-card-title>
    <div class="json-viewer">
      <pre>{{ environmentSettings | json }}</pre>
    </div>
  </mat-card>

  <mat-card class="settings" *ngIf="settings$ | async as settings">
    <mat-card-title>Settings</mat-card-title>
    <ul>
      <ng-container *ngFor="let entry of settings">
        <li *ngIf="entry.value">
          <span>{{ entry.key.replace('ExternalSettings:', '') }}:</span
          >&nbsp;&nbsp;<span>{{ entry.value }}</span>
        </li>
      </ng-container>
    </ul>
  </mat-card>

  <mat-card class="assemblies" *ngIf="about$ | async as about">
    <mat-card-title>Assemblies</mat-card-title>
    <ul>
      <ng-container *ngFor="let entry of about.details">
        <li>
          <span>{{ entry.name }}</span
          >&nbsp;&nbsp;<span>(v{{ entry.assemblyVersion }})</span>
        </li>
      </ng-container>
    </ul>
  </mat-card>

  <mat-card class="user">
    <mat-card-title>User</mat-card-title>
    <div class="json-viewer">
      <pre>{{ user$ | async | json }}</pre>
    </div>
  </mat-card>
</div>
