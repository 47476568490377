<h2 mat-dialog-title>Impersonate Group Membership</h2>
<mat-dialog-content>
  <div class="content">
    <mat-progress-bar
      class="progress"
      [mode]="loading ? 'indeterminate' : 'determinate'"
      [value]="loading ? null : 100"
    ></mat-progress-bar>
    <mat-form-field class="example-full-width">
      <input matInput placeholder="Filter Groups" [(ngModel)]="nameFilter" />
    </mat-form-field>
    <div class="option" *ngFor="let group of filteredGroups; index as i">
      <section class="section">
        <mat-checkbox class="checkbox" [(ngModel)]="group.selected"
          >{{ group.name
          }}<span *ngIf="group.originallySelected">
            &nbsp;(Original Group)</span
          ></mat-checkbox
        >
      </section>
    </div>
  </div></mat-dialog-content
>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close (click)="onCancel()">
    Cancel
  </button>
  <button mat-raised-button mat-dialog-close (click)="onReset()">Reset</button>
  <button
    mat-raised-button
    mat-dialog-close
    color="primary"
    (click)="onApply()"
  >
    Apply
  </button>
</mat-dialog-actions>
