import { Injectable } from '@angular/core';
import { InputFormatter } from './input-formatter.interface';
export class DecimalFormatterOptions {
  decimalSeparator: string;
  thousandsSeparator: string;
  roundToDecimal: number;
  prefix: string;
  suffix: string;
  multiplier: number;
  nullAsZero: boolean;
  parseAsNormalized: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DecimalFormatterService implements InputFormatter {
  private defaults: DecimalFormatterOptions = {
    decimalSeparator: '.',
    thousandsSeparator: ',',
    roundToDecimal: 0,
    prefix: '',
    suffix: '',
    multiplier: 1,
    nullAsZero: false,
    parseAsNormalized: false,
  };
  constructor() {}

  parse(
    displayValue: string,
    options?: Partial<DecimalFormatterOptions>
  ): number {
    const opts = this.getOptions(options);
    if (displayValue == null || displayValue === '') {
      return opts.nullAsZero ? 0 : null;
    }
    if (!isNaN(+displayValue)) {
      if (opts.parseAsNormalized) {
        displayValue = this.transform(displayValue as any, options);
      } else {
        displayValue = displayValue.toString();
      }
    }
    const cleanValue = (displayValue || '')
      .replace(opts.prefix, '')
      .replace(opts.suffix, '')
      .replace(new RegExp(opts.thousandsSeparator, 'g'), '');
    let floatValue = parseFloat(cleanValue);
    if (isNaN(floatValue)) {
      floatValue = 0;
    }
    floatValue /= opts.multiplier;

    if ((floatValue * opts.multiplier) % 1 !== 0) {
      // has decimals
      floatValue *= Math.pow(10 * opts.multiplier, opts.roundToDecimal);
      floatValue = Math.round(floatValue);
      floatValue *= Math.pow(10 * opts.multiplier, -opts.roundToDecimal);
    }
    const targetDecimalPlaces =
      opts.roundToDecimal + String(opts.multiplier || 0).length - 1;
    return parseFloat(floatValue.toFixed(targetDecimalPlaces));
  }
  transform(
    underlyingValue: number,
    options?: Partial<DecimalFormatterOptions>
  ): string {
    const opts = this.getOptions(options);
    // null handling, multiplier
    if (
      (underlyingValue == null || String(underlyingValue) === '') &&
      !opts.nullAsZero
    ) {
      return '';
    }

    let floatValue = (underlyingValue || 0) * opts.multiplier;
    // rounding
    floatValue *= Math.pow(10, opts.roundToDecimal);
    if (floatValue % 1 !== 0) {
      floatValue = Math.round(floatValue);
    }
    floatValue *= Math.pow(10, -opts.roundToDecimal);
    // thousands place
    let [integer, fraction = ''] = floatValue
      .toFixed(opts.roundToDecimal)
      .split(opts.decimalSeparator);
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, opts.thousandsSeparator);
    if (fraction !== '') {
      fraction = opts.decimalSeparator + fraction;
    }
    if (isNaN(parseFloat(integer))) {
      integer = '0';
    }
    const transformResult = opts.prefix + integer + fraction + opts.suffix;
    return transformResult;
  }
  reformat(
    displayValue: string,
    options?: Partial<DecimalFormatterOptions>
  ): string {
    return this.transform(this.parse(displayValue, options), options);
  }

  private getOptions(
    customOptions: Partial<DecimalFormatterOptions>
  ): DecimalFormatterOptions {
    return Object.assign({}, this.defaults, customOptions);
  }
}
