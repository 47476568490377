import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { ActivitiesApiService } from 'carehub-api/activities-api.service';
import {
  Activity,
  initializeNewActivity,
} from 'carehub-api/models/member/activity';
import { Member } from 'carehub-api/models/member/member';
import { MemberCase } from 'carehub-api/models/membercase/membercase';
import { BaseComponent } from 'carehub-root/shared/components/base-component';
import { FormBuilderService } from 'carehub-root/shared/form-definition-builder/form-builder-service';
import { FormConfig } from 'carehub-root/shared/form-definition-builder/form-config';
import { Guid } from 'carehub-root/shared/guid';
import { SharedState } from 'carehub-root/shared/state/shared.reducer';
import * as fromShared from 'carehub-shared/state/index';
import * as sharedActions from 'carehub-shared/state/shared.actions';
import { User as CurrentUser } from 'carehub-shared/state/shared.reducer';
import { Required, StringLength } from 'carehub-root/shared/validators';

// We want to add new Required constraints on some fields so we create a new class with the decorations.
class ActivityPrototype {
  @Required()
  activityId: string;
  @Required()
  memberId: string;
  memberCaseId: string;
  activityDirectionId: number;
  @Required()
  activityReasonId: number;
  @Required()
  activityTypeId: number;
  @Required()
  activityWithId: number;
  @Required()
  activityDate: Date | string;
  @Required()
  description: string;
  @StringLength(50)
  reference: string;
  @Required()
  isFollowUp: boolean;
  followUpDate: Date | string;
  inactiveDate: Date | string;
  createdUserId: string;
  updatedUserId: string;
  createdDate: Date | string;
  updatedDate: Date | string;
  activitySubTypeId: number;
  activityRelatedToId: number;
}

@Component({
  templateUrl: './activity-dialog.component.html',
  styleUrls: ['./activity-dialog.component.scss'],
})
export class ActivityDialogComponent extends BaseComponent implements OnInit {
  // !(UX) hard-coded pixel dimensions
  static DefaultWidth = '800px';

  formConfig: FormConfig;
  activity: Activity;
  currentUser: any;

  // remove some system-generated statuses from the user selection
  excludedActivityTypes = ['System'];
  excludedReasonValues = [
    'Dispute',
    'Completed',
    'Document Upload',
    'Status Change',
  ];

  constructor(
    private dialogRef: MatDialogRef<ActivityDialogComponent>,
    private activityService: ActivitiesApiService,
    private sharedStore: Store<SharedState>,

    @Inject(MAT_DIALOG_DATA)
    public details: {
      activityId?: string;
      member?: Member;
      memberCase?: MemberCase;
    },

    formBuilderService: FormBuilderService
  ) {
    super();
    this.formConfig = formBuilderService.build(ActivityPrototype.prototype, [
      'activityTypeId',
      'activityRelatedToId',
      'activityReasonId',
      'activityWithId',
      'activityDirectionId',
      'description',
    ]);
  }

  ngOnInit() {
    this.sharedStore
      .pipe(takeUntil(this.unsubscribe$), select(fromShared.getCurrentUser))
      .subscribe((currentUser: CurrentUser) => {
        this.currentUser = currentUser;
        if (this.details.activityId) {
          // clear the excluded values so the lookup works properly when reading
          // the record rather than editing it.
          this.excludedActivityTypes.length = 0;
          this.excludedReasonValues.length = 0;

          this.activityService
            .getActivityById(this.details.activityId)
            .subscribe((activity: Activity) => {
              this.activity = activity;

              // existing activities cannot be edited
              this.formConfig.formGroup.disable();
              this.applyFormDetails();
            });
        } else {
          this.activity = initializeNewActivity();
          this.activity.activityDate = new Date(Date.now());
          this.activity.memberId = this.details.member.memberId;
          this.activity.memberCaseId = this.details.memberCase.memberCaseId;
        }
      });
  }

  private applyFormDetails() {
    if (this.activity && this.formConfig && this.formConfig.formGroup) {
      this.formConfig.formGroup.patchValue({
        activityTypeId: this.activity.activityTypeId,
        activityRelatedToId: this.activity.activityRelatedToId,
        activityReasonId: this.activity.activityReasonId,
        activityWithId: this.activity.activityWithId,
        activityDirectionId: this.activity.activityDirectionId,
        description: this.activity.description,
      });
    }
  }

  onSave(event: any): void {
    if (this.formConfig && this.formConfig.formGroup.valid) {
      this.activity = { ...this.activity, ...this.formConfig.formGroup.value };
      if (this.activity.activityId && this.activity.activityId !== Guid.empty) {
        this.activityService
          .updateActivity(this.activity, this.activity.activityId)
          .subscribe(
            (result) => {
              this.sharedStore.dispatch(
                new sharedActions.SetCurrentMessage('Activity Updated!')
              );
            },
            (error) => {
              this.sharedStore.dispatch(
                new sharedActions.SetCurrentError(error)
              );
            }
          );
      } else {
        this.activityService.addActivity(this.activity).subscribe(
          (result) => {
            this.sharedStore.dispatch(
              new sharedActions.SetCurrentMessage('Activity Added!')
            );
          },
          (error) => {
            this.sharedStore.dispatch(new sharedActions.SetCurrentError(error));
          }
        );
      }

      this.formConfig.formGroup.markAsPristine();
      this.dialogRef.close(true);
    }
  }

  protected onDestroy(): void {}
}
