import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { MembersApiService } from 'carehub-api/members-api.service';
import { Member } from 'carehub-api/models/member/member';
import { MemberPlan } from 'carehub-api/models/member/memberplan';
import { MemberSsn } from 'carehub-api/models/member/memberssn';
import { FeatureFlagsApiService } from 'carehub-rest-services/feature-flags/feature-flags-api.service';
import { BaseComponent } from 'carehub-shared/components/base-component';
import {
  PermissionScopes,
  checkPermission,
} from 'carehub-shared/directives/if-allowed.directive';
import { PhonePipe } from 'carehub-shared/pipes/phone.pipe';
import { SsnPipe } from 'carehub-shared/pipes/ssn.pipe';
import { LookupService } from 'carehub-shared/services//lookup.service';
import { LookupTypes } from 'carehub-shared/services/models/lookup-types.enum';
import { getCurrentUser } from 'carehub-shared/state';
import {
  LookupItem,
  SharedState,
  User,
} from 'carehub-shared/state/shared.reducer';
import { Observable } from 'rxjs';

const MEMBER_SSN_REVEAL_TIMEOUT = 15 * 1000;
@Component({
  selector: 'ch-member-preview',
  templateUrl: './member-preview.component.html',
  styleUrls: ['./member-preview.component.scss'],
  providers: [PhonePipe, SsnPipe, DatePipe],
})
export class MemberPreviewComponent extends BaseComponent {
  _memberToPreview: Member = null;
  memberToPreviewSsn: string = null;
  unmaskedSsn: MemberSsn = null;

  @Input() set memberToPreview(member: Member) {
    this._memberToPreview = member;
    this.memberToPreviewSsn = this.ssnPipe.transform(member?.ssn);
    this.ssnMasked = true;
  }
  get memberToPreview() {
    return this._memberToPreview;
  }
  @Input() memberPlanToPreview: MemberPlan;
  @Output() memberConfirmed = new EventEmitter<Member>();

  @ViewChild('statusFilter', { static: true }) statusFilter: ElementRef;
  filteredStatuses$: Observable<LookupItem[]>;
  statuses: LookupItem[];
  private user: User;
  private ssnMasked = true;

  public get showMemberBenefits(): boolean {
    return this._showMemberBenefits;
  }
  private _showMemberBenefits: boolean = false;

  constructor(
    private lookupService: LookupService,
    private sharedStore: Store<SharedState>,
    private membersApiService: MembersApiService,
    public phonePipe: PhonePipe,
    public ssnPipe: SsnPipe,
    public datePipe: DatePipe,
    private flagsApi: FeatureFlagsApiService
  ) {
    super();
    this.flagsApi
      .get('show-member-benefits')
      .subscribe((res) => (this._showMemberBenefits = res.value));
    this.sharedStore.pipe(select(getCurrentUser)).subscribe((user) => {
      this.user = user;
    });

    this.lookupService
      .getAllByType(LookupTypes.MemberStatuses)
      .subscribe((data) => {
        this.statuses = data;
      });

    this.ssnMasked = true;
  }
  get canRevealSsn(): Boolean {
    return (
      this.ssnMasked &&
      this.user &&
      checkPermission('ShowMemberSsn', PermissionScopes.READ, this.user)
    );
  }
  showSsn() {
    if (this.memberToPreview) {
      this.membersApiService
        .getMemberSsn(this.memberToPreview.memberId)
        .subscribe((res) => {
          this.unmaskedSsn = res;
          this.memberToPreviewSsn = this.unmaskedSsn.ssn;
        });
      this.ssnMasked = false;

      setTimeout(() => {
        this.memberToPreviewSsn = this.ssnPipe.transform(
          this.memberToPreview.ssn
        );
        this.ssnMasked = true;
      }, MEMBER_SSN_REVEAL_TIMEOUT);
    }
  }
  onDestroy() {}
  statusDisplay = (item: LookupItem): string => item.description;
  selectedMemberForValidation(event: Member) {
    this.memberConfirmed.emit(event);
  }
}
