import { Component } from '@angular/core';
import { AuthService } from 'carehub-root/shared/services/auth.service';

@Component({
  template: 'Logging in...',
  styles: [
    `
      :host {
        font-size: larger;
        vertical-align: middle;
        text-align: center;
      }
    `,
  ],
})
export class LoginRedirectComponent {
  constructor(private authService: AuthService) {
    this.authService.processLoginResult();
  }
}
