<ng-container>
  <div class="grid-container">
    <ng-container hidden="!showMemberSearch">
      <div id="main-grid-container">
        <mat-icon>search</mat-icon>
        <!-- this is the primary input element if the user can see members -->
        <input
          autofocus
          type="search"
          class="client-search"
          #clientFilter
          aria-label="Select Client"
          placeholder="Client"
          [matAutocomplete]="clientAutoComplete"
        />
        <input
          type="search"
          enSmartBlur
          class="member-search"
          #memberFilter
          aria-label="Member Search"
          [value]="memberSearchCriteria.search"
          placeholder="Member Name"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #clientAutoComplete="matAutocomplete"
          [displayWith]="clientDisplay"
          (optionSelected)="onClientSelected($event)"
        >
          <mat-option
            class="client-option"
            *ngFor="let client of filteredClients$ | async"
            [value]="client"
          >
            {{ client.name }}
          </mat-option>
        </mat-autocomplete>
        <span></span>
      </div>
    </ng-container>

    <div id="case-grid-container">
      <mat-icon>search</mat-icon>
      <input
        type="search"
        class="client-search"
        #caseFilter
        aria-label="Select Case"
        placeholder="Case Number"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        [displayWith]="caseDisplay"
        (optionSelected)="onCaseSelected($event)"
      >
        <mat-option
          class="client-option"
          *ngFor="let case of filteredCases?.results"
          [value]="case"
        >
          {{ case.caseNumber }}
        </mat-option>
      </mat-autocomplete>
    </div>
  </div>
  <div
    *ngIf="
      (memberSearchCriteria.search || memberSearchCriteria.clientId) &&
      allowSearchResultsOverlay
    "
    id="search-results"
    class="mat-elevation-z4"
  >
    <div class="header">
      <div class="title">SEARCH RESULTS</div>
      <div class="line">&nbsp;</div>
      <div class="view-all-button">
        <span (click)="onViewAll()" class="ch-clickable">VIEW ALL</span>
      </div>
    </div>
    <div *ngIf="foundMembers$ | async as members">
      <div class="findings">
        <ng-container *ngIf="searching; else displayResults">
          <div class="nothing-found">Searching...</div>
        </ng-container>
        <ng-template #displayResults>
          <ng-container *ngIf="members.length === 0">
            <div class="nothing-found">No Results Found</div>
          </ng-container>
          <ng-container *ngIf="members.length > 0">
            <div class="row" *ngFor="let member of members">
              <div
                class="ch-clickable member-name"
                [routerLink]="[
                  '/member-services-mgmt/members',
                  member.memberId
                ]"
              >
                {{ member.displayName }}
              </div>
              <div>
                {{ getClientName(member.clientId) }}
              </div>
              <div>
                {{ member.dob | date : 'MM/dd/yyyy' : '+0' }}
              </div>
              <div>&nbsp;</div>
            </div>
          </ng-container>
        </ng-template>
        <mat-icon
          class="close-findings ch-clickable"
          (click)="allowSearchResultsOverlay = false"
          >close</mat-icon
        >
      </div>
    </div>
  </div>
</ng-container>
