import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, filter, concatMap, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromRoot from 'carehub-root/state/app.state';
import { Router } from '@angular/router';
import { AuthService } from 'carehub-shared/services/auth.service';
import { User } from '../state/shared.reducer';
import * as fromShared from 'carehub-shared/state/index';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  private user: User;

  // observable where new subscribers immediately receive the current state,
  // good when current state may be emitted before the subscription
  private userLoaded$ = new BehaviorSubject<boolean>(false);
  constructor(
    private sharedStore: Store<fromRoot.State>,
    private router: Router,
    private authService: AuthService
  ) {
    this.sharedStore
      .pipe(
        select(fromShared.getCurrentUser),
        filter((user) => user && user.securityInfo != null)
      )
      .subscribe((user) => {
        this.user = user;
        // inform subscribers that the user is loaded
        this.userLoaded$.next(true);
      });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.userLoaded$.pipe(
      // only emit when loaded is true, may be false since this is a behavior subject
      filter((loaded) => loaded === true),

      // no dupes
      take(1),

      // wait for the user loaded pipe to emit true before proceeding.
      // concat map waits for a to emit before starting b
      concatMap((_) => {
        const token = this.authService.getAccessToken();
        const headers = req.headers.set('Authorization', `Bearer ${token}`);

        const authReq = req.clone({ headers });

        return next.handle(authReq).pipe(
          tap(
            () => {},
            (error) => {
              const respError = error as HttpErrorResponse;
              if (respError && respError.status === 401) {
                console.log(
                  `AuthHttpInterceptor Finished (Unauthorized): ${req.method}:${req.url}`
                );
                this.router.navigate(['/unauthorized']);
              }
            }
          )
        );
      })
    );
  }
}
