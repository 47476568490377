import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GroupsApiService } from 'carehub-api/groups-api.service';
import { Group } from 'carehub-api/models/security/group';
import { BaseComponent } from 'carehub-root/shared/components/base-component';
import { AuthService } from 'carehub-root/shared/services/auth.service';
import { SmartListCriteria } from 'carehub-root/shared/smartlist';
import { breakdownUrl } from 'carehub-shared/url-part';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

export class GroupOption {
  public constructor(
    group: Group,
    originalGroups: string[],
    currentGroups: string[]
  ) {
    this.groupId = group.groupId;
    this.name = group.name;
    this.selected = currentGroups.find((name) => name === group.name) != null;
    this.originallySelected =
      originalGroups.find((name) => name === group.name) != null;
  }
  groupId: string;
  name: string;
  selected: boolean;
  originallySelected: boolean;
}
@Component({
  selector: 'ch-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss'],
})
export class ImpersonateComponent extends BaseComponent implements OnInit {
  private allGroups: Group[];
  groups: GroupOption[];
  loading = false;

  get filteredGroups(): GroupOption[] {
    return !this.groups
      ? []
      : !!this.nameFilter
      ? this.groups.filter(
          (group) =>
            group.name.toLowerCase().indexOf(this.nameFilter.toLowerCase()) >= 0
        )
      : this.groups;
  }

  nameFilter: string;
  constructor(
    private authService: AuthService,
    private groupsApi: GroupsApiService,
    private router: Router
  ) {
    super();

    this.unsubscribe$.subscribe(() => {});
  }

  ngOnInit() {
    this.loadGroups()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.onGroupsLoaded());
  }
  protected onDestroy(): void {}

  public onCancel(): void {}
  public onReset(): void {
    this.authService.impersonateWithGroups(
      null,
      this.updatedCallback.bind(this)
    );
  }
  public onApply(): void {
    const selectedGroups = this.groups
      .filter((group) => group.selected)
      .map((group) => {
        return { name: group.name, id: group.groupId };
      });
    this.authService.impersonateWithGroups(
      selectedGroups,
      this.updatedCallback.bind(this)
    );
  }

  private updatedCallback(): void {
    const currentLocation = breakdownUrl(this.router.url);
    this.router.navigate(['/home'], { skipLocationChange: true }).then(() =>
      this.router.navigate([currentLocation.address], {
        queryParams: currentLocation.query,
        fragment: currentLocation.fragment,
      })
    );
  }
  private loadGroups(): Subject<void> {
    const resultsSubject = new Subject<void>();
    this.unsubscribe$.subscribe(() => resultsSubject.complete());
    this.allGroups = [];
    this.loading = true;
    const loadAllCriteria = new SmartListCriteria();
    loadAllCriteria.pageSize = 0;
    loadAllCriteria.pageIndex = 0;
    // function to keep loading while other pages exist
    const loadNextPageFromApi = (criteria: SmartListCriteria) => {
      this.groupsApi
        .getAllGroups(criteria)
        .pipe(take(1), takeUntil(this.unsubscribe$))
        .subscribe((groups) => {
          this.allGroups.push(...groups.results);
          if (groups.pageCount > groups.currentPage) {
            criteria.pageIndex = groups.currentPage; // index is 0 based and page is 1 based
            loadNextPageFromApi(criteria);
          } else {
            resultsSubject.next();
            resultsSubject.complete();
          }
        });
    };
    loadNextPageFromApi(loadAllCriteria);
    return resultsSubject;
  }
  private onGroupsLoaded(): void {
    const selectedIds = this.authService.securityInfo.groups.split(', ');
    const originallySelectedIds = this.authService.originalUserGroups
      ? this.authService.originalUserGroups.split(', ')
      : selectedIds;
    this.groups = this.allGroups.map(
      (groupDto) =>
        new GroupOption(groupDto, originallySelectedIds, selectedIds)
    );
    this.loading = false;
  }
}
