import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from 'carehub-root/state/app.state';
import * as sharedActions from 'carehub-shared/state/shared.actions';

// not proud of this, but I don't want the app-busy behavior
// for this whole controller
const BYPASS = /UserNotification/;

@Injectable()
export class AppBusyHttpInterceptor implements HttpInterceptor {
  constructor(private sharedStore: Store<fromRoot.State>) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (BYPASS.test(req.url)) {
      return next.handle(req);
    }

    setTimeout(() => {
      this.sharedStore.dispatch(new sharedActions.SetBusyStatus(true));
    });

    const result = next.handle(req).pipe(
      tap((val: any) => {
        if (val.status) {
          if (
            +val.status >= 200 &&
            +val.status < 300 &&
            (req.method === 'PUT' ||
              req.method === 'PATCH' ||
              req.method === 'POST')
          ) {
            if (req.url.endsWith('/upload')) {
              this.sharedStore.dispatch(
                new sharedActions.SetCurrentMessage('Uploaded Successfully')
              );
            } else {
              this.sharedStore.dispatch(
                new sharedActions.SetCurrentMessage('Saved Successfully')
              );
            }
          }
          setTimeout(() => {
            this.sharedStore.dispatch(new sharedActions.SetBusyStatus(false));
          });
        }
      })
    );

    return result;
  }
}
