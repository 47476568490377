import { Directive, Renderer2, ElementRef, OnInit } from '@angular/core';
import {
  DecimalFormatterService,
  DecimalFormatterOptions,
} from '../services/converters/decimal-formatter.service';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { FormatterDirectiveBase } from './base/formatter.directive.base';
import { InputFormatter } from '../services/converters/input-formatter.interface';

@Directive({
  selector: '[enPercentFormatter]',
  host: {
    '(blur)': 'onTouched()',
    '(change)': 'onChange($event.target.value)',
  },
})
export class PercentFormatterDirective
  extends FormatterDirectiveBase
  implements ControlValueAccessor, OnInit
{
  public formatterOptions: Partial<DecimalFormatterOptions>;
  public formatter: InputFormatter;
  constructor(
    renderer: Renderer2,
    element: ElementRef,
    controlBinder: NgControl,
    decimalFormatter: DecimalFormatterService
  ) {
    super(renderer, element, controlBinder);
    this.formatter = decimalFormatter;
    this.formatterOptions = {
      multiplier: 100,
      suffix: '%',
      roundToDecimal: 1,
    };
  }
}
