import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Team } from 'carehub-api/models/security/team';
import { User } from 'carehub-api/models/security/user';
import { TeamsApiService } from 'carehub-api/teams-api.service';
import { TestApiService } from 'carehub-rest-services/chiliSauceApi/test-api.service';
import { Email } from 'carehub-root/shared/components/controls/emails-field/emails-field.component';
import {
  ColumnDetails,
  ColumnTextAlign,
} from 'carehub-root/shared/components/controls/smartlist-grid/column-details';
import { FormBuilderService } from 'carehub-root/shared/form-definition-builder/form-builder-service';
import { FormConfig } from 'carehub-root/shared/form-definition-builder/form-config';
import {
  SmartListCriteria,
  SmartListResult,
} from 'carehub-root/shared/smartlist';
import { Required, StringLength } from 'carehub-root/shared/validators';
import {
  CheckFilterComponent,
  CheckFilterState,
} from 'carehub-shared/components/controls/check-filter/check-filter.component';
import { LookupItem } from 'carehub-shared/state/shared.reducer';
import { Observable, of, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

class Color {
  name: string;
  code: string;
  date: Date;
  price?: number;
}

class DomainObject {
  @StringLength(6)
  name: string;
  description: string;
  dob: Date;
  phone: string;
  ssn: string;
  cost: number;
  percentage: number;
  isSmart: boolean;
  color: Color;
  @Required()
  colorName: string;
  otherColor: any;
  emails: string;
}

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['../test.component.scss'],
})
export class TestComponent implements OnInit {
  domainObject = new DomainObject();
  formConfig: FormConfig;
  colors: Color[] = [
    {
      name: 'Blue',
      code: '#0000FF',
      price: 0,
      date: new Date(Date.parse('3/3/2003')),
    },
    {
      name: 'Black',
      code: '#000000',
      price: 0,
      date: new Date(Date.parse('4/4/2004')),
    },
    {
      name: 'PREMIUM: Orange',
      code: '#ebb434',
      price: 20,
      date: new Date(Date.parse('2020-1-02')),
    },
  ].sort((a, b) => a.name.localeCompare(b.name));

  emails: Email[] = [];
  colors$: Observable<SmartListResult<Color>>;
  colorsCriteria$ = new Subject<SmartListCriteria>();
  includedColumns: ColumnDetails[] = [
    {
      columnDef: 'name',
      header: 'Name',
      cell: (row: Color) => {
        // pretend it's an observable
        return of(row.name);
      },
    },
    {
      columnDef: 'code',
      sortable: true,
      header: 'Hex',
      alignText: ColumnTextAlign.Left,
      cell: (row: Color) => row.code,
    },

    {
      columnDef: 'price',
      sortable: true,
      header: 'Price',
      type: 'currency',
      cell: (row: Color) => row.price,
    },
    {
      columnDef: 'date',
      sortable: true,
      header: 'Date Added',
      cell: (row: Color) =>
        row.date ? formatDate(`${row.date}`, 'mediumDate', 'en-US') : 'N/A',
    },
    {
      columnDef: 'action',
      header: 'Show',
      alignText: ColumnTextAlign.Left,
      cell: (_: Color) => {
        return {
          icons: ['show'],
        };
      },
      iconSets: [
        {
          show: {
            icon: 'announcement',
            color: 'blue',
            toolTip: 'Show',
            click: function () {
              alert('hello world!');
            },
          },
        },
      ],
    },
  ];

  selectedUser: User;
  selectedTeamUser: User;
  targetTeam: Team;
  lookupSingleSelected: LookupItem;
  lookupMultiSelected: LookupItem[] = [];
  @ViewChild('checkboxFilter') checkboxFilter: CheckFilterComponent;
  checkboxFilterState: CheckFilterState = CheckFilterState.Crossed;
  checkboxFilterLabels: Partial<{ [key in CheckFilterState]: string }> = {
    checked: 'I am checked',
    crossed: 'I am cross',
  };

  constructor(
    private formBuilderService: FormBuilderService,
    private teamService: TeamsApiService,
    private testApiService: TestApiService
  ) {
    this.setInitialValuesForMocking();

    this.formConfig = this.formBuilderService.build(DomainObject.prototype, [
      'name',
      'description',
      'dob',
      'phone',
      'ssn',
      'cost',
      'isSmart',
      'percentage',
      'color',
      'colorName',
      'otherColor',
      'emails',
    ]);

    this.formConfig.formGroup.patchValue({
      name: this.domainObject.name,
      description: this.domainObject.description,
      dob: this.domainObject.dob,
      phone: this.domainObject.phone,
      ssn: this.domainObject.ssn,
      cost: this.domainObject.cost,
      isSmart: this.domainObject.isSmart,
      percentage: this.domainObject.percentage,
      color: this.domainObject.color,
      colorName: this.domainObject.colorName,
      otherColor: this.domainObject.otherColor,
      emails: this.domainObject.emails,
    });
  }

  ngOnInit() {
    this.teamService
      .getAllTeams(Object.assign(new SmartListCriteria(), { pageSize: 20 }))
      .pipe(take(1))
      .subscribe(
        (teams) =>
          (this.targetTeam = teams.results.find(
            (team) => team.name.indexOf('Hub') >= 0
          ))
      );
  }

  getColorDisplayValue(value: Color): string {
    return `${value.name} (${value.code})`;
  }

  public throwHttpError() {
    this.testApiService
      .getServerError()
      .pipe(take(1))
      .subscribe(() =>
        alert('this should not happen, since an error should be throws')
      );
  }
  public throwJsError() {
    throw new Error("Oh No! I'm an Error test");
    alert('this should not happen, since an error should be throws');
  }
  private setInitialValuesForMocking() {
    this.domainObject.name = 'Sample';
    // eslint-disable-next-line max-len
    this.domainObject.description =
      'Vitae porro ha nulli ex nasci an du omnem. Scribi operae vim his vocant qualis somnia. Ope recurrunt innumeras admonitus agi potestate. To co ac harum ne quare cupio. Advenire pendeant vim ubi curantes posterum earumque. Ex quoties sciamus intueor admodum ei ac.';
    this.domainObject.dob = new Date(Date.now());
    this.domainObject.phone = '2895271320';
    this.domainObject.ssn = '111223333';
    this.domainObject.cost = 12.99;
    this.domainObject.isSmart = true;
    this.domainObject.percentage = 125.25;
    this.domainObject.color = null;
    this.domainObject.colorName = null;
    this.domainObject.otherColor = null;
    this.domainObject.emails = 'foo@bar.com;biz@baz.com';

    // TODO: This is a bit wonky and I should refactor the email
    // component to be simpler.
    this.domainObject.emails
      .split(';')
      .forEach((entry) =>
        this.emails.push({ address: entry, removable: true })
      );

    this.colorsCriteria$.subscribe((criteria: SmartListCriteria) => {
      let colors = this.colors;
      if (criteria.filter) {
        colors = this.colors.filter((entry) => {
          const include = entry.name
            .toLocaleLowerCase()
            .startsWith(criteria.filter.toLocaleLowerCase());
          return include;
        });
      }

      const smartListOfColors: SmartListResult<Color> = {
        currentPage: 1,
        pageCount: 1,
        pageSize: 10000,
        rowCount: colors.length,
        results: colors,
      };

      this.colors$ = of(smartListOfColors);
    });
  }
}
