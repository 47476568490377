import { Component, Input, OnInit } from '@angular/core';
import { AgeGroup } from 'carehub-api/enums/provider/agegroup';

@Component({
  selector: 'ch-provider-indicator',
  templateUrl: './provider-indicator.component.html',
  styleUrls: ['./provider-indicator.component.scss'],
})
export class ProviderIndicatorComponent implements OnInit {
  @Input() indicator: AgeGroup;

  isIndicatorAllAges(indicator: AgeGroup): boolean {
    return indicator == AgeGroup.allAges;
  }
  isIndicatorPediatricOnly(indicator: AgeGroup): boolean {
    return indicator == AgeGroup.pediatricOnly;
  }
  isIndicatorAdultOnly(indicator: AgeGroup): boolean {
    return indicator == AgeGroup.adultsOnly;
  }
  constructor() {}

  ngOnInit(): void {}
}
