import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ssn',
})
export class SsnPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value || !value.length) {
      return '';
    }

    return `***-**-${value.substring(value.length - 4)}`;
  }
}
