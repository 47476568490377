<div
  [class.ch-optional-host-container]="!removePadding"
  [class.reduce-top-padding]="hideHeader"
>
  <div *ngIf="!hideHeader" class="action-control-holder">
    <span *ngIf="gridTitle" class="grid-title">{{ gridTitle }}</span>
    <ng-container *enIfWriteAllowed="permissionName; else forbiddenIcon">
      <!--put tooltip in containing div so it works when the button is disabled-->
      <div *ngIf="!disableAdd" class="button-container" matTooltip="Add New">
        <button
          class="add"
          mat-icon-button
          (click)="onAdd($event)"
          [routerLink]="determineLink()"
        >
          <mat-icon aria-label="Add item">add_box</mat-icon>
        </button>
      </div>
      <div *ngIf="canDelete()" class="button-container" matTooltip="Delete">
        <button
          [disabled]="!isAnyChecked()"
          class="delete"
          mat-icon-button
          (click)="onDelete($event)"
        >
          <mat-icon aria-label="Delete item">delete</mat-icon>
        </button>
      </div>
      <div
        *ngFor="let icon of getExtraIcons()"
        class="button-container"
        [matTooltip]="
          icon.toolTipResolver ? icon.toolTipResolver() : icon.toolTip
        "
      >
        <button
          [disabled]="icon.enabled != null && !icon.enabled()"
          class="custom-icon"
          mat-icon-button
          (click)="icon.click ? icon.click($event) : null"
        >
          <mat-icon [attr.aria-label]="icon.toolTip">{{ icon.icon }}</mat-icon>
        </button>
      </div>
    </ng-container>
    <ng-template #forbiddenIcon>
      <mat-icon
        *ngIf="disableAdd || !canDelete()"
        class="forbidden-icon"
        matTooltip="You do not have permission to edit this section."
        >lock
      </mat-icon>
    </ng-template>
  </div>
  <div class="mat-elevation-z8 tableBodyContent">
    <mat-progress-bar class="wait-notifier" [mode]="progressMode" value="100">
    </mat-progress-bar>

    <div class="filtering-controls">
      <ng-content></ng-content>
    </div>

    <table
      class="smart-grid-table"
      mat-table
      [dataSource]="datasource"
      matSort
      [matSortDirection]="smartListCriteria?.sortDirection"
      [matSortDisabled]="disableSort"
      [matSortActive]="smartListCriteria?.sortField"
      (matSortChange)="onSortChange($event)"
      [class.extra-large]="extraLarge"
      [class.is-busy]="smartListResultObject?.isLoading"
      [class.is-select-frozen]="freezeSelection"
    >
      <!-- Checkbox Cells Definition -->
      <ng-container
        *ngIf="
          selectableType === 'Checkbox' ||
          selectableType === 'SingleRowCheckbox'
        "
        matColumnDef="__select"
      >
        <th mat-header-cell *matHeaderCellDef class="checkbox-holder">
          <mat-checkbox
            *ngIf="allowSelectAll"
            (change)="$event ? onMasterToggle() : null"
            disableRipple="true"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" class="checkbox-holder">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="onRowCheckChange($event, row)"
            disableRipple="true"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Grid Data Cells Definition -->
      <ng-container
        *ngFor="let column of includedColumns"
        [matColumnDef]="column.columnDef"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [disabled]="!column.sortable"
          [class.currency-type]="column.type === 'currency'"
          [class.show-sortable]="column.sortable"
          [style.width]="column.width ? column.width : ''"
          [matTooltip]="column.toolTip"
        >
          {{ column.header }}
        </th>
        <td
          mat-cell
          *matCellDef="let row; let i = index"
          [ngClass]="
            column.cssTextClassResolver ? column.cssTextClassResolver(row) : ''
          "
          [style.background-color]="
            column.backgroundColor ? column.backgroundColor : null
          "
          [ngStyle]="rowCssResolver?.cssStyleResolver(row)"
          [class]="column.type ? column.type + '-type' : ''"
          [class.align-content]="column.alignText != null"
          [class.align-left]="column.alignText === ColumnTextAlign.Left"
          [class.align-right]="column.alignText === ColumnTextAlign.Right"
          [class.align-center]="column.alignText === ColumnTextAlign.Center"
        >
          <ng-container *ngVar="column.cell(row, i, totalRows) as val">
            <ng-container [ngSwitch]="typeOf(column, val)">
              <ng-container *ngSwitchCase="'array'">
                <div *ngFor="let subRow of val">{{ subRow }}</div>
              </ng-container>
              <div *ngSwitchCase="'icons'" class="icons">
                <ng-container
                  *ngFor="
                    let iconEntry of getCellValueIcons(val);
                    let iconIndex = index
                  "
                >
                  <ng-container
                    *ngIf="getIcon(iconIndex, column, iconEntry) as iconDetails"
                  >
                    <mat-icon
                      *ngIf="
                        iconDetails?.icon &&
                        ((iconDetails && !iconDetails.render) ||
                          iconDetails.render(row))
                      "
                      [style.color]="iconDetails.color"
                      (click)="onIconClick($event, row, iconDetails)"
                      [matTooltip]="
                        iconDetails.toolTipResolver
                          ? iconDetails.toolTipResolver(row)
                          : iconDetails.toolTip
                      "
                    >
                      {{ iconDetails.icon }}</mat-icon
                    >
                    <span
                      class="text-icon"
                      *ngIf="iconDetails && iconDetails?.value"
                      (click)="onIconClick($event, row, iconDetails)"
                      [matTooltip]="
                        iconDetails.toolTipResolver
                          ? iconDetails.toolTipResolver(row)
                          : iconDetails.toolTip
                      "
                      >{{ iconDetails.value() }}</span
                    >
                  </ng-container>
                </ng-container>
              </div>

              <ng-container
                *ngSwitchCase="'control'"
                [ngSwitch]="column.controlDef.type"
              >
                <ng-container *ngSwitchCase="'radio-button'">
                  <mat-radio-group [disabled]="row.levelId == null">
                    <mat-radio-button
                      *ngFor="let data of column.controlDef.bindingDataSource()"
                      [value]="data.id"
                      (click)="$event.stopPropagation()"
                      disableRipple="true"
                      (change)="column.controlDef.click($event, row)"
                      [checked]="row.levelId == data.id"
                    >
                      {{ data.description }} &nbsp; &nbsp;
                    </mat-radio-button>
                  </mat-radio-group>
                </ng-container>
                <ng-container *ngSwitchCase="'checkbox'">
                  <mat-checkbox
                    (change)="onCheckBoxClicked($event, row, column)"
                    disableRipple="true"
                    [disabled]="
                      column.controlDef.isDisabled &&
                      column.controlDef.isDisabled(row)
                    "
                    [checked]="column.controlDef.bindingDataSource(row)"
                    (click)="$event.stopPropagation()"
                  >
                  </mat-checkbox>
                </ng-container>
                <ng-container *ngSwitchCase="'auto-lookup-multi-select'">
                  <div class="row-multiselect">
                    <ch-lookup-multi-select
                      [placeholder]="column.controlDef.name"
                      [lookupType]="column.controlDef.lookupType"
                      [selectedIds]="column.controlDef.selectedIds(row)"
                      (selectedIdsChange)="
                        column.controlDef.updatedSelectedIds(row, $event)
                      "
                      [compact]="true"
                    ></ch-lookup-multi-select>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'search'" class="field-columns">
                  <div
                    [ngClass]="column.controlDef.cssResolver(row, i)"
                    class="field-search-outline"
                  >
                    <input
                      class="input-field"
                      matInput
                      [value]="column.controlDef.selectedIds(row)"
                      [readonly]="isReadonly"
                      (change)="
                        column.controlDef.updatedSelectedIds(
                          row,
                          i,
                          $event.target.value
                        )
                      "
                      (blur)="onBlur($event)"
                      [disabled]="
                        column.controlDef.isDisabled &&
                        column.controlDef.isDisabled(row)
                      "
                      [class.input-field-color]="
                        column.controlDef.isDisabled &&
                        column.controlDef.isDisabled(row)
                      "
                    />
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="column.controlDef.type === 'button-positive'"
                >
                  <div class="row-button">
                    <button
                      mat-button
                      class="positive"
                      (click)="column.controlDef.click(row, $event)"
                    >
                      {{ column.controlDef.name }}
                    </button>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="column.controlDef.type === 'button-negative'"
                >
                  <div class="row-button">
                    <button
                      mat-button
                      class="negative"
                      (click)="column.controlDef.click(row, $event)"
                    >
                      {{ column.controlDef.name }}
                    </button>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="'observable'">
                <ng-container *ngIf="column.type !== 'currency'; else currency">
                  {{ val | async }}
                </ng-container>
                <ng-template #currency>
                  {{
                    val | async | currency : 'USD' : 'symbol-narrow' : '1.2-2'
                  }}
                </ng-template>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <ng-container *ngIf="column.type !== 'currency'; else currency">
                  <ng-container
                    *ngIf="
                      column.cellValueMaxLength &&
                        val &&
                        val.length > column.cellValueMaxLength;
                      else unTruncated
                    "
                  >
                    <span [matTooltip]="val" matTooltipClass="cell-value-tip">{{
                      val.slice(0, column.cellValueMaxLength) + '…'
                    }}</span>
                  </ng-container>
                </ng-container>
                <ng-template #unTruncated>
                  {{ val }}
                </ng-template>
                <ng-template #currency>
                  {{
                    val | currency : 'USD' : 'symbol-narrow' : '1.2-2'
                  }}</ng-template
                >
              </ng-container>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <!-- Apply Definitions from above -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="onRowClick(row, $event)"
        [class.single-selected-row]="
          selectableType === 'SingleRow' && selection.isSelected(row)
        "
      ></tr>
    </table>

    <!--Paginator -->
    <ng-container *ngIf="datasourceLength > 0 && smartListFound">
      <ng-container *ngIf="isReadingAllowed">
        <mat-paginator
          #gridPaginator
          *ngIf="page?.observers?.length && showPaginator"
          [pageSizeOptions]="pageSizeOptions"
          [length]="totalRows"
          [pageIndex]="smartListCriteria?.pageIndex"
          [pageSize]="smartListCriteria?.pageSize"
          (page)="onPage($event)"
          [class.infinite]="infiniteScroll"
        >
        </mat-paginator>
      </ng-container>
    </ng-container>

    <div
      class="no-items-footer"
      *ngIf="datasourceLength === 0 && isReadingAllowed"
    >
      No items found.
    </div>
    <div class="not-allowed-footer" *ngIf="!isReadingAllowed">
      You do not have permission to this section.
    </div>
  </div>
</div>
