<div class="grid-container">
  <ch-providergroup-banner [providerGroup]="providerGroup$ | async">
  </ch-providergroup-banner>
  <div class="providergroup-nav">
    <ch-side-nav [menuItems]="menuItems"></ch-side-nav>
  </div>
  <div class="providergroup-body">
    <router-outlet></router-outlet>
  </div>
</div>
