import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BaseComponent } from 'carehub-shared/components/base-component';
import * as fromRoot from 'carehub-root/state/app.state';
import * as fromShared from 'carehub-shared/state';
import { takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseComponent {
  public isLoggedIn = false;
  constructor(private sharedStore: Store<fromRoot.State>) {
    super();

    this.sharedStore
      .pipe(select(fromShared.getCurrentUser), takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.isLoggedIn = !!result;
      });
  }

  protected onDestroy(): void {}
}
