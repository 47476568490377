import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

/**
 * facilitates communication between content components and the app content container
 *
 * Use cases include a component wanting to render the right scroll without duplication. Since we cannot use css to enforce a 'has-child-node' rule, we use a service to communicate up to the app component.
 */
@Injectable({
  providedIn: 'root',
})
export class ContentContainerService implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  private rightScrollClaimed$ = new BehaviorSubject<boolean>(false);

  constructor() {}
  ngOnInit(): void {
    this.destroyed$.pipe(take(1)).subscribe((_) => {
      this.rightScrollClaimed$.complete();
    });
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * if the container will handle the right scroll bar (which should always be visible), it should call this method. if this method is used, the component must call releaseRightScroll when it is destroyed
   */
  public claimRightScroll(): void {
    setTimeout(() => this.rightScrollClaimed$.next(true));
  }
  /**
   * if a container will handle the right scroll bar, this method is used to inform the app content container to resume handling the right scroll, and should be called by content containers on destroy
   */
  public releaseRightScroll(): void {
    this.rightScrollClaimed$.next(false);
  }
  /**
   * used by src/app/core/app.component.ts to enable component to claim handling the right scroll while still rendering the scroll otherwise
   * @param callback invoked when a component claims or releases the the right scroll responsibility
   */
  public onRightScrollToggle(callback: (claimed: boolean) => void) {
    this.rightScrollClaimed$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((claimed) => callback(claimed));
  }
}
