<ch-smartlist-grid
  permissionName="Member"
  [includedColumns]="includedColumns"
  disableAdd="true"
  [smartListResult]="dataSource"
  [isLoadingInput]="isLoading"
  [smartListCriteria]="smartListCriteria"
  gridTitle="Members"
  (rowClick)="onRowClick($event)"
  (page)="onPage($event)"
  (sort)="onSort($event)"
  [infiniteScroll]="true"
>
  <ch-smartlist-filter mainFilters>
    <div>
      <input
        type="search"
        enSmartBlur
        class="search memberPhoneNumber"
        aria-label="Member Phone Number Filter"
        [value]="filter?.memberPhoneNumber || null"
        placeholder="Member Phone Number"
        (blur)="onFilter('memberPhoneNumber', $event.target.value)"
      />
    </div>
    <div>
      <input
        type="search"
        enSmartBlur
        class="search memberFirstName"
        aria-label="Member First Name Filter"
        [value]="filter?.memberFirstName || null"
        placeholder="Member First Name"
        (blur)="onFilter('memberFirstName', $event.target.value)"
      />
    </div>
    <div>
      <input
        type="search"
        enSmartBlur
        class="search memberLastName"
        aria-label="Member Last Name Filter"
        [value]="filter?.memberLastName || null"
        placeholder="Member Last Name"
        (blur)="onFilter('memberLastName', $event.target.value)"
      />
    </div>
    <div>
      <input
        type="search"
        enSmartBlur
        class="search dob"
        aria-label="DOB Filter"
        [(ngModel)]="memberBirthDate"
        placeholder="Date of Birth"
        (blur)="onDateFilter('memberBirthDate', $event.target.value)"
      />
    </div>
    <ch-lookup-select
      [selectedId]="filter?.memberStatusId || null"
      (selectedChange)="onFilter('memberStatusId', $event ? $event.id : '')"
      lookupType="MemberStatuses"
      placeholder="Member Status"
    >
    </ch-lookup-select>
    <div>
      <input
        type="search"
        enSmartBlur
        class="search emp"
        aria-label="emp Filter"
        [value]="filter?.memberEmployeeNumber || null"
        placeholder="Employee Number"
        (blur)="onFilter('memberEmployeeNumber', $event.target.value)"
      />
    </div>
  </ch-smartlist-filter>
</ch-smartlist-grid>
