export const BASE_ENVIRONMENT = {
  production: false,
  logoEnvText: 'LOCAL',
  environmentName: 'Local',
  // migrating to this structure
  apis: {
    flags: 'https://edh-flags-dev.azurewebsites.net',
    carehub: 'https://com-edhc-chilisauce-api-dev.azurewebsites.net',
    security: 'https://com-edhc-chilisauce-secapi-dev.azurewebsites.net',
  },
  featureServiceUri: 'https://edh-flags-dev.azurewebsites.net',
  chiliSauceApiRoot: 'https://com-edhc-chilisauce-api-dev.azurewebsites.net',
  securityApiRoot: 'https://com-edhc-chilisauce-secapi-dev.azurewebsites.net/',
  blobStorage: 'https://comedhccarehubdev.blob.core.windows.net',
  claimsFaxLine: '8889654011',
  receiptFax: '8889654011',
  receiptEmail: 'accounting@edhc.com',
  preLaunchTheme: 'Dark,Light',
  launchStatus: 'PreLaunch,Launched',
  // deepcode ignore HardcodedNonCryptoSecret: just a key, not a secret
  googleMapsApiKey: 'AIzaSyAsoRR9YHAbG75tvj0xT1uWGjJA9TMSsjc',
};

export const OIDC_DEFAULTS = {
  authority: 'https://com-edhc-chilisauce-sts-dev.azurewebsites.net/',
  redirect_uri: origin + '/login-redirect',
  post_logout_redirect_uri: origin + '/logout-redirect',
  client_id: 'carehub-web',
  scope: 'openid profile carehub',
  response_type: 'id_token token',
  silent_redirect_uri: '/',
};

export const APP_INSIGHTS_DEFAULTS = {
  enableCorsCorrelation: true,
  autoTrackPageVisitTime: true,
  verboseLogging: true,
  disableDataLossAnalysis: false,
  disableCorrelationHeaders: false,
  correlationHeaderExcludedDomains: Array<string>(),
};
