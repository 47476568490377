import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface IFeatureFlag {
  key: string;
  value: boolean;
  description: string;
  expires: number;
}
export class FeatureFlag {
  key: string;
  value: boolean;
  description: string;
}

// each key has a time to live of 30s
const KEY_EXPIRES = 1000 * 30;

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsApiService {
  cache: { [key: string]: IFeatureFlag } = {};
  root: string;

  constructor(protected http: HttpClient) {
    if (environment.featureServiceUri) {
      this.root = environment.featureServiceUri + '/feature-flag';
    }
  }

  get(
    key: string,
    defaultValue: FeatureFlag = {
      key: key,
      value: false,
      description: '',
    }
  ): Observable<FeatureFlag> {
    if (!this.root) {
      return of(defaultValue);
    }

    let now = Date.now();
    let cached = this.cache[key];
    if (cached && cached.expires < now) {
      return of(cached);
    }

    return this.http.get<FeatureFlag>(this.root + `/${key}`).pipe(
      tap((flag) => {
        if (flag != null) {
          this.cache[flag.key] = {
            key: flag.key,
            value: flag.value,
            description: flag.description,
            expires: now + KEY_EXPIRES,
          };
        }
      })
    );
  }

  // reserved for admins to toggle flags
  set(flag: FeatureFlag, value: boolean) {
    if (!this.root) {
      return of({});
    }

    return this.http.put(this.root + '/update', { ...flag, value: value });
  }

  all() {
    if (!this.root) {
      return of(Array<IFeatureFlag>());
    }

    return this.http.get<IFeatureFlag[]>(this.root + '/all');
  }

  delete(key: string): Observable<any> {
    if (!this.root) {
      return of({});
    }

    return this.http.delete(this.root + '/delete/' + key).pipe(
      tap(() => {
        delete this.cache[key];
      })
    );
  }
}
