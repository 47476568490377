<span [formGroup]="formGroup">
  <mat-form-field>
    <mat-chip-list #chipList aria-label="Email selection">
      <mat-chip
        *ngFor="let email of emails"
        [selectable]="true"
        [removable]="true"
        (removed)="onRemoveEmail(email)"
      >
        {{ email.address }}
        <mat-icon
          matChipRemove
          *ngIf="email.removable && !disabled && !formGroup.disabled"
          >cancel</mat-icon
        >
      </mat-chip>
      <input
        [readonly]="disabled || formGroup.disabled"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="onAddEmail($event)"
      />
      <mat-error *ngIf="formGroup.get(propertyName).invalid">
        Please provide valid email addresses only.
      </mat-error>
    </mat-chip-list>
  </mat-form-field>
</span>
