import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  SmartListCriteria,
  SmartListResult,
} from 'carehub-root/shared/smartlist';
import { Observable, Subject, fromEvent, timer } from 'rxjs';
import { debounceTime, first, take, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base-component';

@Component({
  selector: 'ch-auto-complete-field',
  templateUrl: './auto-complete-field.component.html',
  styleUrls: [
    '../smart-field/smart-field.component.scss',
    './auto-complete-field.component.scss',
  ],
})
export class AutoCompleteFieldComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Input() labelName: string | null;
  @Input() displayPropertyName: string | null;
  @Input() displayFn: (entity: any) => string;
  @Input() propertyName: string;
  @Input() formGroup: FormGroup;
  @Input() displayMessage: { [key: string]: string };
  @Input() dataSource$: Observable<SmartListResult<any>>;
  @Input() criteria$: Subject<SmartListCriteria>;

  @Output() entitySelected = new EventEmitter<any>();
  @Output() blur = new EventEmitter<any>();
  @ViewChild('filterControlInput') filterControl: ElementRef;
  isRequired = false;

  @Input() set required(val: boolean) {
    this.isRequired = val;
  }

  constructor() {
    super();
  }

  ngOnInit() {
    const control = this.formGroup.controls[this.propertyName];
  }

  ngAfterViewInit() {
    // todo: still kinda spammy
    fromEvent(this.filterControl.nativeElement, 'input')
      .pipe(takeUntil(this.unsubscribe$), debounceTime(200))
      .subscribe((searchEvent: { target: any }) => {
        const criteria = new SmartListCriteria();
        criteria.filter = searchEvent.target.value;
        this.criteria$.next(criteria);
      });
  }

  selectedItemDisplay(value: any): string | undefined {
    if (value) {
      if (this.displayFn) {
        return this.displayFn(value);
      }

      return (<any>value)[this.displayPropertyName];
    }
    return '';
  }

  onBlur(event: any) {
    // autocomplete
    // this event fires before the selected event (in aot), so delay
    // relatedTarget is the selected option element from dropdown
    // the null check here prevents the change from optionSelected event from being overwritten
    if (!event.relatedTarget) {
      timer(50)
        .pipe(take(1))
        .subscribe((_) => {
          const value = this.formGroup.get(this.propertyName).value;
          if (this.dataSource$) {
            this.dataSource$.pipe(first()).subscribe((data) => {
              if (value && value.replace) {
                if (data && data.results && data.results.length > 0) {
                  const patchValue: any = {};
                  patchValue[this.propertyName] = data.results[0];
                  this.formGroup.patchValue(patchValue);
                } else {
                  const patchValue: any = {};
                  patchValue[this.propertyName] = value;
                  this.formGroup.patchValue(patchValue);
                }
              }
            });
          }
        });
    }
    this.blur.emit(event);
  }

  onSelected(event: any) {
    if (this.formGroup) {
      this.formGroup.patchValue({
        [this.propertyName]: event.option.value,
      });
      this.formGroup.markAsDirty();
      this.entitySelected.emit(event.option.value);
    }
  }

  getFormControl() {
    let value = null;
    if (this.formGroup) {
      value = this.formGroup.get(this.propertyName);
    }
    return value;
  }

  protected onDestroy(): void {}
}
