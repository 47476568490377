<ch-base-banner [goBack]="this.goBack.bind(this)">
  <div class="banner-content" *ngIf="provider">
    <div class="providerName">
      <span class="banner-label">NAME: </span>
      {{ provider?.firstName + ' ' + provider?.lastName }}
      <span class="indicator">
        <ch-provider-indicator
          [indicator]="provider.ageGroupId"
        ></ch-provider-indicator>
      </span>
    </div>
    <mat-divider class="banner-dividers" [vertical]="true"> </mat-divider>
    <div class="providerNpi">
      <span class="banner-label">NPI: </span>
      {{ provider?.npi }}
    </div>
    <mat-divider class="banner-dividers" [vertical]="true"></mat-divider>

    <div class="providerStatus">
      <span class="banner-label">STATUS: </span>
      {{ providerStatus | async }}
    </div>
  </div>
</ch-base-banner>
