<div class="grid-container">
  <div class="provider-banner">
    <ch-provider-banner [provider]="provider$ | async"> </ch-provider-banner>
  </div>
  <div class="provider-nav">
    <ch-side-nav [menuItems]="menuItems"></ch-side-nav>
  </div>
  <div class="provider-body" style="margin-top: 60px">
    <router-outlet></router-outlet>
  </div>
</div>
