import 'reflect-metadata';

// ---------------------------------------------------------------------------
const urlPartMetadataKey = 'urlPartMetadataKey';

export class ClassType<T> {
  constructor(
    public target: Function,
    public name: string,
    public url: string
  ) {}
}

export function UrlPart(url: string) {
  return (target: Function) => {
    const classConstructor = target;
    const metadata =
      Reflect.getMetadata(urlPartMetadataKey, classConstructor) || {};
    metadata.url = url;
    Reflect.defineMetadata(urlPartMetadataKey, metadata, classConstructor);
  };
}

export function forType<T>(target: new () => T): ClassType<T> {
  const classConstructor = target;
  const result = Reflect.getMetadata(urlPartMetadataKey, classConstructor);
  return new ClassType(target, target.name, result.url);
}

export function breakdownUrl(url: string): {
  address: string;
  query?: { [key: string]: string };
  fragment?: string;
} {
  if (url) {
    // parse out query string and fragment for angular router support
    const queryParams: { [key: string]: string } = {};
    let fragment: string;
    [url, fragment] = url.split('#');
    const [baseAddress, queryString] = url.split('?');
    if (queryString) {
      for (let param of queryString.split('&')) {
        const [key, value] = param.split('=');
        queryParams[key] = decodeURIComponent(value);
      }
    }
    // use router navigate to avoid a full page reload.
    return {
      address: baseAddress,
      query: queryParams,
      fragment: fragment,
    };
  }
}
