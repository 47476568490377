import { Injectable } from '@angular/core';

/** provides contextual information for the call context swap component and iframes in general */
@Injectable({
  providedIn: 'root',
})
export class FrameContextService {
  public hostOrigin: string;
  public childOrigin: string;
  constructor() {}

  public getChildDest(path: string): string {
    if (this.childOrigin == null) {
      return null;
    }
    return this.childOrigin + path;
  }
}
