import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { User } from 'carehub-api/models/security/user';
import { UsersApiService } from 'carehub-api/users-api.service';
import { BaseComponent } from 'carehub-shared/components/base-component';
import { ScopedPermission } from 'carehub-shared/directives/if-allowed.directive';
import { SmartListCriteria } from 'carehub-shared/smartlist';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ch-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
})
export class UserSelectComponent extends BaseComponent implements OnInit {
  @ViewChild('filterInput', { static: true })
  filterInput: ElementRef;
  filteredItems$ = new Subject<User[]>();

  private searchPaging: SmartListCriteria = Object.assign(
    new SmartListCriteria(),
    { pageIndex: 0, pageSize: 5 }
  );

  /*** Input/Output ***/
  @Input() placeholder: string = null;
  @Input() teamId: string = null;
  @Input() hasPermission: ScopedPermission = null;
  @Input() enabled: boolean = true;

  /***    User in/out ***/
  private _user: User;
  get user(): User {
    return this._user;
  }
  @Input() set user(value: User) {
    if (this.userId !== (value ? value.userId : undefined)) {
      this._user = value;
      this.userChange.emit(this.user);
      this.userIdChange.emit(this.userId);
    }
  }
  @Output() userChange = new EventEmitter<User>();

  get userId(): string {
    return this._user ? this._user.userId : undefined;
  }
  @Input() set userId(value: string) {
    if (value !== this.userId) {
      if (value) {
        this.userService
          .getUserById(value)
          .pipe(take(1), takeUntil(this.unsubscribe$))
          .subscribe((loadedUser) => (this.user = loadedUser));
      } else {
        this.user = undefined;
      }
    }
  }
  @Output() userIdChange = new EventEmitter<string>();

  /***    Display methods ***/
  @Input()
  displayName = (i: User): string => (i ? i.displayName : '');

  constructor(private userService: UsersApiService) {
    super();
  }

  ngOnInit() {
    fromEvent(this.filterInput.nativeElement, 'input')
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(600),
        map((event: any) => event.target.value) // get the new input value
      )
      .subscribe((searchText) => this.onUserSearch(searchText));
  }
  protected onDestroy(): void {
    this.filteredItems$.complete();
    this.userChange.complete();
  }

  /**
   * called when focus is moved from the input, to null the selected if the
   * value is empty
   * @param event the blur event
   */
  public onInputBlur(event: FocusEvent) {
    const target = event.target as HTMLTextAreaElement;
    if (target.value === '') {
      this.user = null;
    }
  }

  public onSelection(user: User): void {
    this.user = user;
  }

  /**
   * performs the back end search for the selected user
   * @param searchText the text to search for (name)
   */
  public onUserSearch(searchText: string): void {
    this.userService
      .getFilteredUsers(this.searchPaging, {
        ...this.hasPermission,
        teamId: this.teamId,
        name: searchText,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((users) => this.filteredItems$.next(users.results));
  }
}
