import { Component, OnInit } from '@angular/core';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { moveUserInfoToSessionStorage } from 'carehub-root/shared/services/auth.service';

@Component({
  template: '',
})
export class SilentRedirectComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    moveUserInfoToSessionStorage();

    const config = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    };

    new UserManager(config)
      .signinSilentCallback()
      .then((result) => {
        console.log('Signed in silently...');
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
