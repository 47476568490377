import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as fromRoot from 'carehub-root/state/app.state';
import { BaseComponent } from 'carehub-shared/components/base-component';
import { NavItem } from 'carehub-shared/nav-item';
import { BusinessModuleService } from 'carehub-shared/services/business-module.service';
import * as fromShared from 'carehub-shared/state';

import { FeatureFlagsApiService } from 'carehub-rest-services/feature-flags/feature-flags-api.service';
import {
  getPermissionScope,
  hasPermission,
} from 'carehub-root/shared/directives/if-allowed.directive';

@Component({
  selector: 'ch-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent extends BaseComponent implements OnInit {
  menuItems: Array<NavItem | null> = [];
  permissions$: Observable<{ [key: string]: string[] }>;

  constructor(
    private sharedStore: Store<fromRoot.State>,
    private businessModuleService: BusinessModuleService,
    private flagsApi: FeatureFlagsApiService
  ) {
    super();
  }

  ngOnInit() {
    // this can emit multiple times as user details get loaded.
    this.permissions$ = this.sharedStore.pipe(
      takeUntil(this.unsubscribe$),
      select(fromShared.getUserPermissions)
    );

    this.sharedStore
      .pipe(
        select(fromShared.getCurrentBusinessModuleId),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((value: number) => {
        this.permissions$.subscribe(
          (permissions: { [key: string]: string[] }) => {
            if (value) {
              console.debug(`NavComponent: updating menu-items`);

              this.menuItems.length = 0;
              const menuItems =
                this.businessModuleService.getCurrentBusinessModuleSideMenu(
                  value
                );

              for (const menuItem of menuItems) {
                if (!menuItem) {
                  continue;
                }

                const level = getPermissionScope(menuItem.permissionLevel);
                const allowed = hasPermission(
                  menuItem.permissionName,
                  level,
                  permissions
                );

                if (allowed) {
                  this.menuItems.push(menuItem);
                }

                if (menuItem.featureFlag) {
                  this.flagsApi
                    .get(menuItem.featureFlag)
                    .subscribe(
                      (res) =>
                        (menuItem.featureFlagOn = (res && res.value) || false)
                    );
                }
              }
            }
          }
        );
      });
  }

  onDestroy() {}
}
