import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

// stole this from the ng_if.ts
// in the angular core lib
class NgVarContext<T = unknown> {
  public $implicit: T = null!;
  public ngVar: T = null!;
}

@Directive({
  selector: '[ngVar]',
})
export class VarDirective<T = unknown> {
  @Input()
  set ngVar(context: any) {
    this.context.$implicit = this.context.ngVar = context;
    this.updateView();
  }

  context: NgVarContext<T> = new NgVarContext<T>();
  viewCreated: boolean = false;

  constructor(
    private vcRef: ViewContainerRef,
    private templateRef: TemplateRef<NgVarContext<T>>
  ) {}

  updateView() {
    // ensure that we only render view this once
    if (this.viewCreated) {
      return;
    }

    this.vcRef.clear();
    this.vcRef.createEmbeddedView(this.templateRef, this.context);
    this.viewCreated = true;
  }
}
