import { Component, OnInit } from '@angular/core';
import { BaseMemberIconComponent } from '../base-member-icon/base-member-icon.component';

@Component({
  selector: 'ch-problematic-member-icon',
  templateUrl: './problematic-member-icon.component.html',
  styleUrls: ['./problematic-member-icon.component.scss'],
  providers: [],
})
export class ProblematicMemberIconComponent
  extends BaseMemberIconComponent
  implements OnInit
{
  get getToolTip(): string {
    return (
      'Transfer To Supervisor Immediately' +
      (this.member?.problematicNotes != null
        ? ':\n' + this.member?.problematicNotes
        : '!')
    );
  }
  get getIcon(): string {
    return 'report_problem';
  }

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
