import { Directive, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Directive()
export abstract class BaseComponent implements OnDestroy {
  protected unsubscribe$: Subject<void> = new Subject();

  getValue(formGroup: FormGroup, propertyName: string): any {
    let value = null;

    if (formGroup) {
      const control = formGroup.get(propertyName);
      if (control) {
        value = control.value;
        if (value === undefined) {
          value = null;
        }
      } else {
        console.warn(`FormGroup Control not found for ${propertyName}`);
      }
    }

    return value;
  }

  ngOnDestroy() {
    this.onDestroy();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Called with the ngOnDestroy lifecycle hook
   */
  protected abstract onDestroy(): void;
}
