<ng-container>
  <div class="header-grid-container">
    <div class="left-side">
      <img
        class="logo ch-clickable"
        [class.envTagged]="hint"
        [routerLink]="routerLink"
        src="assets/images/logo.svg"
        alt="Care Hub"
      />
      <span class="environmentLabel">{{ hint }}</span>
      <span [class.user_hidden]="(user$ | async) !== null" class="divider"
        >&nbsp;</span
      >
      <div class="module" *ngIf="(user$ | async) && shouldShowModules">
        <mat-form-field floatLabel="never">
          <mat-label>Select Module</mat-label>
          <mat-select
            id="moduleSelector"
            #moduleSelector
            (selectionChange)="businessModuleChanged($event)"
            [value]="currentBusinessModuleId$ | async"
          >
            <mat-select-trigger>
              <mat-icon
                class="module-select-icon"
                matTooltip="Business Modules"
                alt="Select Module"
                >apps</mat-icon
              >
              <span class="selected-module-name">{{
                moduleSelector.triggerValue
              }}</span>
            </mat-select-trigger>

            <mat-option value="1" *enIfReadAllowed="'MemberServiceModule'">
              Member Services
            </mat-option>
            <mat-option value="5" *enIfReadAllowed="'ClientAccountModule'">
              Client Account Management
            </mat-option>
            <mat-option value="4" *enIfReadAllowed="'ProviderNetworkModule'">
              Provider Network Management
            </mat-option>
            <mat-option value="2" *enIfReadAllowed="'FinanceModule'">
              Claims Finance Management
            </mat-option>
            <mat-option value="3" *enIfReadAllowed="'SecurityModule'">
              Platform Administration
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <ch-master-search
      *ngIf="(user$ | async) && shouldShowSearch"
    ></ch-master-search>

    <div class="right-side">
      <div>&nbsp;</div>
      <div class="links" *ngIf="user$ | async">
        <button
          mat-icon-button
          (click)="toggleNotificationsPanel($event)"
          aria-label="Show notifications"
          *ngIf="shouldShowNotifications"
        >
          <mat-icon
            [matBadge]="notificationCount"
            [matBadgeHidden]="!notificationCount"
            >notifications</mat-icon
          >
        </button>

        <button
          mat-icon-button
          *ngIf="canImpersonate() && shouldShowImpersonator"
          (click)="onImpersonate()"
        >
          <mat-icon>groups</mat-icon>
        </button>

        <button *ngIf="shouldShowHelp" mat-icon-button [routerLink]="['/help']">
          <mat-icon>help</mat-icon>
        </button>

        <div
          #notification_panel
          class="notification-panel"
          [class.hidden]="notificationsPanelHidden"
        >
          <div class="top-actions">
            <a href="#" (click)="dismissAll($event)"> Dismiss All </a>
          </div>

          <div
            class="notification-list"
            *ngIf="notificationCount !== 0; else noNotifications"
          >
            <div *ngFor="let n of notifications" class="notification-item">
              <div class="body" (click)="notificationClicked(n, $event)">
                <div class="timestamp">
                  {{ n.createdDate | date : 'short' }}
                </div>
                <div>{{ n.body }}</div>
              </div>

              <button mat-icon-button (click)="dismissNotification(n, $event)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>

          <ng-template #noNotifications
            ><h3 class="center-text">No Notifications</h3></ng-template
          >

          <div class="bottom-actions">
            <a href="#" (click)="loadMoreNotifications($event)"> Show More </a>
          </div>
        </div>
      </div>

      <div class="divider">&nbsp;</div>
      <div class="user-options" [class.logged-in]="user$ | async">
        <span
          *ngIf="user$ | async"
          (click)="refreshTokens()"
          class="user-name"
          >{{ (user$ | async).displayName }}</span
        >
        <mat-icon
          *ngIf="user$ | async"
          class="logout"
          matTooltip="Log out"
          (click)="logout()"
          >exit_to_app
        </mat-icon>
        <span *ngIf="(user$ | async) === null">
          <button mat-button (click)="login()" autofocus>Login</button>
        </span>
      </div>
    </div>
  </div>
</ng-container>
