import { Pipe, PipeTransform } from '@angular/core';
import { LookupService } from 'carehub-shared/services/lookup.service';
import { of } from 'rxjs';

@Pipe({
  name: 'lookup',
})
export class LookupPipe implements PipeTransform {
  constructor(private lookupService: LookupService) {}

  transform(value: any, args?: any): any {
    if (!args) {
      throw new Error(
        'LookupPipe requires an argument specifying the LookupType to find.'
      );
    }

    return value
      ? this.lookupService.getDescriptionByTypeAndId(args, value)
      : of('');
  }
}
