<mat-form-field appearance="fill">
  <mat-label>Target Testing Environment</mat-label>
  <mat-select (selectionChange)="onSelect($event.value)">
    <mat-option>---</mat-option>
    <mat-option *ngFor="let opt of hostList" [value]="opt.key">
      {{ opt.value }}
    </mat-option>
  </mat-select>
</mat-form-field>
<div class="ch-flex-grid events-grid">
  <mat-form-field appearance="fill">
    <mat-label>Upstream Events</mat-label>
    <input matInput disabled [value]="upstreamCount | number : '1.0-0'" />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Downstream Events</mat-label>
    <input matInput disabled [value]="downstreamCount | number : '1.0-0'" />
  </mat-form-field>
  <button mat-button (click)="onDumpLogToConsole()">
    Dump Events to Console
  </button>
  <button mat-button (click)="onClearLog()">Clear Event Log</button>
</div>

<div>
  <iframe
    class="iframe"
    [class.show-iframe]="targetHost"
    #hostedIframe
  ></iframe>
</div>
