<ch-smartlist-grid
  class="activities"
  permissionName="Activity"
  [includedColumns]="includedColumns"
  disableAdd="true"
  [smartListResult]="activities$ | async"
  [smartListCriteria]="smartListCriteria"
  gridTitle="Activities"
  (rowClick)="onRowClick($event)"
  (load)="onLoad($event)"
  (page)="onPage($event)"
  (sort)="onSort($event)"
>
</ch-smartlist-grid>
