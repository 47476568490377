import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ClientsApiService } from 'carehub-api/clients-api.service';
import { CptApiService } from 'carehub-api/cpt-api.service';
import { DescribedItemsApiService } from 'carehub-api/describeditems-api.service';
import { DrgApiService } from 'carehub-api/drg-api.service';
import { HcpcsApiService } from 'carehub-api/hcpcs-api.service';
import { MembersApiService } from 'carehub-api/members-api.service';
import { Cpt } from 'carehub-api/models/common/cpt';
import { Drg } from 'carehub-api/models/common/drg';
import { Hcpcs } from 'carehub-api/models/common/hcpcs';
import { AuthService } from 'carehub-shared/services/auth.service';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { LookupTypes } from '../services/models/lookup-types.enum';
import * as sharedActions from './shared.actions';
import { LookupItem, SharedState } from './shared.reducer';

@Injectable()
export class SharedEffects {
  static lookupsAlreadyDispatched: LookupTypes[] = [];
  static cptLookupsAlreadyDispatched: string[] = [];
  static drgLookupsAlreadyDispatched: string[] = [];
  static hcpcsLookupsAlreadyDispatched: string[] = [];

  constructor(
    private describedItemsService: DescribedItemsApiService,
    private cptApiService: CptApiService,
    private drgApiService: DrgApiService,
    private hcpcsApiService: HcpcsApiService,
    private clientsService: ClientsApiService,
    private membersService: MembersApiService,
    private sharedStore: Store<SharedState>,
    private router: Router,
    private actions$: Actions,
    private authService: AuthService
  ) {}

  setCurrentBusinessModuleId$ = createEffect(() =>
    this.getCurrentBusinessModuleIdEffect()
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedActions.SharedActionTypes.Login),
      mergeMap((action: sharedActions.Login) =>
        from(this.authService.login()).pipe(
          map((user) => new sharedActions.LoginSuccess(user)),
          catchError((err) => of(new sharedActions.LoginFail(err)))
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedActions.SharedActionTypes.Logout),
      mergeMap((action: sharedActions.Logout) =>
        from(this.authService.logout()).pipe(
          map((authResult) => new sharedActions.LogoutSuccess()),
          catchError((err) => of(new sharedActions.LogoutFail(err)))
        )
      )
    )
  );

  loginFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedActions.SharedActionTypes.LoginFail),
      mergeMap((action: sharedActions.LoginFail) =>
        from(this.router.navigate(['/unauthorized'])).pipe(
          map((result) => new sharedActions.LoginFailRedirect(result))
        )
      )
    )
  );

  loadLookups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedActions.SharedActionTypes.LoadLookups),
      filter((action: sharedActions.LoadLookups) => {
        const alreadyDoneOrPending =
          SharedEffects.lookupsAlreadyDispatched.includes(action.payload);
        if (alreadyDoneOrPending === false) {
          SharedEffects.lookupsAlreadyDispatched.push(action.payload);
        }
        return !alreadyDoneOrPending;
      }),
      mergeMap((action: sharedActions.LoadLookups) => {
        return this.describedItemsService
          .getDescribedItems(action.payload)
          .pipe(
            map((result) => {
              if (result && result.length > 0) {
                return new sharedActions.LoadLookupsSuccess({
                  type: action.payload,
                  data: <LookupItem[]>result,
                });
              } else {
                return new sharedActions.SetCurrentError(
                  `No items found for LookupType of ${action.payload}`
                );
              }
            }),
            catchError((err) => of(new sharedActions.SetCurrentError(err)))
          );
      })
    )
  );

  loadAllLookups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedActions.SharedActionTypes.LoadAllLookups),
      switchMap((_: sharedActions.LoadAllLookups) => {
        return this.describedItemsService.getAllDescribedItems().pipe(
          map((result) => {
            if (result) {
              return new sharedActions.LoadAllLookupsSuccess(result);
            }

            return new sharedActions.SetCurrentError(
              `loading all lookups failed!`
            );
          }),
          catchError((err) => of(new sharedActions.SetCurrentError(err)))
        );
      })
    )
  );

  loadCptLookups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedActions.SharedActionTypes.LoadCptLookups),
      filter((action: sharedActions.LoadCptLookups) => {
        const alreadyDoneOrPending =
          SharedEffects.cptLookupsAlreadyDispatched.includes(action.code);
        if (alreadyDoneOrPending === false) {
          SharedEffects.cptLookupsAlreadyDispatched.push(action.code);
        }
        return !alreadyDoneOrPending;
      }),
      mergeMap((action: sharedActions.LoadCptLookups) => {
        return this.cptApiService.getCptByCode(action.code).pipe(
          map((result) => {
            if (result) {
              return new sharedActions.LoadCptLookupsSuccess({
                code: action.code,
                data: <Cpt>result,
              });
            } else {
              return new sharedActions.SetCurrentError(
                `No Cpt found for code ${action.code}`
              );
            }
          }),
          catchError((err) => of(new sharedActions.SetCurrentError(err)))
        );
      })
    )
  );

  loadDrgLookups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedActions.SharedActionTypes.LoadDrgLookups),
      filter((action: sharedActions.LoadDrgLookups) => {
        const alreadyDoneOrPending =
          SharedEffects.drgLookupsAlreadyDispatched.includes(action.code);
        if (alreadyDoneOrPending === false) {
          SharedEffects.drgLookupsAlreadyDispatched.push(action.code);
        }
        return !alreadyDoneOrPending;
      }),
      mergeMap((action: sharedActions.LoadDrgLookups) => {
        return this.drgApiService.getDrgByCode(action.code).pipe(
          map((result) => {
            if (result) {
              return new sharedActions.LoadDrgLookupsSuccess({
                code: action.code,
                data: <Drg>result,
              });
            } else {
              return new sharedActions.SetCurrentError(
                `No Drg found for code ${action.code}`
              );
            }
          }),
          catchError((err) => of(new sharedActions.SetCurrentError(err)))
        );
      })
    )
  );

  loadHcpcsLookups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedActions.SharedActionTypes.LoadHcpcsLookups),
      filter((action: sharedActions.LoadHcpcsLookups) => {
        const alreadyDoneOrPending =
          SharedEffects.hcpcsLookupsAlreadyDispatched.includes(action.code);
        if (alreadyDoneOrPending === false) {
          SharedEffects.hcpcsLookupsAlreadyDispatched.push(action.code);
        }
        return !alreadyDoneOrPending;
      }),
      mergeMap((action: sharedActions.LoadHcpcsLookups) => {
        return this.hcpcsApiService.getHcpcsByCode(action.code).pipe(
          map((result) => {
            if (result) {
              return new sharedActions.LoadHcpcsLookupsSuccess({
                code: action.code,
                data: <Hcpcs>result,
              });
            } else {
              return new sharedActions.SetCurrentError(
                `No Hcpcs found for code ${action.code}`
              );
            }
          }),
          catchError((err) => of(new sharedActions.SetCurrentError(err)))
        );
      })
    )
  );

  findMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedActions.SharedActionTypes.FindMembers),
      switchMap((action: sharedActions.FindMembers) => {
        if (action.payload.clientId) {
          return this.membersService
            .findMembersByClientId(action.payload.clientId, {
              name: action.payload.search,
            })
            .pipe(
              map(
                (membersResult) =>
                  new sharedActions.FindMembersSuccess(membersResult)
              ),
              catchError((err) => of(new sharedActions.SetCurrentError(err)))
            );
        }

        return this.membersService
          .findMembers({ name: action.payload.search })
          .pipe(
            map(
              (membersResult) =>
                new sharedActions.FindMembersSuccess(membersResult)
            ),
            catchError((err) => of(new sharedActions.SetCurrentError(err)))
          );
      })
    )
  );

  loadClientNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedActions.SharedActionTypes.LoadClientNames),
      switchMap((action: sharedActions.LoadClientNames) => {
        return this.clientsService.getClientNames().pipe(
          map((names) => new sharedActions.LoadClientNamesSuccess(names)),
          catchError((err) => of(new sharedActions.SetCurrentError(err)))
        );
      })
    )
  );

  private getCurrentBusinessModuleIdEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(sharedActions.SharedActionTypes.SetCurrentBusinessModuleId),
      map((action: sharedActions.SetCurrentBusinessModuleId) => {
        const route: Route = this.router.config.find(
          (entry: any) => entry.businessModuleId === +action.payload
        );

        if (route) {
          const currentUrl = this.router.url;
          if (currentUrl !== '/' && currentUrl.indexOf(route.path) === -1) {
            console.log(
              `Navigating from '${currentUrl}' to '/${route.path}/' because of setCurrentBusinessModuleId Effect`
            );
            this.router.navigate([`/${route.path}/`]);
          }
        }

        return new sharedActions.SetCurrentBusinessModuleIdSuccess(
          action.payload
        );
      })
    );
  }
}
