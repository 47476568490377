<div class="grid-container">
  <ch-anesthesiologist-banner [anesthesiologist]="anesthesiologist$ | async">
  </ch-anesthesiologist-banner>
  <div class="anesthesiologist-nav">
    <ch-side-nav [menuItems]="menuItems"></ch-side-nav>
  </div>
  <div class="anesthesiologist-body">
    <router-outlet></router-outlet>
  </div>
</div>
