import { Injectable } from '@angular/core';
import {
  ApplicationInsights,
  DistributedTracingModes,
  IConfig,
  IConfiguration,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { Store, select } from '@ngrx/store';
import { environment } from 'carehub-environment/environment';
import * as fromRoot from 'carehub-root/state/app.state';
import * as fromShared from 'carehub-shared/state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class AppInsightService {
  private ai: ApplicationInsights;

  protected unsubscribe$: Subject<void> = new Subject();

  constructor(sharedStore: Store<fromRoot.State>) {
    // there IS an angular plugin, but we're so behind on angular releases the peer
    // deps are weird.
    const config: IConfiguration & IConfig = {
      ...environment.appInsights,
      distributedTracingMode: DistributedTracingModes.W3C,
    };

    this.ai = new ApplicationInsights({ config });
    this.ai.loadAppInsights();

    sharedStore
      .pipe(select(fromShared.getCurrentUser), takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        if (user) {
          this.ai.setAuthenticatedUserContext(
            user.displayName,
            user.userGuid,
            true
          );
        }
      });
  }

  logPageView(name: string, uri?: string, properties?: any) {
    this.ai.trackPageView({
      name,
      uri,
      properties,
    });
  }

  logEvent(name: string, properties?: any) {
    this.ai.trackEvent({ name, properties });
  }

  logMetric(name: string, average: number, properties?: any) {
    this.ai.trackMetric({
      name,
      average,
      properties,
    });
  }

  logException(
    exception: Error,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number },
    severityLevel?: number
  ) {
    this.ai.trackException({
      exception,
      properties,
      measurements,
      severityLevel,
    });
  }

  logTrace(
    message: string,
    properties?: { [name: string]: string },
    severityLevel?: SeverityLevel
  ) {
    this.ai.trackTrace({ message, properties, severityLevel });
  }
}
