import { Injectable } from '@angular/core';
import { NavItem } from '../nav-item';
import { BusinessModuleDetails } from './business-module.details';

@Injectable({
  providedIn: 'root',
})
export class BusinessModuleService {
  constructor() {}

  businessModules: BusinessModuleDetails[] = [];

  registerModule(name: string, id: number, menuItems: Array<NavItem | null>) {
    if (this.businessModules.filter((c) => c.id === id).length === 0) {
      console.log(`Registering Business Module for '${name}'.`);
      const details = new BusinessModuleDetails();
      details.name = name;
      details.id = id;
      details.menuItems = menuItems;

      this.businessModules.push(details);
      console.log(
        `Business Modules Count is now at: ${this.businessModules.length} with the addition of '${name}'.`
      );
    } else {
      console.log(`Business Module for '${name}' ALREADY Registered.`);
    }
  }

  getCurrentBusinessModuleSideMenu(id: number): Array<NavItem | null> {
    console.log(`Looking for Business Module with Id of ${id}.`);
    const businessModuleDetails = this.businessModules.find(
      (entry) => entry.id === id
    );

    if (businessModuleDetails) {
      console.log(`Found Menu for Business Module with Id of ${id}.`);
      return businessModuleDetails.menuItems;
    } else {
      console.warn(`Could NOT find Menu for Business Module with Id of ${id}.`);
      return [];
    }
  }
}
