<div [ngClass]="{ 'hide-me': !isLoggedIn || !hasCallValidationPermission }">
  <div class="header-text" [class.hide-me]="!this.isAwaitingCall">
    Awaiting Inbound Call
  </div>
  <div class="grids" [class.hide-me]="this.isAwaitingCall">
    <mat-accordion class="align-headers">
      <div class="caller-id">
        Inbound Caller ID: {{ phoneNumberFormatted || phoneNumber }}
      </div>
      <mat-expansion-panel #memberPanel>
        <mat-expansion-panel-header>
          <mat-panel-title> Member </mat-panel-title>
          <mat-panel-description
            *ngIf="!selectedMember && _validationData && _validationData.member"
          >
            Loading...
          </mat-panel-description>
          <mat-panel-description *ngIf="selectedMember"
            ><span class="accordian-description">
              {{ selectedMember?.firstName }} {{ selectedMember?.lastName }}
              {{ selectedMemberPhoneNumberFormatted ? '|' : '' }}
              {{ selectedMemberPhoneNumberFormatted }}
              <ch-problematic-member-icon
                [member]="selectedMember"
              ></ch-problematic-member-icon>
            </span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template [ngIf]="!!this._validationData">
          <ch-member-selection
            [phoneNumber]="phoneNumber"
            (memberAndPlanSelected)="onSelectedMemberPlanChange($event)"
          ></ch-member-selection>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel #memberPreviewPanel>
        <mat-expansion-panel-header>
          <mat-panel-title> Member Details </mat-panel-title>
          <mat-panel-description
            *ngIf="!selectedMember && _validationData && _validationData.member"
          >
            Loading...
          </mat-panel-description>
          <mat-panel-description *ngIf="selectedMember"
            ><span class="accordian-description">
              Member ID: {{ selectedMember?.surgeryPlusMemberId }} | Employee
              ID: {{ selectedMember?.employeeNumber }}
              <ch-problematic-member-icon
                [member]="selectedMember"
              ></ch-problematic-member-icon>
            </span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template [ngIf]="!!this._validationData">
          <div *ngIf="selectedMember; else plzSelectMember">
            <ch-member-preview
              [memberToPreview]="
                this.selectedMember ? this.selectedMember : undefined
              "
              [memberPlanToPreview]="
                this.selectedMemberPlan ? this.selectedMemberPlan : undefined
              "
              (memberConfirmed)="onMemberConfirmation($event)"
            ></ch-member-preview>
          </div>
          <ng-template #plzSelectMember>
            <div class="validate-properly">Select Member to load Details</div>
          </ng-template>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel #casePanel>
        <mat-expansion-panel-header>
          <mat-panel-title> Case </mat-panel-title>
          <mat-panel-description
            *ngIf="
              !selectedCase && _validationData && _validationData.memberCase
            "
          >
            Loading...
          </mat-panel-description>
          <mat-panel-description *ngIf="!!selectedCase">
            <span class="accordian-description">
              Case Number: {{ selectedCase?.caseNumber }} | Closed Date:
              {{
                selectedCase.caseClosedDate != null
                  ? (selectedCase.caseClosedDate | date : 'MM/dd/yyyy' : '+0')
                  : '(N/A)'
              }}
              <ch-problematic-member-icon
                [member]="selectedMember"
              ></ch-problematic-member-icon>
            </span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div *ngIf="confirmedMember; else plzConfirmMember">
          <ch-case-selection
            #caseLookup
            *ngIf="!!this._validationData"
            [member]="this.selectedMember ? this.selectedMember : undefined"
            (caseSelected)="onSelectedCaseChange($event)"
          ></ch-case-selection>
        </div>
        <ng-template #plzConfirmMember>
          <div class="validate-properly">Confirm Member to load Cases</div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div *ngIf="hasContextSwapPermission">
    <button
      class="swap-context"
      mat-raised-button
      (click)="onClickSwapContext()"
    >
      Swap Context
    </button>
  </div>
  <button
    class="go-to-link"
    mat-raised-button
    (click)="onClickGoToMember()"
    [disabled]="!(_validationData.member && selectedMember)"
    target="_blank"
  >
    Go to Member
  </button>
  <button
    class="go-to-link"
    mat-raised-button
    (click)="onClickGoToMemberCase()"
    [disabled]="!_validationData.member || !_validationData.memberCase"
    target="_blank"
  >
    Go to MemberCase
  </button>
</div>

<div *ngIf="!isLoggedIn || !hasCallValidationPermission">
  <div class="header-text">
    This page is under construction. Please use CareHub through the browser.
  </div>
</div>
<div class="reset-icon-box">
  <mat-icon (click)="onClickResetCallInfo()">refresh</mat-icon>
</div>
